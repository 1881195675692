import React, { FunctionComponent } from "react"
import { connect } from "react-redux"
import {
  CircularProgress,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  Select,
  MenuItem,
} from "@material-ui/core"
import IconEdit from "@material-ui/icons/Edit"
import IconCheck from "@material-ui/icons/Check"
import IconClose from "@material-ui/icons/Close"
import IconDelete from "@material-ui/icons/Delete"
import IconNext from "@material-ui/icons/ArrowForwardIos"
import { Coords } from "google-map-react"
import SlideDialog from "./SlideDialog"
import DialogCloseButton from "./DialogCloseButton"
import i18n from "../../locales"
import { StoryModel } from "../../models"
import { Status } from "../../graphql/API"
import { Consumer as AppContextConsumer } from "../../AppContext"
import { RootState } from "../../state/store"
import * as actions from "../../state/modals/story-listing/actions"
import * as mapActions from "../../state/map/actions"
import * as storyActions from "../../state/stories/actions"

const styles = (theme: Theme) =>
  createStyles({
    table: {
      paddingBottom: theme.spacing(),
    },
    row: {
      cursor: "pointer",
    },
    secondaryColumn: {
      width: "1px",
    },
    spinner: {
      textAlign: "center",
      padding: theme.spacing(4),
    },
    paginationButtons: {
      display: "flex",
      justifyContent: "space-between",
    },
    prevPage: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      transform: "rotate(180deg)",
    },
    nextPage: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    noStories: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
    submittingOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  })

interface Props extends WithStyles<typeof styles> {
  shown: boolean
  isLoadingStories: boolean
  isSubmitting: boolean
  stories: StoryModel[]
  page: number

  panTo: (story: StoryModel) => void
  editStory: (story: StoryModel) => void
  updateStatus: (
    currentUser: CurrentUser,
    story: StoryModel,
    status: Status
  ) => void
  showPrevPage: (user: CurrentUser, page: number) => void
  showNextPage: (user: CurrentUser, page: number) => void
  closeDialog: () => void
  disableAddStoryMode: () => void
}

const StoryListing: FunctionComponent<Props> = (props: Props) => {
  const { stories, shown, closeDialog, isLoadingStories, classes } = props
  const getStoryTable = () => {
    if (isLoadingStories) {
      return <Spinner {...props} />
    } else if (!stories.length) {
      return (
        <p className={classes.noStories}>
          {i18n("storyListing.table.noStories")}
        </p>
      )
    }

    return <StoryTable {...props} />
  }

  const onCloseDialog = () => {
    props.disableAddStoryMode()
    props.closeDialog()
  }

  return (
    <SlideDialog open={shown} onClose={closeDialog} maxWidth="md" fullWidth>
      <DialogTitle>
        {i18n("storyListing.table.title")}
        <DialogCloseButton onClick={onCloseDialog} />
      </DialogTitle>
      {getStoryTable()}
      {props.isSubmitting && (
        <div className={props.classes.submittingOverlay}>
          <CircularProgress color="primary" size={45} thickness={3} />
        </div>
      )}
    </SlideDialog>
  )
}

const StoryTable = (props: Props) => (
  <AppContextConsumer>
    {context => (
      <div>
        <Table className={props.classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{i18n("storyListing.table.name")}</TableCell>

              <TableCell
                className={props.classes.secondaryColumn}
                align="center"
                padding="default"
              >
                {i18n("storyListing.table.analytics")}
              </TableCell>

              <TableCell
                className={props.classes.secondaryColumn}
                align="center"
                padding="default"
              >
                {i18n("storyListing.table.edit")}
              </TableCell>

              <TableCell
                className={props.classes.secondaryColumn}
                align="center"
                padding="default"
              >
                {i18n("storyListing.table.status")}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.stories.map(story => {
              const unlocks =
                story.analyticsUnlockCount != null
                  ? story.analyticsUnlockCount
                  : 0
              const clicks =
                story.analyticsClickCount != null
                  ? story.analyticsClickCount
                  : 0

              return (
                <TableRow
                  hover
                  key={story.id}
                  onClick={() => props.panTo(story)}
                  className={props.classes.row}
                >
                  <TableCell>{story.name}</TableCell>

                  <TableCell>
                    {unlocks + " / " + clicks}
                  </TableCell>
                  <TableCell align="center" padding="default">
                    <IconButton
                      onClick={() => props.editStory(story)}
                      aria-label="edit"
                    >
                      <IconEdit />
                    </IconButton>
                  </TableCell>

                  <TableCell align="right" padding="default">
                    <Select
                      value={story.status.toLowerCase()}
                      onChange={e => {
                        let action = e.target.value
                        if (action === "deleted") {
                          window.confirm(
                            i18n("storyListing.prompt.areYouSure")
                          ) &&
                            props.updateStatus(
                              context.currentUser as CurrentUser,
                              story,
                              Status.DELETED
                            )
                        } else if (action === "draft") {
                          props.updateStatus(
                            context.currentUser as CurrentUser,
                            story,
                            Status.DRAFT
                          )
                        } else if (action === "published") {
                          props.updateStatus(
                            context.currentUser as CurrentUser,
                            story,
                            Status.PUBLISHED
                          )
                        }
                      }}
                    >
                      <MenuItem value="published">
                        <IconCheck /> {i18n("storyListing.table.publish")}
                      </MenuItem>
                      <MenuItem value="draft">
                        <IconClose /> {i18n("storyListing.table.unpublish")}
                      </MenuItem>
                      <MenuItem value="deleted">
                        <IconDelete /> {i18n("storyListing.table.delete")}
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <div className={props.classes.paginationButtons}>
          <div>
            {props.page > 0 && (
              <IconButton
                className={props.classes.prevPage}
                onClick={() =>
                  props.showPrevPage(
                    context.currentUser as CurrentUser,
                    props.page
                  )
                }
              >
                <IconNext />
              </IconButton>
            )}
          </div>

          <div>
            {props.stories.length >= actions.pageLength && (
              <IconButton
                className={props.classes.nextPage}
                onClick={() =>
                  props.showNextPage(
                    context.currentUser as CurrentUser,
                    props.page
                  )
                }
              >
                <IconNext />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    )}
  </AppContextConsumer>
)

const Spinner = (props: Props) => (
  <div className={props.classes.spinner}>
    <CircularProgress size={30} thickness={5} />
  </div>
)

const componentWithStyles = withStyles(styles)(StoryListing)

const mapStateToProps = (state: RootState) => ({
  shown: state.modals.storyListing.shown,
  isLoadingStories: state.modals.storyListing.isLoadingListUserStories,
  isSubmitting: state.modals.storyListing.isSubmitting,
  stories: state.stories.user,
  page: state.modals.storyListing.page,
})

const mapDispatchToProps = (dispatch: any) => ({
  panTo: (story: StoryModel) => {
    const coords: Coords = {
      lat: story.geoLatitude,
      lng: story.geoLongitude,
    }
    dispatch(mapActions.panTo(coords, 0.35))
  },
  editStory: (story: StoryModel) => {
    dispatch(storyActions.editStory(story))
  },
  updateStatus: (
    currentUser: CurrentUser,
    story: StoryModel,
    status: Status
  ) => {
    if (currentUser) {
      dispatch(storyActions.updateStatus(currentUser, story, status))
    }
  },
  showPrevPage: (user: CurrentUser, page: number) => {
    dispatch(
      storyActions.retrieveUserStories(user, page - 1, actions.pageLength)
    )
  },
  showNextPage: (user: CurrentUser, page: number) => {
    dispatch(
      storyActions.retrieveUserStories(user, page + 1, actions.pageLength)
    )
  },
  closeDialog: () => {
    dispatch(actions.closeStoryListing())
  },
  disableAddStoryMode: () => {
    dispatch(mapActions.disableAddStoryMode())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(componentWithStyles)
