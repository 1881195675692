import React, { FunctionComponent } from "react"
import { RouteComponentProps } from "react-router-dom"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core"
import Header from "./Super/Header"
import Map from "./Super/Map"
import StoryListing from "./Super/StoryListing"
import StoryForm from "./Super/StoryForm"
import Toast from "./Super/Toast"

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Crimson Text', serif",
  },
})

interface Props extends RouteComponentProps {}

const Super: FunctionComponent<Props> = (props: Props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Header />
      <Map />
      <StoryListing />
      <StoryForm />
      <Toast />
    </MuiThemeProvider>
  )
}

export default Super
