import React, { Component } from "react"
import { WrappedFieldProps } from "redux-form"
import { Button, IconButton } from "@material-ui/core"
import lightGreen from "@material-ui/core/colors/lightGreen"
import { ButtonProps } from "@material-ui/core/Button"
import IconCheck from "@material-ui/icons/Check"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Tooltip from "@material-ui/core/Tooltip"
import {
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  Grid,
} from "@material-ui/core"
import i18n from "../../locales"
import { AudioModel, AudioContainer, UploadModel } from "../../models"
import { strFileSize } from "../../utils/file"

const config = require("../../config/story.json")

const audioListStyles = (theme: Theme) =>
  createStyles({
    audioList: {
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(-2),
    },
    container: {
      position: "relative",
      paddingRight: 16,
    },
    filename: {
      marginTop: theme.spacing(0.75),
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    remove: {
      position: "absolute",
      top: 0,
      right: -4,
      padding: 4,
      margin: -4,
    },
    indicator: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    check: {
      color: lightGreen[600],
    },
    empty: {
      marginTop: theme.spacing(0.75),
      textAlign: "center",
    },
    list: {
      width: "100%",
    },
    audiobox: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      centerOnSmall: {
        textAlign: "center",
      },
    },
  })

interface Props extends WrappedFieldProps, ButtonProps {
  submitting: boolean
  showError: (error: string) => void
}

interface AudioListProps
  extends WrappedFieldProps,
    WithStyles<typeof audioListStyles> {
  submitting: boolean
  item: AudioModel | UploadModel | undefined
  showError: (error: string) => void
}

type State = {
  openAttributeModal: boolean
  attrModalData: any
  attrText: any
  attrEditModeIndex: number
}

const maxSize = config.maxAudioFileSize * 1024 * 1024

const checkFile = (file: File): boolean => {
  return file.size < maxSize
}

const handleChange = (
  onChange: (value: any) => void,
  onError: (error: string) => void,
  audioContainer: AudioContainer
) => (event: any) => {
  const newContainer = new AudioContainer(audioContainer)
  const files = event.target.files
  const newFiles = []

  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const fileCheckPassed = checkFile(file)
    if (fileCheckPassed) {
      newFiles.push({
        file: file,
        attribution: "",
      })
    } else {
      const message = i18n("storyForm.error.fileTooBig")
      const size = `${config.maxAudioFileSize} MB`
      onError(message.replace("{{name}}", file.name).replace("{{size}}", size))
    }
  }

  if (newFiles.length > 0) {
    newContainer.upload(newFiles)
    onChange(newContainer)
  }
}

const handleRemove = (
  onChange: any,
  audioContainer: AudioContainer,
  toRemove: AudioModel | UploadModel
) => (event: any) => {
  const newContainer = new AudioContainer(audioContainer)
  newContainer.remove(toRemove)
  onChange(newContainer)
}

const getFilename = (item: AudioModel | UploadModel): string => {
  if (item instanceof AudioModel) {
    return item.filename
  } else if (item.file && item.file.name) {
    return item.file.name
  } else {
    return ""
  }
}

const uploadDone = (
  item: AudioModel | UploadModel,
  audioContainer: AudioContainer
): boolean => {
  if (item instanceof AudioModel) {
    return true
  } else if (item instanceof UploadModel) {
    const index = audioContainer.toUpload.indexOf(item)
    return audioContainer.uploadProgress[index] === 1
  } else {
    return false
  }
}

export const FormAudioField = (props: Props) => {
  const { input, submitting, showError, ...buttonProps } = props
  const fieldId = `story_${input.name}`

  return (
    <div>
      <label htmlFor={fieldId}>
        <input
          id={fieldId}
          accept="audio/*"
          type="file"
          multiple
          style={{ display: "none" }}
          disabled={submitting}
          onChange={handleChange(input.onChange, showError, input.value)}
        />
        <Button component="span" {...buttonProps as ButtonProps}>
          {i18n(`storyForm.label.${input.name}`)}
        </Button>
      </label>
      <AudioList {...props as AudioListProps} />
    </div>
  )
}

const getAttribution = (item: AudioModel | UploadModel): string => {
  if (item instanceof AudioModel) {
    return item.attribution
  } else if (item.file && item.file.name) {
    return item.attribution
  } else {
    return ""
  }
}

const getKey = (item: AudioModel | UploadModel, index: number) => {
  if (item instanceof AudioModel) {
    return item.id || item.filename
  } else if (item.file && item.file.name) {
    return item.file.name
  } else {
    return index.toString()
  }
}

const getFileSize = (item: AudioModel | UploadModel): number => {
  if (item instanceof AudioModel) {
    return item.size
  } else if (item.file && item.file.name) {
    return item.file.size
  } else {
    return 0
  }
}

const getFileSizeText = (item: AudioModel | UploadModel): string => {
  const fileSize = getFileSize(item)
  return strFileSize(fileSize)
}

const AudioListItem = (
  showAttributeModal: any,
  totalItems: number,
  item: AudioModel | UploadModel,
  index: number,
  props: AudioListProps
) => {
  const { classes, submitting } = props
  const key = getKey(item, index)
  const filename = getFilename(item)
  const attribution = getAttribution(item)
  const fileSizeText = getFileSizeText(item)

  return (
    <ListItem className={classes.audiobox} key={key}>
      <Grid container alignItems="center" justify="center">
        <Grid item lg={4} sm={5} xs={12} className={classes.centerOnSmall}>
          <ListItemText primary={filename} />
        </Grid>
        <Grid item lg={5} sm={4} xs={12} className={classes.centerOnSmall}>
          <ListItemText primary={attribution} />
        </Grid>
        <Grid item lg={2} sm={2} xs={6} className={classes.centerOnSmall}>
          <ListItemText primary={fileSizeText} />
        </Grid>

        <Grid item lg={1} sm={1} xs={12} className={classes.centerOnSmall}>
          <Tooltip title={i18n("storyForm.label.editAttribution")}>
            <IconButton
              className={classes.arrowIcon}
              onClick={() => showAttributeModal(index, item)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          {(submitting && <UploadProgress item={item} {...props} />) || (
            <RemoveButton item={item} {...props} />
          )}
        </Grid>
      </Grid>
    </ListItem>
  )
}

const RemoveButton = (props: AudioListProps) => {
  const { classes, input, item } = props
  return (
    <Tooltip title={i18n("storyForm.label.removeAudio")}>
      <IconButton
        className={classes.arrowIcon}
        onClick={handleRemove(input.onChange, input.value, item!)}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}

const UploadProgress = (props: AudioListProps) => {
  const { classes, input, item } = props
  const audioContainer = input.value
  const done = uploadDone(item!, audioContainer)

  if (done) {
    return (
      <div className={classes.indicator}>
        <IconCheck className={classes.check} fontSize="small" />
      </div>
    )
  } else {
    return (
      <div className={classes.indicator}>
        <CircularProgress size={18} thickness={6} />
      </div>
    )
  }
}

class AudioListUnstyled extends Component<AudioListProps, State> {
  constructor(props: AudioListProps) {
    super(props)
    this.state = {
      openAttributeModal: false,
      attrModalData: false,
      attrText: null,
      attrEditModeIndex: 0,
    }
  }

  _renderEmpty = () => {
    const { classes } = this.props
    return (
      <div className={classes.empty}>{i18n("storyForm.label.noAudio")}</div>
    )
  }

  _showAttributeModal = (index: number, item: AudioModel) => {
    this.setState({
      openAttributeModal: true,
      attrModalData: item,
      attrText: null,
      attrEditModeIndex: index,
    })
  }

  _updateItemAttribute = () => {
    const { input } = this.props
    const { attrModalData, attrText } = this.state
    let audioContainer = input.value
    // uploaded files
    let audiosItems = input.value.items || []
    if (audiosItems && audiosItems.length > 0) {
      const newContainer = new AudioContainer(audioContainer)
      audiosItems.map((item: AudioModel) => {
        item.attribution = item.attribution || ""
        if (item.id === attrModalData.id) {
          item.attribution = attrText
        }
        return item
      })
      newContainer.items = audiosItems
      input.onChange(newContainer)
    }
    // to be uploaded files
    let audiosItemsToUpload = input.value.toUpload || []
    if (audiosItemsToUpload && audiosItemsToUpload.length > 0) {
      const newContainer = new AudioContainer(audioContainer)
      audiosItemsToUpload.map((item: UploadModel, index: number) => {
        item.attribution = item.attribution || ""
        if (index === this.state.attrEditModeIndex) {
          item.attribution = attrText
        }
        return item
      })
      newContainer.toUpload = audiosItemsToUpload
      input.onChange(newContainer)
    }

    this.setState({
      openAttributeModal: false,
    })
  }

  _getAttrModalContent = () => {
    const { attrModalData } = this.state
    const title =
      attrModalData.filename ||
      attrModalData.name ||
      i18n("storyForm.label.audio")
    let defautlValue =
      this.state.attrText != null
        ? this.state.attrText
        : attrModalData.attribution
    return (
      <div>
        <Dialog
          open={this.state.openAttributeModal}
          onClose={() => {}}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {i18n("storyForm.label.addAttributionTitle")} {title}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="attribute"
              label={i18n("storyForm.label.attribution")}
              type="text"
              defaultValue={defautlValue}
              fullWidth
              onChange={e => {
                this.setState({
                  attrText: e.target.value,
                })
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({
                  openAttributeModal: false,
                  attrModalData: false,
                })
              }
              color="primary"
            >
              {i18n("storyForm.label.attributionCancel")}
            </Button>
            <Button
              onClick={() => {
                this._updateItemAttribute()
              }}
              color="primary"
            >
              {i18n("storyForm.label.attributionSave")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  _renderContent = () => {
    const { classes, input } = this.props
    const audioContainer = input.value
    let items = audioContainer.items
    return (
      <Grid container>
        <Grid item className={classes.list}>
          <List dense={false} className={classes.list}>
            {items.map((audio: AudioModel, index: number) =>
              AudioListItem(
                this._showAttributeModal,
                items.length,
                audio,
                index,
                this.props
              )
            )}
            {audioContainer.toUpload.map((file: UploadModel, index: number) =>
              AudioListItem(
                this._showAttributeModal,
                audioContainer.toUpload.length,
                file,
                index,
                this.props
              )
            )}
          </List>
        </Grid>
        {this.state.openAttributeModal ? this._getAttrModalContent() : null}
      </Grid>
    )
  }

  render() {
    const { input } = this.props
    const audioContainer = input.value
    return audioContainer.items.length > 0 || audioContainer.toUpload.length > 0
      ? this._renderContent()
      : this._renderEmpty()
  }
}

const AudioList = withStyles(audioListStyles)(AudioListUnstyled)
