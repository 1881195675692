import React from "react"
import { ForgotPassword } from "aws-amplify-react"
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Input,
  InputLabel,
  Button,
  FormField,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
} from "aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React"
import i18n from "../../locales"

class CustomForgotPassword extends ForgotPassword {
  constructor(props: any) {
    super(props)

    this.setState = super.setState
    this.render = super.render
  }

  sendView(theme: any) {
    return (
      <div>
        <FormField theme={theme}>
          <InputLabel theme={theme}>
            {i18n("forgotPassword.form.username.label")}
          </InputLabel>
          <Input
            autoFocus
            placeholder={i18n("forgotPassword.form.username.placeholder")}
            theme={theme}
            key="username"
            name="username"
            onChange={this.handleInputChange}
          />
        </FormField>
      </div>
    )
  }

  submitView(theme: any) {
    return (
      <div>
        <Input
          placeholder={i18n("forgotPassword.form.code.placeholder")}
          theme={theme}
          key="code"
          name="code"
          autoComplete="off"
          onChange={this.handleInputChange}
        />
        <Input
          placeholder={i18n("forgotPassword.form.password.placeholder")}
          theme={theme}
          type="password"
          key="password"
          name="password"
          onChange={this.handleInputChange}
        />
      </div>
    )
  }

  showComponent(theme: any) {
    const { authData = {} } = this.props

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {i18n("forgotPassword.header")}
        </SectionHeader>
        <SectionBody theme={theme}>
          {this.state.delivery || authData.username
            ? this.submitView(theme)
            : this.sendView(theme)}
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            {this.state.delivery || authData.username ? (
              <Button theme={theme} onClick={this.submit}>
                {i18n("forgotPassword.form.submit")}
              </Button>
            ) : (
              <Button theme={theme} onClick={this.send}>
                {i18n("forgotPassword.form.send")}
              </Button>
            )}
          </SectionFooterPrimaryContent>
          <SectionFooterSecondaryContent theme={theme}>
            {this.state.delivery || authData.username ? (
              <Link theme={theme} onClick={this.send}>
                {i18n("forgotPassword.form.resend")}
              </Link>
            ) : (
              <Link theme={theme} onClick={() => this.changeState("signIn")}>
                {i18n("forgotPassword.form.cancel")}
              </Link>
            )}
          </SectionFooterSecondaryContent>
        </SectionFooter>
      </FormSection>
    )
  }

  props: any
  state: any = {}
  refs: any
  context: any
  setState: any
  forceUpdate: any
  render: any
}

export default CustomForgotPassword
