import React, { Component, FunctionComponent } from "react"
import * as R from "ramda"
import { WrappedFieldProps } from "redux-form"
import {
  Button,
  IconButton,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  Grid,
} from "@material-ui/core"
import lightGreen from "@material-ui/core/colors/lightGreen"
import { ButtonProps } from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconCheck from "@material-ui/icons/Check"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import i18n from "../../locales"
import { ImageModel, ImageContainer, UploadModel } from "../../models"
import { useStoryImage } from "../../utils/story-image"
import { strFileSize } from "../../utils/file"

const config = require("../../config/story.json")

const imageListStyles = (theme: Theme) =>
  createStyles({
    remove: {
      marginTop: -10,
    },
    arrowIcon: {
      fontSize: 20,
    },
    indicator: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    check: {
      color: lightGreen[600],
    },
    empty: {
      marginTop: theme.spacing(0.75),
      textAlign: "center",
    },
    imagebox: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      paddingTop: 0,
      paddingBottom: 0,
    },
    image: {
      height: 100,
      marginLeft: 30,
      marginTop: 10,
    },
    list: {
      width: "100%",
    },
    center: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      centerOnSmall: {
        textAlign: "center",
      },
    },
  })

interface Props extends WrappedFieldProps, ButtonProps {
  submitting: boolean
  showError: (error: string) => void
}

type State = {
  openAttributeModal: boolean
  attrModalData: any
  attrText: any
  attrEditModeIndex: number
  allImages: any
}

interface ImageListProps
  extends WrappedFieldProps,
    WithStyles<typeof imageListStyles> {
  submitting: boolean
  item: ImageModel | UploadModel | undefined
  imagesreordered: () => void
  showError: (error: string) => void
}

const maxSize = config.maxImageFileSize * 1024 * 1024
const maxResolution = 0x3fff * 0x3fff

const checkFile = (file: File): Promise<boolean> =>
  new Promise(resolve => {
    if (file.size > maxSize) {
      resolve(false)
      return
    }

    const reader = new FileReader()
    const image = new Image()

    reader.onload = () => {
      image.src = reader.result as string
    }

    image.onload = () => {
      const resolution = image.width * image.height
      resolve(resolution < maxResolution)
    }

    reader.readAsDataURL(file)
  })

const handleChange = (
  onChange: (value: any) => void,
  onError: (error: string) => void,
  imageContainer: ImageContainer
) => async (event: any) => {
  const newContainer = new ImageContainer(imageContainer)
  const files = event.target.files
  const newFiles = []

  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const fileCheckPassed = await checkFile(file)
    if (fileCheckPassed) {
      newFiles.push({
        file: file,
        attribution: "",
      })
    } else {
      const message = i18n("storyForm.error.fileTooBig")
      const size = `${config.maxImageFileSize} MB`
      onError(message.replace("{{name}}", file.name).replace("{{size}}", size))
    }
  }

  if (newFiles.length > 0) {
    newContainer.upload(newFiles)
    onChange(newContainer)
  }
}

const handleRemove = (
  onChange: any,
  imageContainer: ImageContainer,
  toRemove: ImageModel | UploadModel
) => (event: any) => {
  if (toRemove instanceof ImageModel) {
    const newContainer = new ImageContainer(imageContainer)
    newContainer.remove(toRemove)
    onChange(newContainer)
  } else {
    const currentToUpload = imageContainer.toUpload || []
    const imagesToUpload = [...currentToUpload]
    const newContainer = new ImageContainer(imageContainer)
    imagesToUpload.splice(imagesToUpload.indexOf(toRemove), 1)
    newContainer.toUpload = imagesToUpload
    onChange(newContainer)
  }
}

const getFilename = (item: ImageModel | UploadModel): string => {
  if (item instanceof ImageModel) {
    return item.filename
  } else if (item.file && item.file.name) {
    return item.file.name
  } else {
    return ""
  }
}

const getAttribution = (item: ImageModel | UploadModel): string => {
  if (item instanceof ImageModel) {
    return item.attribution
  } else if (item.file && item.file.name) {
    return item.attribution
  } else {
    return ""
  }
}

const getFileSize = (item: ImageModel | UploadModel): number => {
  if (item instanceof ImageModel) {
    return item.size
  } else if (item.file && item.file.name) {
    return item.file.size
  } else {
    return 0
  }
}

const getFileSizeText = (item: ImageModel | UploadModel): string => {
  const fileSize = getFileSize(item)
  return strFileSize(fileSize)
}

const uploadDone = (
  item: ImageModel | UploadModel,
  imageContainer: ImageContainer
): boolean => {
  if (item instanceof ImageModel) {
    return true
  } else if (item instanceof UploadModel) {
    const index = imageContainer.toUpload.indexOf(item)
    return imageContainer.uploadProgress[index] === 1
  } else {
    return false
  }
}

export const FormImageField = (props: Props) => {
  const { input, submitting, showError, ...buttonProps } = props
  const fieldId = `story_${input.name}`
  return (
    <div>
      <label htmlFor={fieldId}>
        <input
          id={fieldId}
          accept="image/jpeg, image/png"
          type="file"
          multiple
          style={{ display: "none" }}
          disabled={submitting}
          onChange={handleChange(input.onChange, showError, input.value)}
        />
        <Button
          component="span"
          {...R.dissoc("imagesreordered", buttonProps) as ButtonProps}
        >
          {i18n(`storyForm.label.${input.name}`)}
        </Button>
      </label>
      <ImageList {...props as ImageListProps} />
    </div>
  )
}

const RemoveButton = (props: ImageListProps) => {
  const { classes, input, item } = props
  return (
    <Tooltip title={i18n("storyForm.label.removeImage")}>
      <IconButton
        className={classes.arrowIcon}
        onClick={handleRemove(input.onChange, input.value, item!)}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}

const UploadProgress = (props: ImageListProps) => {
  const { classes, input, item } = props
  const imageContainer = input.value
  const done = uploadDone(item!, imageContainer)

  if (done) {
    return (
      <div className={classes.indicator}>
        <IconCheck className={classes.check} fontSize="small" />
      </div>
    )
  } else {
    return (
      <div className={classes.indicator}>
        <CircularProgress size={18} thickness={6} />
      </div>
    )
  }
}

class ImageListUnstyled extends Component<ImageListProps, State> {
  constructor(props: ImageListProps) {
    super(props)
    this.state = {
      openAttributeModal: false,
      attrModalData: false,
      attrText: null,
      attrEditModeIndex: 0,
      allImages: [],
    }
  }

  static getDerivedStateFromProps(nextProps: ImageListProps, prevState: State) {
    const { input } = nextProps
    const imageContainer = input.value
    let items = imageContainer.items
    let allImages = []
    for (let k in items) {
      allImages.push(items[k])
    }
    for (let kk in imageContainer.toUpload) {
      allImages.push(imageContainer.toUpload[kk])
    }
    if (
      allImages.length !== prevState.allImages.length &&
      allImages !== prevState.allImages
    ) {
      allImages.map((item: any, i: number) => {
        if (typeof item.sortOrder === "undefined") {
          item.sortOrder = i
        }
        return item
      })
      return {
        allImages: allImages,
      }
    }
    return null
  }

  _onImagesOrderchange = (oldIndex: any, newIndex: any) => {
    let allImages = this.state.allImages
    const [removed] = allImages.splice(oldIndex, 1)
    allImages.splice(newIndex, 0, removed)
    allImages.map((item: any, i: number) => {
      item.sortOrder = i
      return item
    })

    const { input } = this.props
    let imageContainer = input.value
    let imagesItems = imageContainer.items || []
    let imagesToUpload = imageContainer.toUpload || []
    const newContainer = new ImageContainer(imageContainer)
    // assign order to already uploaded files
    if (imagesItems.length > 0) {
      imagesItems.map((item: ImageModel, i: number) => {
        for (let k in allImages) {
          if (item.id === allImages[k].id) {
            item.sortOrder = allImages[k].sortOrder
            break
          }
        }
        return item
      })
      newContainer.items = imagesItems
    }
    // assign order to be uploaded image
    if (imagesToUpload.length > 0) {
      imagesToUpload.map((item: any, i: number) => {
        for (let k in allImages) {
          if (item.file.name === allImages[k].name) {
            item.sortOrder = allImages[k].sortOrder
            break
          }
        }
        return item
      })
      newContainer.toUpload = imagesToUpload
    }
    this.setState(
      {
        allImages: allImages,
      },
      () => {
        input.onChange(newContainer)
      }
    )
  }

  getKey(item: ImageModel | UploadModel, index: number) {
    if (item instanceof ImageModel) {
      return item.id || item.filename
    } else if (item.file && item.file.name) {
      return item.file.name
    } else {
      return index.toString()
    }
  }

  _imageListItem = (
    totalItems: number,
    item: ImageModel | UploadModel,
    index: number
  ) => {
    const { classes, submitting } = this.props
    const key = this.getKey(item, index)
    const filename = getFilename(item)
    const attribution = getAttribution(item)
    const fileSizeText = getFileSizeText(item)

    return (
      <ListItem className={classes.imagebox} key={key}>
        <Grid container alignItems="center" justify="center">
          <Grid item lg={1} sm={1} xs={12} className={classes.centerOnSmall}>
            <Tooltip title={i18n("storyForm.label.moveImageUp")}>
              <div>
                <IconButton
                  className={classes.arrowIcon}
                  disabled={index > 0 ? false : true}
                  onClick={e => {
                    this._onImagesOrderchange(index, index - 1)
                  }}
                >
                  <ExpandLess fontSize="default" />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={i18n("storyForm.label.moveImageDown")}>
              <div>
                <IconButton
                  className={classes.arrowIcon}
                  disabled={
                    index < totalItems - 1 && totalItems - 1 > 0 ? false : true
                  }
                  onClick={() => {
                    this._onImagesOrderchange(index, index + 1)
                  }}
                >
                  <ExpandMore fontSize="default" />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid item lg={3} sm={4} xs={12} className={classes.center}>
            <ListItemAvatar>
              <ImagePreview item={item} className={classes.image} />
            </ListItemAvatar>
          </Grid>
          <Grid item lg={5} sm={4} xs={6} className={classes.centerOnSmall}>
            <ListItemText primary={filename} secondary={attribution} />
          </Grid>
          <Grid item lg={2} sm={2} xs={6} className={classes.centerOnSmall}>
            <ListItemText primary={fileSizeText} />
          </Grid>

          <Grid item lg={1} sm={1} xs={12} className={classes.centerOnSmall}>
            {item instanceof File ? null : (
              <Tooltip title={i18n("storyForm.label.editAttribution")}>
                <IconButton
                  className={classes.arrowIcon}
                  onClick={() => this._showAttributeModal(index, item)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}

            {(submitting && <UploadProgress item={item} {...this.props} />) || (
              <RemoveButton item={item} {...this.props} />
            )}
          </Grid>
        </Grid>
      </ListItem>
    )
  }

  _renderEmpty = () => {
    const { classes } = this.props
    return (
      <div className={classes.empty}>{i18n("storyForm.label.noImage")}</div>
    )
  }

  _showAttributeModal = (index: number, item: ImageModel | UploadModel) => {
    this.setState({
      openAttributeModal: true,
      attrModalData: item,
      attrText: null,
      attrEditModeIndex: index,
    })
  }

  _updateItemAttribute = () => {
    const { input } = this.props
    const { attrModalData, attrText } = this.state
    let imageContainer = input.value
    // uploaded files
    let imagesItems = input.value.items || []
    if (imagesItems && imagesItems.length > 0) {
      const newContainer = new ImageContainer(imageContainer)
      imagesItems.map((item: ImageModel) => {
        item.attribution = item.attribution || ""
        if (item.id === attrModalData.id) {
          item.attribution = attrText
        }
        return item
      })
      newContainer.items = imagesItems
      input.onChange(newContainer)
    }

    // to be uploaded files
    let imagesItemsToUpload = input.value.toUpload || []
    if (imagesItemsToUpload && imagesItemsToUpload.length > 0) {
      const newContainer = new ImageContainer(imageContainer)
      imagesItemsToUpload.map((item: UploadModel, index: number) => {
        item.attribution = item.attribution || ""
        if (item.file.name === attrModalData.file.name) {
          item.attribution = attrText
        }
        return item
      })
      newContainer.toUpload = imagesItemsToUpload
      input.onChange(newContainer)
    }

    this.setState({
      openAttributeModal: false,
    })
  }

  _getAttrModalContent = () => {
    const { attrModalData } = this.state
    const title =
      attrModalData.filename ||
      attrModalData.name ||
      i18n("storyForm.label.image")
    let defautlValue =
      this.state.attrText != null
        ? this.state.attrText
        : attrModalData.attribution
    return (
      <div>
        <Dialog
          open={this.state.openAttributeModal}
          onClose={() => {}}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {i18n("storyForm.label.addAttributionTitle")} {title}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="attribute"
              label={i18n("storyForm.label.attribution")}
              type="text"
              defaultValue={defautlValue}
              fullWidth
              onChange={e => {
                this.setState({
                  attrText: e.target.value,
                })
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({
                  openAttributeModal: false,
                  attrModalData: false,
                })
              }
              color="primary"
            >
              {i18n("storyForm.label.attributionCancel")}
            </Button>
            <Button
              onClick={() => {
                this._updateItemAttribute()
              }}
              color="primary"
            >
              {i18n("storyForm.label.attributionSave")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  _renderContent = () => {
    const { classes } = this.props
    const { allImages } = this.state
    allImages.sort(function(a: any, b: any) {
      return a.sortOrder - b.sortOrder
    })
    return (
      <Grid container>
        <Grid item className={classes.list}>
          <List dense={false} className={classes.list}>
            {allImages.map((image: ImageModel | UploadModel, index: number) =>
              this._imageListItem(allImages.length, image, index)
            )}
          </List>
        </Grid>
        {this.state.openAttributeModal ? this._getAttrModalContent() : null}
      </Grid>
    )
  }

  render() {
    const { input } = this.props
    const imageContainer = input.value
    return imageContainer.items.length > 0 || imageContainer.toUpload.length > 0
      ? this._renderContent()
      : this._renderEmpty()
  }
}

interface ImagePreviewProps {
  item: ImageModel | UploadModel
  className: string
}

const ImagePreview: FunctionComponent<ImagePreviewProps> = (
  props: ImagePreviewProps
) => {
  const { item, className } = props
  const image = useStoryImage(item)

  if (image) {
    return <img src={image} alt="" className={className} />
  } else {
    return null
  }
}

const ImageList = withStyles(imageListStyles)(ImageListUnstyled)
