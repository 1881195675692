"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.zoomToGeohashPrecision = function (levels, zoom) {
  for (var precision in levels) {
    var levelZoom = levels[precision];

    if (levelZoom > zoom) {
      return Number(precision);
    }
  }

  return 8;
};