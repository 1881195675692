"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var CustomerModel =
/** @class */
function () {
  function CustomerModel(params) {
    this.id = "";
    this.name = "";
    this.storyCount = 0;
    this.lastAction = "";

    if (params) {
      this.id = params.id;
      this.name = params.name;

      if (params.storyCount) {
        this.storyCount = params.storyCount;
      }

      if (params.lastAction) {
        this.lastAction = params.lastAction;
      }
    }
  }

  return CustomerModel;
}();

exports.CustomerModel = CustomerModel;