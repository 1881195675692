import React from "react"
import { SignIn } from "aws-amplify-react"
import {
  FormSection,
  FormField,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Button,
  Link,
  Hint,
  Input,
  InputLabel,
  SectionFooterPrimaryContent,
} from "aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React"
import i18n from "../../locales"

class CustomSignIn extends SignIn {
  constructor(props: any) {
    super(props)

    this.setState = super.setState
    this.render = super.render
  }

  showComponent(theme: any) {
    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>{i18n("signIn.header")}</SectionHeader>
        <form onSubmit={this.signIn}>
          <SectionBody theme={theme}>
            <FormField theme={theme}>
              <InputLabel theme={theme}>
                {i18n("signIn.form.username.label")} *
              </InputLabel>
              <Input
                autoFocus
                placeholder={i18n("signIn.form.username.placeholder")}
                theme={theme}
                key="username"
                name="username"
                onChange={this.handleInputChange}
              />
            </FormField>
            <FormField theme={theme}>
              <InputLabel theme={theme}>
                {i18n("signIn.form.password.label")} *
              </InputLabel>
              <Input
                placeholder={i18n("signIn.form.password.placeholder")}
                theme={theme}
                key="password"
                type="password"
                name="password"
                onChange={this.handleInputChange}
              />
              <Hint theme={theme}>
                <Link
                  theme={theme}
                  onClick={() => this.changeState("forgotPassword")}
                >
                  {i18n("signIn.forgotPassword")}
                </Link>
              </Hint>
            </FormField>
          </SectionBody>
          <SectionFooter theme={theme}>
            <SectionFooterPrimaryContent theme={theme}>
              <Button theme={theme} type="submit" disabled={this.state.loading}>
                {i18n("signIn.form.submit")}
              </Button>
            </SectionFooterPrimaryContent>
          </SectionFooter>
        </form>
      </FormSection>
    )
  }

  props: any
  state: any = {}
  refs: any
  context: any
  setState: any
  forceUpdate: any
  render: any
}

export default CustomSignIn
