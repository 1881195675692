import { createAction } from "typesafe-actions"
import {
  listCustomers,
  searchCustomerDetails,
} from "../../../services/customer"
import * as toastsActions from "../../toasts/actions"

const SET_CUSTOMERS = "[CUSTOMERS] SET_CUSTOMERS"
const SET_CUSTOMER_DETAILS = "[CUSTOMERS] SET_CUSTOMER_DETAILS"

export const retrieveCustomers = () => (dispatch: any, getState: any) => {
  listCustomers()
    .then(({ data }: any) => {
      dispatch(setCustomers(data))
      dispatch(retrieveCustomerDetails())
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const retrieveCustomerDetails = () => (dispatch: any, getState: any) => {
  searchCustomerDetails()
    .then(({ data }: any) => {
      dispatch(setCustomerDetails(data))
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const setCustomers = createAction(
  SET_CUSTOMERS,
  resolve => ({ listCustomers }: any) => resolve(listCustomers.items)
)

export const setCustomerDetails = createAction(
  SET_CUSTOMER_DETAILS,
  resolve => ({ searchCustomerDetails }: any) =>
    resolve(searchCustomerDetails.aggregations)
)
