import { createAction, createStandardAction } from "typesafe-actions"
import { Coords } from "google-map-react"
import * as storyActions from "../../stories/actions"
import * as mapActions from "../../map/actions"
import { StoryModel } from "../../../models"

const SHOW_PREVIEW = "[MODALS] SHOW_PREVIEW"
const HIDE_PREVIEW = "[MODALS] HIDE_PREVIEW"

export const showStoryPreview = (
  story: string | StoryModel,
  coords?: Coords
) => (dispatch: any) => {
  if (typeof story === "string") {
    dispatch(storyActions.resetPreview())
    dispatch(showStoryPreviewId(story))
  } else {
    dispatch(storyActions.setPreview(story))
    dispatch(showStoryPreviewId(story.id))

    const panCoords = {
      lat: story.geoLatitude,
      lng: story.geoLongitude,
    }
    dispatch(mapActions.panTo(panCoords, 0.25))
  }

  if (coords) {
    dispatch(mapActions.panTo(coords, 0.25))
  }
}

export const showStoryPreviewId = createStandardAction(SHOW_PREVIEW)<string>()

export const hideStoryPreview = createAction(HIDE_PREVIEW)
