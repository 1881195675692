// tslint:disable
// this is an auto generated file. This will be overwritten

export const searchStoriesInBounds = `query SearchStoriesInBounds(
  $filter: SearchableStoriesInBoundsInput!
  $limit: Int
  $nextToken: Int
) {
  searchStoriesInBounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    aggregations {
      stories {
        key
        doc_count
      }
    }
    total
    nextToken
  }
}
`;
export const searchUserStories = `query SearchUserStories(
  $filter: SearchableUserStoriesInput!
  $limit: Int
  $nextToken: Int
) {
  searchUserStories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    total
    nextToken
  }
}
`;
export const searchChildStories = `query SearchChildStories(
  $filter: SearchableChildStoriesInput!
  $limit: Int
  $nextToken: Int
) {
  searchChildStories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    total
    nextToken
  }
}
`;
export const searchStoriesByQuery = `query SearchStoriesByQuery(
  $filter: SearchableStoriesByQueryInput!
  $limit: Int
  $nextToken: Int
) {
  searchStoriesByQuery(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    total
    nextToken
  }
}
`;
export const searchAvailableStories = `query SearchAvailableStories(
  $filter: SearchableAvailableStoriesInput!
  $limit: Int
  $nextToken: Int
) {
  searchAvailableStories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    total
    nextToken
  }
}
`;
export const searchCustomerDetails = `query SearchCustomerDetails($limit: Int, $nextToken: Int) {
  searchCustomerDetails(limit: $limit, nextToken: $nextToken) {
    aggregations {
      id
      storyCount
      lastAction
    }
  }
}
`;
export const searchSuperUserDetails = `query SearchSuperUserDetails(
  $customerId: String!
  $limit: Int
  $nextToken: Int
) {
  searchSuperUserDetails(
    customerId: $customerId
    limit: $limit
    nextToken: $nextToken
  ) {
    userCreated {
      username
      storyCount
    }
    userModified {
      username
      lastAction
    }
  }
}
`;
export const searchCategoryDetails = `query SearchCategoryDetails($limit: Int, $nextToken: Int) {
  searchCategoryDetails(limit: $limit, nextToken: $nextToken) {
    aggregations {
      id
      storyCount
    }
  }
}
`;
export const getStory = `query GetStory($id: ID!) {
  getStory(id: $id) {
    id
    name
    textExpo
    textMain
    imagesExpo {
      items {
        id
        identityId
        filename
        original
        owner
        sortOrder
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    imagesMain {
      items {
        id
        identityId
        filename
        original
        owner
        sortOrder
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    audiosExpo {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    audiosMain {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    videosExpo {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    videosMain {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    category {
      id
      names
    }
    parent {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    geoRadius
    geoAltitude
    geoPoint {
      lat
      lon
    }
    dataOrigin {
      id
      name
      image
    }
    reference
    references
    submitter {
      id
      firstName
      middleName
      lastName
      email
      showName
      authorized
      adult
      wpUserId
      wpEntryId
    }
    stars
    arSceneName
    private
    status
    comment
    dateStory
    owner
    userCreated
    userModified
    analyticsUnlockCount
    analyticsClickCount
    version
  }
}
`;
export const getDataOrigin = `query GetDataOrigin($id: ID!) {
  getDataOrigin(id: $id) {
    id
    name
    image
  }
}
`;
export const listDataOrigins = `query ListDataOrigins(
  $filter: ModelDataOriginFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataOrigins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      image
    }
    nextToken
  }
}
`;
export const getImage = `query GetImage($id: ID!) {
  getImage(id: $id) {
    id
    identityId
    filename
    original
    owner
    sortOrder
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const listImages = `query ListImages(
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      identityId
      filename
      original
      owner
      sortOrder
      attribution
      size
      storyExpo {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      storyMain {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
    }
    nextToken
  }
}
`;
export const getAudio = `query GetAudio($id: ID!) {
  getAudio(id: $id) {
    id
    identityId
    filename
    original
    owner
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const listAudios = `query ListAudios(
  $filter: ModelAudioFilterInput
  $limit: Int
  $nextToken: String
) {
  listAudios(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      identityId
      filename
      original
      owner
      attribution
      size
      storyExpo {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      storyMain {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
    }
    nextToken
  }
}
`;
export const getVideo = `query GetVideo($id: ID!) {
  getVideo(id: $id) {
    id
    identityId
    filename
    original
    owner
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const listVideos = `query ListVideos(
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      identityId
      filename
      original
      owner
      attribution
      size
      storyExpo {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      storyMain {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
    }
    nextToken
  }
}
`;
export const getCategory = `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    names
  }
}
`;
export const listCategories = `query ListCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      names
    }
    nextToken
  }
}
`;
export const getSubmitter = `query GetSubmitter($id: ID!) {
  getSubmitter(id: $id) {
    id
    firstName
    middleName
    lastName
    email
    showName
    authorized
    adult
    wpUserId
    wpEntryId
  }
}
`;
export const listSubmitters = `query ListSubmitters(
  $filter: ModelSubmitterFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubmitters(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      middleName
      lastName
      email
      showName
      authorized
      adult
      wpUserId
      wpEntryId
    }
    nextToken
  }
}
`;
export const getCustomer = `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    id
    name
  }
}
`;
export const listCustomers = `query ListCustomers(
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
    }
    nextToken
  }
}
`;
export const getRegularUser = `query GetRegularUser($id: ID!) {
  getRegularUser(id: $id) {
    id
    email
    postSplashScreenAccepted
    visitedStories
  }
}
`;
export const listRegularUsers = `query ListRegularUsers(
  $filter: ModelRegularUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listRegularUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      postSplashScreenAccepted
      visitedStories
    }
    nextToken
  }
}
`;
export const searchStories = `query SearchStories(
  $filter: SearchableStoryFilterInput
  $sort: SearchableStorySortInput
  $limit: Int
  $nextToken: Int
) {
  searchStories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    nextToken
  }
}
`;
