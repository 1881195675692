const superGroup = "SuperUsers"
const adminGroup = "AdminUsers"

export const isSuper = (user?: CurrentUser): boolean => {
  if (user) {
    return user.groups.includes(superGroup)
  } else {
    return false
  }
}

export const isAdmin = (user?: CurrentUser): boolean => {
  if (user) {
    return user.groups.includes(adminGroup)
  } else {
    return false
  }
}

export const welcomeText = (user?: CurrentUser): string => {
  if (!user) {
    return ""
  }

  const customer = user.customerName
  const username = user.username

  if (customer) {
    return `${customer} / ${username}`
  } else {
    return username
  }
}
