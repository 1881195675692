import React, { Component } from "react"
import { connect } from "react-redux"
import GoogleMapReact, {
  BootstrapURLKeys,
  Bounds,
  ChangeEventValue,
  ClickEventValue,
  Coords,
  Props as MapProps,
} from "google-map-react"
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  Fab,
  Tooltip,
  Hidden,
} from "@material-ui/core"
import IconNotes from "@material-ui/icons/Notes"
import IconRoom from "@material-ui/icons/Room"
import IconZoomIn from "@material-ui/icons/ZoomIn"
import IconZoomOut from "@material-ui/icons/ZoomOut"

import StoryMarker from "./StoryMarker"
import StoryCluster from "./StoryCluster"
import CategoryFilter from "./CategoryFilter"
import StoryPreview from "./StoryPreview"
import MapSearch from "./MapSearch"
import MapSpeedDial from "./MapSpeedDial"
import appContext from "../../AppContext"
import {
  StoryModel,
  StoryMarkerModel,
  StoryClusterModel,
  CategoryModel,
} from "../../models"
import { RootState } from "../../state/store"
import { getAllCategoriesSelector } from "../../state/categories/selectors"
import * as categoryActions from "../../state/categories/actions"
import * as storyActions from "../../state/stories/actions"
import * as mapActions from "../../state/map/actions"
import * as modalFormActions from "../../state/modals/story-form/actions"
import * as modalPreviewActions from "../../state/modals/story-preview/actions"
import * as modalListingActions from "../../state/modals/story-listing/actions"
import i18n from "../../locales"

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
      width: "100vw",
      height: "calc(100vh - 64px)",
    },
    vignette: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
      mixBlendMode: "multiply",
      pointerEvents: "none",
    },
    myLocationButton: {
      position: "fixed",
      top: 80,
      right: 7,
      color: "#333",
      background: "white",
      "&:hover": {
        color: "white",
      },
    },
    mapZoomIn: {
      zIndex: 2,
      position: "fixed",
      top: 150,
      right: 7,
      color: "#333",
      background: "white",
      "&:hover": {
        color: "white",
      },
    },
    mapZoomOut: {
      zIndex: 2,
      position: "fixed",
      top: 210,
      right: 7,
      color: "#333",
      background: "white",
      "&:hover": {
        color: "white",
      },
    },
    storyListingButton: {
      position: "fixed",
      left: theme.spacing(4),
      bottom: theme.spacing(4),
      padding: "0 2rem",
      color: "#333",
      background: "white",
      "&:hover": {
        color: "white",
      },
    },
    storyListingButtonIcon: {
      marginLeft: theme.spacing(),
    },
    addStoryButton: {
      position: "fixed",
      right: "70px",
      bottom: theme.spacing(4),
      padding: "0 2rem",
      background: "white",
      color: "#333",
      "&:hover": {
        background: "white",
        color: "#333",
      },
    },
    addStoryButtonActive: {
      background: "#3f51b5",
      color: "white",
      "&:hover": {
        background: "#3f51b5",
        color: "white",
      },
    },
    [theme.breakpoints.down("sm")]: {
      storyListingButton: {
        display: "none",
      },
      addStoryButton: {
        display: "none",
      },
      myLocationButton: {
        display: "none",
      },
      mapZoomIn: {
        top: 80,
      },
      mapZoomOut: {
        top: 140,
      },
    },
  })

const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#ebe3cd",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#523735",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f1e6",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#d5df9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#c34940",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#fefb54",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c34940",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#e4fcfd",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7abdf8",
      },
    ],
  },
]

interface Props extends WithStyles<typeof styles> {
  defaultCenter: Coords
  defaultZoom: number

  categories: CategoryModel[]
  categoriesFilter: string[]
  storyMarkers: StoryMarkerModel[]
  storyClusters: StoryClusterModel[]
  panTarget: PanTarget | null
  zoomTarget: PanTarget | null
  parentStory?: StoryModel
  editedStory?: StoryModel
  isAddStoryModeEnabled: boolean
  isAddChildStoryModeEnabled: boolean
  isEditStoryModeEnabled: boolean

  retrieveCategories: () => void
  retrieveStories: (
    bounds: Bounds,
    zoom: number,
    owner: string,
    categories: string[]
  ) => void
  showPreview: (id: string, coords?: Coords) => void
  showCreateStoryForm: (story: StoryModel) => void
  toggleCategoriesFilter: (categoryId: string) => void
  toggleAllCategoriesFilter: () => void
  enableAddStoryMode: () => void
  disableAllStoryModes: () => void
  showStoryListing: (currentUser: CurrentUser) => void
  panToMyLocation: () => void
  zoomIn: (coords: Coords, zoom?: number) => void
  editStory: (story: StoryModel) => void
}

type State = {
  map?: any
  bounds?: Bounds
  isFilterVisible: boolean
  zoomValue: number
}

class Map extends Component<Props, State> {
  static contextType = appContext

  static defaultProps = {
    defaultCenter: {
      lat: 59.91,
      lng: 10.75,
    },
    defaultZoom: 11,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      isFilterVisible: false,
      zoomValue: 11,
    }

    this.onChange = this.onChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidUpdate(oldProps: Props) {
    if (
      oldProps.panTarget !== this.props.panTarget &&
      this.props.panTarget &&
      this.state.map
    ) {
      const target = this.props.panTarget

      if (this.state.map.getZoom() < target.zoom) {
        this.state.map.setZoom(target.zoom)
      }

      this.state.map.panTo(target.coords)

      this.state.map.panBy(window.innerWidth * target.offset, 0)
    }

    if (
      oldProps.zoomTarget !== this.props.zoomTarget &&
      this.props.zoomTarget &&
      this.state.map
    ) {
      const target = this.props.zoomTarget

      this.setState({
        zoomValue: this.state.zoomValue + target.zoom,
      })

      this.state.map.panTo(target.coords)
      this.state.map.panBy(window.innerWidth * target.offset, 0)
    }

    if (
      oldProps.categoriesFilter !== this.props.categoriesFilter &&
      this.state.bounds &&
      this.state.map
    ) {
      this.props.retrieveStories(
        this.state.bounds,
        this.state.map.getZoom(),
        this.context.currentUser.customerId,
        this.props.categoriesFilter
      )
    }
  }

  onChange(event: ChangeEventValue) {
    this.setState({
      bounds: event.marginBounds,
    })

    this.props.retrieveStories(
      event.marginBounds,
      event.zoom,
      this.context.currentUser.customerId,
      this.props.categoriesFilter
    )
  }

  onClick(point: ClickEventValue) {
    if (this.props.isAddStoryModeEnabled) {
      const story = new StoryModel()
      story.geoPoint = {
        lat: point.lat,
        lon: point.lng,
      }
      this.props.showCreateStoryForm(story)
      this.props.disableAllStoryModes()
    }

    if (this.props.isAddChildStoryModeEnabled) {
      const parentStory = this.props.parentStory!
      const story = new StoryModel()
      story.geoPoint = {
        lat: point.lat,
        lon: point.lng,
      }
      story.textExpo = parentStory.textExpo
      story.storyParentId = parentStory.id
      story.category = new CategoryModel(parentStory.category)
      story.storyCategoryId = parentStory.storyCategoryId
      this.props.showCreateStoryForm(story)
      this.props.disableAllStoryModes()
    }

    if (this.props.isEditStoryModeEnabled) {
      const story = this.props.editedStory!
      story.geoPoint = {
        lat: point.lat,
        lon: point.lng,
      }
      story.geoLatitude = point.lat
      story.geoLongitude = point.lng
      this.props.editStory(story)
      this.props.disableAllStoryModes()
    }
  }

  onGoogleApiLoaded: SubField<MapProps, "onGoogleApiLoaded"> = ({
    map,
    maps,
  }) => {
    const hiddenMapType = new maps.ImageMapType({
      getTileUrl(coord: { x: string; y: string }, zoom: string) {
        return (
          `https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?` +
          `layers=topo4&zoom=${zoom}&x=${coord.x}&y=${coord.y}&format=image/png`
        )
      },
      maxZoom: 20,
      minZoom: 0,
      name: "Hidden",
    })

    map.overlayMapTypes.push(hiddenMapType)

    this.setState({ map })
    this.props.retrieveCategories()
  }

  enableAddStoryMode = () => {
    this.props.enableAddStoryMode()
  }

  disableAllStoryModes = () => {
    this.props.disableAllStoryModes()
  }

  toggleFilterModal = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible,
    })
  }

  render() {
    const {
      classes,
      categoriesFilter,
      categories,
      toggleCategoriesFilter,
      toggleAllCategoriesFilter,
      isAddStoryModeEnabled,
      isAddChildStoryModeEnabled,
      isEditStoryModeEnabled,
      showStoryListing,
      panToMyLocation,
    } = this.props

    const showMarkers = this.props.storyMarkers.length > 0
    const isPlacingMarker =
      isAddStoryModeEnabled ||
      isAddChildStoryModeEnabled ||
      isEditStoryModeEnabled
    const cursorToShow = isPlacingMarker ? "crosshair" : ""

    return (
      <div className={classes.wrapper}>
        <GoogleMapReact
          bootstrapURLKeys={this.googleApiParams()}
          defaultCenter={this.props.defaultCenter}
          defaultZoom={this.props.defaultZoom}
          onClick={this.onClick}
          onChange={this.onChange}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.onGoogleApiLoaded}
          options={() => ({
            fullscreenControl: false,
            zoomControl: false,
            draggableCursor: cursorToShow,
            styles: mapStyles,
          })}
          zoom={this.state.zoomValue}
        >
          {(showMarkers &&
            this.props.storyMarkers.map((marker: StoryMarkerModel) => (
              <StoryMarker
                key={marker.id}
                marker={marker}
                lat={marker.geoPoint.lat}
                lng={marker.geoPoint.lon}
                showPreview={this.props.showPreview}
              />
            ))) ||
            this.props.storyClusters.map((cluster: StoryClusterModel) => (
              <StoryCluster
                key={cluster.hash}
                hash={cluster.hash}
                lat={cluster.geoPoint.lat}
                lng={cluster.geoPoint.lon}
                count={cluster.count}
                zoomIn={this.props.zoomIn}
              />
            ))}
        </GoogleMapReact>

        {this.state.map && (
          <MapSearch
            map={this.state.map}
            showPreview={this.props.showPreview}
          />
        )}

        <MapSpeedDial
          isPlacingMarker={isPlacingMarker}
          openMyStories={() => showStoryListing(this.context.currentUser)}
          openFilter={this.toggleFilterModal}
          panToMyLocation={panToMyLocation}
          addNewStory={
            isPlacingMarker
              ? this.disableAllStoryModes
              : this.enableAddStoryMode
          }
        />

        <img src="/vignette.png" alt="" className={classes.vignette} />

        <Fab
          className={classes.myLocationButton}
          color="primary"
          size="medium"
          onClick={panToMyLocation}
        >
          <IconRoom />
        </Fab>

        <Fab
          className={classes.mapZoomIn}
          color="primary"
          size="medium"
          onClick={() => {
            this.setState({
              zoomValue: this.state.zoomValue + 1,
            })
          }}
        >
          <IconZoomIn />
        </Fab>

        <Fab
          className={classes.mapZoomOut}
          color="primary"
          size="medium"
          onClick={() => {
            this.setState({
              zoomValue: this.state.zoomValue - 1,
            })
          }}
        >
          <IconZoomOut />
        </Fab>

        <Fab
          className={classes.storyListingButton}
          color="primary"
          size="large"
          variant="extended"
          onClick={() => showStoryListing(this.context.currentUser)}
        >
          {i18n("map.button.myStories")}
          <IconNotes className={classes.storyListingButtonIcon} />
        </Fab>
        <CategoryFilter
          toggleFilterModal={this.toggleFilterModal}
          isFilterVisible={this.state.isFilterVisible}
          categories={categories}
          categoriesFilter={categoriesFilter}
          toggleCategoriesFilter={toggleCategoriesFilter}
          toggleAllCategoriesFilter={toggleAllCategoriesFilter}
        />
        <Hidden smDown>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={isPlacingMarker}
            title={i18n("map.tooltip.addStoryButton")}
          >
            <Fab
              className={
                isPlacingMarker
                  ? `${classes.addStoryButton} ${classes.addStoryButtonActive}`
                  : classes.addStoryButton
              }
              color="primary"
              size="large"
              variant="extended"
              onClick={
                isPlacingMarker
                  ? this.disableAllStoryModes
                  : this.enableAddStoryMode
              }
            >
              {i18n("map.button.addStory")}
            </Fab>
          </Tooltip>
        </Hidden>

        <StoryPreview
          customerId={this.context.currentUser.customerId}
          showPreview={this.props.showPreview}
        />
      </div>
    )
  }

  googleApiParams(): BootstrapURLKeys {
    const key = process.env.REACT_APP_GOOGLE_MAP_KEY || ""
    const libraries = "places"
    const language = "no"

    return {
      key: `${key}&libraries=${libraries}&language=${language}`,
    }
  }
}

const componentWithStyles = withStyles(styles)(Map)

const mapStateToProps = (state: RootState) => ({
  categories: getAllCategoriesSelector(state),
  categoriesFilter: state.categories.allFilteredIds,
  storyMarkers: state.stories.markers,
  storyClusters: state.stories.clusters,
  panTarget: state.map.panTarget,
  zoomTarget: state.map.zoomTarget,
  parentStory: state.map.parentStory,
  editedStory: state.map.editedStory,
  isAddStoryModeEnabled: state.map.isAddStoryModeEnabled,
  isAddChildStoryModeEnabled: state.map.isAddChildStoryModeEnabled,
  isEditStoryModeEnabled: state.map.isEditStoryModeEnabled,
})

const mapDispatchToProps = (dispatch: any) => ({
  retrieveCategories: () => {
    dispatch(categoryActions.retrieveCategories())
  },
  retrieveStories: (
    bounds: Bounds,
    zoom: number,
    owner: string,
    categories: string[]
  ) => {
    if (categories.length > 0) {
      dispatch(storyActions.retrieveMapStories(bounds, zoom, owner, categories))
    } else {
      dispatch(storyActions.setMarkers([]))
      dispatch(storyActions.setClusters([]))
    }
  },
  showPreview: (id: string, coords?: Coords) => {
    dispatch(modalPreviewActions.showStoryPreview(id, coords))
  },
  showCreateStoryForm: (story: StoryModel) => {
    dispatch(modalFormActions.openStoryForm(story))
  },
  toggleCategoriesFilter: (categoryId: string) => {
    dispatch(categoryActions.toggleCategoriesFilter(categoryId))
  },
  toggleAllCategoriesFilter: () => {
    dispatch(categoryActions.toggleAllCategoriesFilter())
  },
  enableAddStoryMode: () => {
    dispatch(mapActions.enableAddStoryMode())
  },
  disableAllStoryModes: () => {
    dispatch(mapActions.disableAddStoryMode())
    dispatch(mapActions.disableAddChildStoryMode())
    dispatch(mapActions.disableEditStoryPositionMode())
  },
  showStoryListing: (currentUser: CurrentUser) => {
    dispatch(modalListingActions.showStoryListing(currentUser))
  },
  panToMyLocation: () => {
    dispatch(mapActions.panToMyLocation())
  },
  zoomIn: (coords: Coords, zoom: number = 2) => {
    dispatch(mapActions.zoomIn(coords, zoom))
  },
  editStory: (story: StoryModel) => {
    dispatch(storyActions.editStory(story))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(componentWithStyles)
