import { createAction, createAsyncAction } from "typesafe-actions"
import * as categoryActions from "../../admin/categories/actions"
import * as toastsActions from "../../toasts/actions"
import { CategoryModel } from "../../../models"
import {
  createCategoryFromModel,
  updateCategoryWithModel,
  deleteCategoryById,
  uploadCategoryImage,
} from "../../../services/category"

const OPEN_CATEGORY_FORM = "[MODALS] OPEN CATEGORY FORM"
const CLOSE_CATEGORY_FORM = "[MODALS] CLOSE CATEGORY FORM"
const SUBMIT_REQUEST = "[MODALS] SUBMIT_REQUEST"
const SUBMIT_REQUEST_SUCCESS = "[MODALS] SUBMIT_REQUEST_SUCCESS"
const SUBMIT_REQUEST_FAILURE = "[MODALS] SUBMIT_REQUEST_FAILURE"

export const openCategoryForm = createAction(
  OPEN_CATEGORY_FORM,
  resolve => (category?: CategoryModel) => resolve(category)
)

export const closeCategoryForm = createAction(CLOSE_CATEGORY_FORM)

export const submitStatus = createAsyncAction(
  SUBMIT_REQUEST,
  SUBMIT_REQUEST_SUCCESS,
  SUBMIT_REQUEST_FAILURE
)<void, void, void>()

export const createCategory = (category: CategoryModel) => (dispatch: any) => {
  dispatch(submitStatus.request())
  createCategoryFromModel(category)
    .then(() => {
      dispatch(submitStatus.success())
      uploadCategoryImage(category).then(() => {
        dispatch(categoryActions.retrieveCategories())
      })
    })
    .catch((error: any) => {
      dispatch(submitStatus.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const updateCategory = (category: CategoryModel) => (dispatch: any) => {
  dispatch(submitStatus.request())
  updateCategoryWithModel(category)
    .then(() => {
      dispatch(submitStatus.success())
      uploadCategoryImage(category).then(() => {
        dispatch(categoryActions.retrieveCategories())
      })
    })
    .catch((error: any) => {
      dispatch(submitStatus.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const deleteCategory = (id: string) => (dispatch: any) => {
  dispatch(submitStatus.request())
  deleteCategoryById(id)
    .then(() => {
      dispatch(submitStatus.success())
      dispatch(categoryActions.removeCategory(id))
    })
    .catch((error: any) => {
      dispatch(submitStatus.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}
