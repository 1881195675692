import React, { FunctionComponent, forwardRef, useEffect, useMemo } from "react"
import { compose, Dispatch } from "redux"
import { connect } from "react-redux"
import { InjectedFormProps, reduxForm, Field, initialize } from "redux-form"
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Slide,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { FormTextField } from "./FormTextField"
import { CategoryFormImageField } from "./CategoryFormImageField"
import { CategoryModel } from "../../models"
import i18n, { availableLanguages } from "../../locales"
import { RootState } from "../../state/store"
import * as categoryFormActions from "../../state/modals/category-form/actions"
import { requiredField } from "../../validators/required"
import { maxLength, minLength } from "../../validators/lenght"

const form = "categoryForm"

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
    },
    form: {
      padding: `0 ${theme.spacing(3)}px`,
    },
    actions: {
      textAlign: "right",
      padding: `${theme.spacing(3)}px 0`,
    },
    button: {
      marginLeft: theme.spacing(2),
    },
  })

interface Props
  extends WithStyles<typeof styles>,
    InjectedFormProps<CategoryModel> {
  dispatch: Dispatch

  shown: boolean
  submitting: boolean
  category: CategoryModel
  isNewCategory: boolean

  submitCategory: (category: CategoryModel, isNew: boolean) => void
  closeDialog: () => void
}

const CategoryForm: FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    dispatch,
    handleSubmit,

    shown,
    submitting,
    category,
    isNewCategory,

    submitCategory,
    closeDialog,
  } = props

  useEffect(() => {
    dispatch(initialize(form, category))
  }, [dispatch, category])

  const validators = useMemo(
    () => ({
      id: [requiredField, minLength(4), maxLength(200)],
      name: [requiredField, minLength(4), maxLength(200)],
    }),
    []
  )

  const onSubmit = (values: CategoryModel, dispatch: Dispatch) => {
    submitCategory(values, isNewCategory)
  }

  return (
    <Dialog
      open={shown}
      classes={{ paper: classes.paper }}
      onClose={closeDialog}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {isNewCategory
          ? i18n("adminCategoryForm.header.new")
          : i18n("adminCategoryForm.header.edit")}
      </DialogTitle>
      <form className={classes.form}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Field
              name="id"
              props={{ form: "adminCategoryForm" }}
              component={FormTextField}
              validate={validators.id}
              InputProps={{
                readOnly: !isNewCategory,
                disabled: !isNewCategory,
              }}
            />
          </Grid>
          {availableLanguages.map((language: string) => (
            <Grid key={language} item xs={12}>
              <Field
                name={`names[${language}]`}
                props={{ form: "adminCategoryForm" }}
                component={FormTextField}
                validate={validators.name}
                label={`${i18n("adminCategoryForm.label.names")} (${language})`}
                placeholder={`${i18n(
                  "adminCategoryForm.placeholder.names"
                )} (${language})`}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Field
              name="image"
              props={{ form: "adminCategoryForm" }}
              component={CategoryFormImageField}
              categoryId={category.id}
            />
          </Grid>
          <Grid item xs={12} className={classes.actions}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={closeDialog}
            >
              {i18n("adminCategoryForm.button.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit(onSubmit)}
              disabled={submitting}
            >
              {i18n("adminCategoryForm.button.submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  )
}

const Transition = forwardRef((props: TransitionProps, ref: any) => (
  <Slide direction="up" {...props} ref={ref} />
))

const mapStateToProps = (state: RootState) => ({
  shown: state.modals.categoryForm.shown,
  submitting: state.modals.categoryForm.submitting,
  category: state.modals.categoryForm.category,
  isNewCategory: state.modals.categoryForm.isNewCategory,
})

const mapDispatchToProps = (dispatch: any) => ({
  submitCategory: (category: CategoryModel, isNew: boolean) => {
    if (isNew) {
      dispatch(categoryFormActions.createCategory(category))
    } else {
      dispatch(categoryFormActions.updateCategory(category))
    }
  },
  closeDialog: () => {
    dispatch(categoryFormActions.closeCategoryForm())
  },
})

export default compose<FunctionComponent>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  reduxForm({ form })
)(CategoryForm)
