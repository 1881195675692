import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import * as storyActions from "../../stories/actions"
import { StoryModel } from "../../../models"

type State = {
  shown: boolean
  story: StoryModel
  submitting: boolean
  submitProgress: number
}

const initialState = {
  shown: false,
  story: {} as StoryModel,
  submitting: false,
  submitProgress: 0,
}

export const storyFormReducer = (
  state: State = initialState,
  action: ActionType<typeof actions | typeof storyActions>
): State => {
  switch (action.type) {
    case getType(actions.openStoryForm):
      return {
        ...state,
        shown: true,
        story: { ...action.payload } as StoryModel,
      }

    case getType(storyActions.submitStatus.request):
      return {
        ...state,
        submitting: true,
        submitProgress: 0,
      }

    case getType(storyActions.submitStatus.success):
      return {
        ...state,
        shown: false,
        submitting: false,
      }

    case getType(storyActions.submitStatus.failure):
      return {
        ...state,
        submitting: false,
      }

    case getType(storyActions.submitStatusProgress):
      return {
        ...state,
        submitProgress: state.submitProgress + 1,
      }

    case getType(actions.closeStoryForm):
      return {
        ...state,
        shown: false,
      }

    default:
      return state
  }
}
