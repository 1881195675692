import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { CustomerModel } from "../../../models"

type State = {
  shown: boolean
  customer: CustomerModel
  submitting: boolean
}

const initialState = {
  shown: false,
  customer: {} as CustomerModel,
  submitting: false,
}

export const customerFormReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.openCustomerForm):
      if ("payload" in action) {
        return {
          ...state,
          shown: true,
          customer: new CustomerModel(action.payload),
        }
      } else {
        return {
          ...state,
          shown: true,
          customer: new CustomerModel(),
        }
      }

    case getType(actions.closeCustomerForm):
      return {
        ...state,
        shown: false,
      }

    case getType(actions.submitStatus.request):
      return {
        ...state,
        submitting: true,
      }

    case getType(actions.submitStatus.success):
      return {
        ...state,
        shown: false,
        submitting: false,
      }

    case getType(actions.submitStatus.failure):
      return {
        ...state,
        submitting: false,
      }

    default:
      return state
  }
}
