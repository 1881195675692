import React, { Component } from "react"
import {
  DialogContent,
  DialogTitle,
  InputBase,
  Typography,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  Button,
  DialogActions,
} from "@material-ui/core"
import { connect } from "react-redux"
import { Coords } from "google-map-react"
import SlideDialog from "./SlideDialog"
import DialogCloseButton from "./DialogCloseButton"
import StoryImages from "./StoryImages"
import StoryAudios from "./StoryAudios"
import StoryVideos from "./StoryVideos"
import i18n from "../../locales"
import { StoryModel } from "../../models"
import appContext from "../../AppContext"
import { RootState } from "../../state/store"
import * as storyActions from "../../state/stories/actions"
import * as modalPreviewActions from "../../state/modals/story-preview/actions"
import * as relatedStoriesActions from "../../state/stories-related/actions"
import { categoryName } from "../../utils/category"

const styles = (theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "baseline",
    },
    topGutter: {
      marginTop: theme.spacing(2),
    },
    leftPadded: {
      marginLeft: theme.spacing(2),
    },
    storyCoordinates: {
      flex: 1,
      marginLeft: theme.spacing(2),
    },
    storyValue: {
      marginLeft: theme.spacing(2),
      whiteSpace: "nowrap",
    },
    actions: {
      justifyContent: "space-between",
    },
    connectedStory: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })

interface Props extends WithStyles<typeof styles> {
  shown: boolean
  storyId: string | undefined
  story: StoryModel | undefined
  isRetrievingParentStory: boolean
  isRetrievingChildStories: boolean
  parentStory?: StoryModel
  childStories: StoryModel[]
  customerId: string

  retrieveStory: (id: string) => void
  retrieveParentStory: (id?: string) => void
  retrieveChildStories: (currentUser: CurrentUser, id: string) => void
  showPreview: (id: string, coords?: Coords) => void
  closeDialog: () => void
  editStory: (story: StoryModel) => void
}

class StoryPreview extends Component<Props, {}> {
  static contextType = appContext

  componentDidUpdate(oldProps: Props) {
    const {
      story,
      storyId,
      retrieveStory,
      retrieveParentStory,
      retrieveChildStories,
    } = this.props

    if (!story && storyId && storyId !== oldProps.storyId) {
      retrieveStory(storyId)
    }

    if (story && story !== oldProps.story) {
      retrieveParentStory(story.storyParentId)
      retrieveChildStories(this.context.currentUser, story.id)
    }
  }

  showPreview(story: StoryModel) {
    if (story && story.id && story.geoPoint) {
      const coords = {
        lat: story.geoPoint.lat,
        lng: story.geoPoint.lon,
      }
      this.props.showPreview(story.id, coords)
    }
  }

  connectedStories = () => {
    const {
      classes,
      story,
      isRetrievingChildStories,
      isRetrievingParentStory,
      parentStory,
    } = this.props

    if (!story) {
      return null
    }

    const isParentStory = !story.storyParentId
    const isChildStory = !isParentStory

    if (isParentStory) {
      return (
        <>
          <Typography variant="caption" className={classes.topGutter}>
            {i18n("storyPreview.label.childStories")}
          </Typography>
          <div>
            {isRetrievingChildStories ? (
              <CircularProgress color="primary" size={30} thickness={4} />
            ) : (
              this.childrenList()
            )}
          </div>
        </>
      )
    } else if (isChildStory) {
      return (
        <>
          <Typography variant="caption" className={classes.topGutter}>
            {i18n("storyPreview.label.parentStory")}
          </Typography>
          <div>
            {isRetrievingParentStory || !parentStory ? (
              <CircularProgress color="primary" size={30} thickness={4} />
            ) : (
              <Typography
                variant="subtitle1"
                key={story.id}
                className={classes.connectedStory}
              >
                <span>{parentStory.name}</span>
                <Button
                  color="primary"
                  onClick={() => this.showPreview(parentStory)}
                >
                  {i18n("storyPreview.button.preview")}
                </Button>
              </Typography>
            )}
          </div>
        </>
      )
    } else {
      return null
    }
  }

  childrenList = () => {
    const { classes, childStories } = this.props

    if (childStories.length > 0) {
      return (
        <>
          {childStories.map((story: any, index: number) => (
            <Typography
              variant="subtitle1"
              key={story.id}
              className={classes.connectedStory}
            >
              <span>
                {index + 1}. {story.name}
              </span>
              <Button color="primary" onClick={() => this.showPreview(story)}>
                {i18n("storyPreview.button.preview")}
              </Button>
            </Typography>
          ))}
        </>
      )
    } else {
      return (
        <Typography variant="subtitle1">
          {i18n("storyPreview.label.noChildren")}
        </Typography>
      )
    }
  }

  content() {
    const { story, closeDialog, customerId, editStory, classes } = this.props
    const isOwner = story && story.owner === customerId

    if (!story) {
      return <div />
    }

    return (
      <div>
        <DialogTitle>
          {story.name || ""}
          <DialogCloseButton onClick={closeDialog} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.flex}>
            <Typography variant="caption" className={classes.topGutter}>
              {i18n("storyPreview.label.coordinates")}:
            </Typography>
            <InputBase
              className={classes.storyCoordinates}
              value={
                Number(story.geoLatitude).toFixed(9) +
                " " +
                Number(story.geoLongitude).toFixed(9)
              }
              readOnly
            />

            <Typography variant="caption" className={classes.leftPadded}>
              {i18n("storyPreview.label.arSceneName")}:
            </Typography>
            <Typography variant="subtitle1" className={classes.storyValue}>
              {story.arSceneName != null
                ? story.arSceneName
                : i18n("storyForm.label.noAR")}
            </Typography>

            <Typography variant="caption" className={classes.leftPadded}>
              {i18n("storyPreview.label.geoRadius")}:
            </Typography>
            <Typography variant="subtitle1" className={classes.storyValue}>
              {story.geoRadius} m
            </Typography>
          </div>

          {isOwner && (
            <DialogActions className={classes.actions}>
              <div>
                <Button onClick={() => editStory(story)} color="primary">
                  {i18n("storyPreview.button.edit")}
                </Button>
              </div>
            </DialogActions>
          )}

          <Typography variant="caption" className={classes.topGutter}>
            {i18n("storyPreview.label.category")}
          </Typography>
          <InputBase value={categoryName(story.category)} fullWidth readOnly />

          <StoryImages source={story.imagesExpo} />

          <StoryAudios source={story.audiosExpo} />

          <StoryVideos source={story.videosExpo} />

          <Typography variant="caption" className={classes.topGutter}>
            {i18n("storyPreview.label.textExpo")}
          </Typography>
          <InputBase
            value={story.textExpo}
            fullWidth
            multiline
            rows="6"
            readOnly
          />

          <StoryImages source={story.imagesMain} />

          <StoryAudios source={story.audiosMain} />

          <StoryVideos source={story.videosMain} />

          <Typography variant="caption" className={classes.topGutter}>
            {i18n("storyPreview.label.textMain")}
          </Typography>
          <InputBase
            value={story.textMain}
            fullWidth
            multiline
            rows="12"
            readOnly
          />

          {this.connectedStories()}
        </DialogContent>
      </div>
    )
  }

  render() {
    const { shown, closeDialog } = this.props

    return (
      <SlideDialog open={shown} onClose={closeDialog} maxWidth="sm" fullWidth>
        {this.content()}
      </SlideDialog>
    )
  }
}

const componentWithStyles = withStyles(styles)(StoryPreview)

const mapStateToProps = (state: RootState) => ({
  shown: state.modals.storyPreview.shown,
  storyId: state.modals.storyPreview.id,
  story: state.stories.preview,
  isRetrievingParentStory: state.relatedStories.isRetrievingParentStory,
  isRetrievingChildStories: state.relatedStories.isRetrievingChildStories,
  parentStory: state.relatedStories.parentStory,
  childStories: state.relatedStories.childStories,
})

const mapDispatchToProps = (dispatch: any) => ({
  retrieveStory: (id: string) => {
    dispatch(storyActions.retrievePreviewStory(id))
  },
  closeDialog: () => {
    dispatch(modalPreviewActions.hideStoryPreview())
  },
  editStory: (story: StoryModel) => {
    dispatch(modalPreviewActions.hideStoryPreview())
    dispatch(storyActions.editStory(story))
  },
  retrieveParentStory: (id: string | undefined) => {
    dispatch(relatedStoriesActions.retrieveParentStory(id))
  },
  retrieveChildStories: (currentUser: CurrentUser, id: string) => {
    dispatch(relatedStoriesActions.retrieveChildStories(currentUser, id))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(componentWithStyles)
