import React from "react"
import { RequireNewPassword } from "aws-amplify-react"
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Input,
  Button,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
} from "aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React"
import i18n from "../../locales"

class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props: any) {
    super(props)

    this.setState = super.setState
    this.render = super.render
  }

  showComponent(theme: any) {
    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {i18n("requireNewPassword.header")}
        </SectionHeader>
        <SectionBody theme={theme}>
          <Input
            autoFocus
            placeholder={i18n("requireNewPassword.form.password.placeholder")}
            theme={theme}
            key="password"
            name="password"
            type="password"
            onChange={this.handleInputChange}
          />
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            <Button theme={theme} onClick={this.change}>
              {i18n("requireNewPassword.form.submit")}
            </Button>
          </SectionFooterPrimaryContent>
          <SectionFooterSecondaryContent theme={theme}>
            <Link theme={theme} onClick={() => this.changeState("signIn")}>
              {i18n("requireNewPassword.form.cancel")}
            </Link>
          </SectionFooterSecondaryContent>
        </SectionFooter>
      </FormSection>
    )
  }

  props: any
  state: any = {}
  refs: any
  context: any
  setState: any
  forceUpdate: any
  render: any
}

export default CustomRequireNewPassword
