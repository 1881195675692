import { combineReducers } from "redux"
import { storyListingReducer } from "./story-listing/reducer"
import { storyPreviewReducer } from "./story-preview/reducer"
import { storyFormReducer } from "./story-form/reducer"
import { customerFormReducer } from "./customer-form/reducer"
import { superUserFormReducer } from "./super-user-form/reducer"
import { categoryFormReducer } from "./category-form/reducer"

export const modalsReducer = combineReducers({
  storyListing: storyListingReducer,
  storyPreview: storyPreviewReducer,
  storyForm: storyFormReducer,
  customerForm: customerFormReducer,
  superUserForm: superUserFormReducer,
  categoryForm: categoryFormReducer,
})
