import React, { FunctionComponent, useContext, useState, useRef } from "react"
import {
  Button,
  Select,
  Menu,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import SignOut from "../Authenticator/SignOut"
import appContext from "../../AppContext"
import { welcomeText } from "../../utils/user"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18n from "../../locales"

const styles = (theme: Theme) =>
  createStyles({
    user: {
      color: "inherit",
      paddingLeft: theme.spacing(1),
      textDecoration: "none",
      textTransform: "none",
      fontSize: 17,
    },
    languageSelect: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    languageFlag: {
      height: 14,
      verticalAlign: "middle",
    },
    languageLabel: {
      marginLeft: theme.spacing(1),
      verticalAlign: "middle",
    },
  })

interface Props extends WithStyles<typeof styles> {}

const UserMenu: FunctionComponent<Props> = (props: Props) => {
  const context = useContext(appContext)
  const [menu, setMenu] = useState(false)
  const menuRef = useRef(null)

  const { classes } = props
  const { currentUser } = context

  if (!currentUser) {
    return null
  }

  const username = welcomeText(currentUser)

  return (
    <>
      <Button
        className={classes.user}
        ref={menuRef}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={() => setMenu(!menu)}
      >
        <ExpandMoreIcon /> {username}
      </Button>
      <Menu
        id="user-menu"
        anchorEl={menuRef.current}
        keepMounted
        open={menu}
        onClose={() => setMenu(false)}
      >
        <Select
          className={classes.languageSelect}
          value={context.currentLanguage}
          onChange={(event: any) => {
            const newLanguage = event.target.value
            if (newLanguage !== context.currentLanguage) {
              context.setCurrentLanguage(newLanguage)
            }
          }}
        >
          <MenuItem value={"no"}>
            <img src="/flag_no.svg" alt="" className={classes.languageFlag} />
            <span className={classes.languageLabel}>{i18n("userMenu.language.no")}</span>
          </MenuItem>
          <MenuItem value={"en"}>
            <img src="/flag_en.svg" alt="" className={classes.languageFlag} />
            <span className={classes.languageLabel}>{i18n("userMenu.language.en")}</span>
          </MenuItem>
        </Select>
        <SignOut />
      </Menu>
    </>
  )
}

export default withStyles(styles)(UserMenu)
