import React, { FunctionComponent, useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import { connect } from "react-redux"
import {
  Typography,
  Button,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import CategoryTable from "./CategoryTable"
import CategoryForm from "./CategoryForm"
import { CategoryModel } from "../../models"
import i18n from "../../locales"
import { RootState } from "../../state/store"
import * as categoryActions from "../../state/admin/categories/actions"
import * as categoryFormActions from "../../state/modals/category-form/actions"
import { getAllCategoriesSelector } from "../../state/admin/categories/selectors"

const styles = (theme: Theme) =>
  createStyles({
    newButton: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.only("xs")]: {
      newButton: {
        bottom: theme.spacing(1),
        right: theme.spacing(1),
      },
    },
  })

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  categories: CategoryModel[]

  retrieveCategories: () => void
  openCategoryForm: (category?: CategoryModel) => void
  deleteCategory: (id: string) => void
}

const Categories: FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    categories,
    retrieveCategories,
    openCategoryForm,
    deleteCategory,
  } = props

  useEffect(() => {
    retrieveCategories()
  }, [retrieveCategories])

  return (
    <div>
      <Typography variant="h5">{i18n("adminCategories.header")}</Typography>
      <CategoryTable
        categories={categories}
        openCategoryForm={openCategoryForm}
        deleteCategory={deleteCategory}
      />
      <CategoryForm />
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.newButton}
        onClick={() => {
          openCategoryForm()
        }}
      >
        {i18n("adminCategories.button.new")}
      </Button>
    </div>
  )
}

const componentWithStyles = withStyles(styles)(Categories)

const mapStateToProps = (state: RootState) => ({
  categories: getAllCategoriesSelector(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  retrieveCategories: () => {
    dispatch(categoryActions.retrieveCategories())
  },
  openCategoryForm: (category?: CategoryModel) => {
    dispatch(categoryFormActions.openCategoryForm(category))
  },
  deleteCategory: (id: string) => {
    dispatch(categoryFormActions.deleteCategory(id))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(componentWithStyles)
