"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var category_1 = require("./category");

var image_container_1 = require("./image-container");

var audio_container_1 = require("./audio-container");

var video_container_1 = require("./video-container");

var submitter_1 = require("./submitter");

var API_1 = require("../API");

var StoryModel =
/** @class */
function () {
  function StoryModel(params) {
    this.id = "";
    this.name = "";
    this.storyCategoryId = "";
    this.category = new category_1.CategoryModel();
    this.textExpo = "";
    this.textMain = "";
    this.imagesExpo = new image_container_1.ImageContainer();
    this.imagesMain = new image_container_1.ImageContainer();
    this.geoRadius = 0;
    this.geoAltitude = 0;
    this.geoLatitude = 0;
    this.geoLongitude = 0;
    this.references = [];
    this.private = false;
    this.status = API_1.Status.DRAFT;
    this.owner = "";
    this.userCreated = "";
    this.userModified = "";
    this.version = 0;
    this.audiosExpo = new audio_container_1.AudioContainer();
    this.audiosMain = new audio_container_1.AudioContainer();
    this.videosExpo = new video_container_1.VideoContainer();
    this.videosMain = new video_container_1.VideoContainer();

    if (params) {
      this.id = params.id;
      this.name = params.name;
      this.textExpo = params.textExpo;
      this.textMain = params.textMain;
      this.geoRadius = params.geoRadius;
      this.geoAltitude = params.geoAltitude;
      this.geoPoint = params.geoPoint;

      if (params.geoPoint) {
        this.geoLatitude = params.geoPoint.lat;
        this.geoLongitude = params.geoPoint.lon;
      }

      this.reference = getReference(params.reference, params.references);
      this.references = getReferences(params.reference, params.references);
      this.stars = params.stars;
      this.arSceneName = params.arSceneName;
      this.private = params.private;
      this.status = params.status;
      this.owner = params.owner;
      this.userCreated = params.userCreated;
      this.userModified = params.userModified;
      this.version = params.version;
      this.category = new category_1.CategoryModel(params.category);
      this.storyCategoryId = params.category.id;
      this.analyticsClickCount = params.analyticsClickCount;
      this.analyticsUnlockCount = params.analyticsUnlockCount;

      if (params.parent) {
        this.parent = new StoryModel(params.parent);
        this.storyParentId = params.parent.id;
      } else if (params.storyParentId) {
        this.storyParentId = params.storyParentId;
      }

      this.imagesExpo = new image_container_1.ImageContainer(params.imagesExpo);
      this.imagesMain = new image_container_1.ImageContainer(params.imagesMain);
      this.audiosExpo = new audio_container_1.AudioContainer(params.audiosExpo);
      this.audiosMain = new audio_container_1.AudioContainer(params.audiosMain);
      this.videosExpo = new video_container_1.VideoContainer(params.videosExpo);
      this.videosMain = new video_container_1.VideoContainer(params.videosMain);
      this.submitter = new submitter_1.SubmitterModel(params.submitter);
    }
  }

  return StoryModel;
}();

exports.StoryModel = StoryModel;

var getReference = function getReference(reference, references) {
  if (references && references[0]) {
    return references[0];
  } else {
    return reference || "";
  }
};

var getReferences = function getReferences(reference, references) {
  return [reference || ""].concat(references || []).filter(function (ref) {
    return ref;
  });
};