import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from "@material-ui/core"
import i18n from "../../locales"
import React from "react"

export const FormSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}: any) => {
  return (
    <FormControl fullWidth margin="normal" error={!!(touched && error)}>
      <InputLabel>{i18n(`storyForm.label.${input.name}`)}</InputLabel>
      <Select
        {...input}
        {...custom}
        inputProps={{
          name: "storyCategoryId",
        }}
        onChange={handleChange(input.onChange)}
      >
        {children}
      </Select>
      {renderFormHelper({ touched, error })}
    </FormControl>
  )
}

const handleChange = (onChange: any) => (event: any) => {
  onChange(event.target.value)
}

const renderFormHelper = ({ touched, error }: any) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}
