import React, { Component, ComponentClass } from "react"
import { compose, Dispatch } from "redux"
import { connect } from "react-redux"
import {
  InjectedFormProps,
  reduxForm,
  Field,
  initialize,
  formValueSelector,
  getFormValues,
} from "redux-form"
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  MenuItem,
  Typography,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"

import { isAdmin } from "../../utils/user"
import SlideDialog from "./SlideDialog"
import DialogCloseButton from "./DialogCloseButton"
import { FormTextField } from "./FormTextField"
import { FormSelectField } from "./FormSelectField"
import { FormImageField } from "./FormImageField"
import { FormAudioField } from "./FormAudioField"
import { FormVideoField } from "./FormVideoField"
import i18n from "../../locales"
import { StoryModel, CategoryModel } from "../../models"
import { categoryName } from "../../utils/category"
import { Status } from "../../graphql/API"
import appContext from "../../AppContext"
import { RootState } from "../../state/store"
import { getAllCategoriesSelector } from "../../state/categories/selectors"
import * as modalPreviewActions from "../../state/modals/story-preview/actions"
import * as storyFormActions from "../../state/modals/story-form/actions"
import * as relatedStoriesActions from "../../state/stories-related/actions"
import * as mapActions from "../../state/map/actions"
import * as storyActions from "../../state/stories/actions"
import * as toastsActions from "../../state/toasts/actions"
import { requiredField } from "../../validators/required"
import { max, maxLength, min, minLength } from "../../validators/lenght"

const styles = (theme: Theme) =>
  createStyles({
    childrenList: {
      marginTop: theme.spacing(2),
    },
    noChildrenList: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.75),
    },
    imageButton: {
      width: "100%",
    },
    actions: {
      justifyContent: "space-between",
    },
    submittingOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    alignRight: {
      textAlign: "right",
    },
  })

interface Props
  extends WithStyles<typeof styles>,
    InjectedFormProps<StoryModel> {
  dispatch: Dispatch
  categories: CategoryModel[]
  shown: boolean
  story: StoryModel
  formValues: StoryModel
  isNewStory: boolean
  isSubmitting: boolean
  isRetrievingParentStory: boolean
  isRetrievingChildStories: boolean
  parentStory?: StoryModel
  childStories: StoryModel[]
  retrieveParentStory: (id?: string) => void
  retrieveChildStories: (currentUser: CurrentUser, id: string) => void
  submitStory: (currentUser: CurrentUser, story: StoryModel) => void
  updateStory: (currentUser: CurrentUser, story: StoryModel) => void
  deleteStory: (currentUser: CurrentUser, story: StoryModel) => void
  previewStory: (story: StoryModel) => void
  closeDialog: () => void
  disableAddStoryMode: () => void
  enableAddChildStoryMode: (story: StoryModel) => void
  enableEditStoryPositionMode: (story: StoryModel) => void
  editStory: (story: StoryModel) => void
  showError: (error: string) => void
}

class StoryForm extends Component<Props> {
  static contextType = appContext

  validators = {
    geoLatitude: [requiredField, min(-90), max(90)],
    geoLongitude: [requiredField, min(-180), max(180)],
    geoAltitude: [requiredField, min(-11034), max(9000)],
    geoRadius: [requiredField, min(1), max(1000)],
    name: [requiredField, minLength(2), maxLength(200)],
    storyCategoryId: [requiredField],
    textExpo: [requiredField, minLength(2), maxLength(10000)],
    textMain: [requiredField, minLength(2), maxLength(10000)],
  }

  constructor(props: Props) {
    super(props)
    this.showPreview = this.showPreview.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidUpdate(oldProps: Props) {
    if (oldProps.story !== this.props.story) {
      const newStory = new StoryModel(this.props.story)
      newStory.geoRadius = newStory.geoRadius || 30

      this.props.retrieveParentStory(this.props.story.storyParentId)
      this.props.retrieveChildStories(
        this.context.currentUser,
        this.props.story.id
      )

      this.props.dispatch(initialize("storyForm", newStory))
    }
  }

  isNewStory(): boolean {
    return this.props.isNewStory
  }

  isChildStory(): boolean {
    return !!(this.props.formValues && this.props.formValues.storyParentId)
  }

  isParentStory(): boolean {
    return !this.isChildStory()
  }

  showPreview(values: StoryModel, dispatch: Dispatch) {
    this.props.previewStory(this.props.formValues)
  }

  handleSubmit(status: Status) {
    return (values: StoryModel, dispatch: Dispatch) => {
      let handler = this.props.submitStory
      if (values.id) {
        handler = this.props.updateStory
      }
      handler(this.context.currentUser, { ...values, status })
    }
  }

  handleDelete() {
    if (
      !this.isNewStory() &&
      window.confirm(i18n("storyForm.prompt.areYouSure"))
    ) {
      this.props.deleteStory(this.context.currentUser, this.props.formValues)
    }
  }

  onCloseDialog = () => {
    this.props.disableAddStoryMode()
    this.props.closeDialog()
  }

  enableAddChildMode = () => {
    this.props.enableAddChildStoryMode(this.props.story)
    this.onCloseDialog()
  }

  enableEditPositionMode = () => {
    this.props.enableEditStoryPositionMode(this.props.story)
    this.onCloseDialog()
  }

  header = () => {
    const type = this.isParentStory() ? "parent" : "child"
    const action = this.isNewStory() ? "new" : "edit"
    return i18n(`storyForm.header.${type}.${action}`)
  }

  connectedStories = () => {
    if (this.isNewStory()) {
      return null
    }

    const {
      classes,
      isRetrievingChildStories,
      isRetrievingParentStory,
      parentStory,
    } = this.props

    if (this.isParentStory()) {
      return (
        <Grid item xs={12}>
          <Grid container spacing={0} justify="space-between">
            <Grid item xs={6}>
              <Typography variant="h6">
                {i18n("storyForm.label.childStories")}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button onClick={this.enableAddChildMode} color="primary">
                {i18n("storyForm.button.addChild")}
              </Button>
            </Grid>
          </Grid>

          {isRetrievingChildStories ? (
            <Grid item xs={12} className={classes.childrenList}>
              <CircularProgress color="primary" size={30} thickness={4} />
            </Grid>
          ) : (
            this.childrenList()
          )}
        </Grid>
      )
    } else if (this.isChildStory()) {
      if (isRetrievingParentStory || !parentStory) {
        return (
          <Grid item xs={12}>
            <Grid container spacing={0} justify="space-between">
              <Grid item xs={6}>
                <Typography variant="h6">
                  {i18n("storyForm.label.parentStory")}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <CircularProgress color="primary" size={30} thickness={4} />
              </Grid>
            </Grid>
          </Grid>
        )
      } else {
        return (
          <Grid item xs={12}>
            <Grid container spacing={0} justify="space-between">
              <Grid item xs={6}>
                <Typography variant="h6">
                  {i18n("storyForm.label.parentStory")}: {parentStory.name}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Button
                  onClick={() => this.props.editStory(parentStory)}
                  color="primary"
                >
                  {i18n("storyForm.button.editParent")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    } else {
      return null
    }
  }

  childrenList = () => {
    const { childStories, classes } = this.props
    if (childStories.length > 0) {
      return (
        <Grid item xs={12} className={classes.childrenList}>
          {childStories.map((story: any, index: number) => (
            <Grid container spacing={2} key={story.id}>
              <Grid item xs={6}>
                {index + 1}. {story.name}
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Button
                  color="primary"
                  onClick={() => this.props.editStory(story)}
                >
                  {i18n("storyForm.button.editChild")}
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )
    } else {
      return (
        <Grid item xs={12} className={classes.noChildrenList}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {i18n("storyForm.label.noChildren")}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  deleteStoryButton() {
    if (this.isNewStory()) {
      return null
    } else {
      return (
        <Button
          color="primary"
          onClick={this.handleDelete}
          disabled={this.props.isSubmitting}
        >
          {i18n("storyForm.button.delete")}
        </Button>
      )
    }
  }

  render() {
    const {
      classes,
      categories,
      handleSubmit,
      isSubmitting,
      showError,
    } = this.props

    const isParent = this.isParentStory()
    const isChild = this.isChildStory()

    return (
      <SlideDialog open={this.props.shown} maxWidth="md" fullWidth>
        <DialogTitle>
          {this.header()}
          <DialogCloseButton onClick={this.onCloseDialog} />
        </DialogTitle>

        <DialogContent>
          <form>
            <Grid container spacing={3}>
              <Hidden xsUp implementation="css">
                <Field
                  name="storyParentId"
                  component={FormTextField}
                  type="text"
                />
              </Hidden>

              {this.connectedStories()}

              <Grid item xs={12}>
                <Button onClick={this.enableEditPositionMode} color="primary">
                  {i18n("storyForm.button.editPosition")}
                </Button>
              </Grid>

              <Grid item lg={3} sm={3} xs={12}>
                <Field
                  name="storyCategoryId"
                  component={FormSelectField}
                  label="Select Category"
                  validate={this.validators.storyCategoryId}
                  disabled={isChild}
                >
                  {categories.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {categoryName(option)}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item lg={3} sm={3} xs={12}>
                <Field
                  name="geoLatitude"
                  component={FormTextField}
                  label="Latitude"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  validate={this.validators.geoLatitude}
                />
              </Grid>

              <Grid item lg={3} sm={3} xs={12}>
                <Field
                  name="geoLongitude"
                  component={FormTextField}
                  label="Longitude"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  validate={this.validators.geoLongitude}
                />
              </Grid>

              <Grid item lg={3} sm={3} xs={12}>
                <Field
                  name="geoRadius"
                  component={FormTextField}
                  label="Radius (m)"
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 1000 } }}
                  validate={this.validators.geoRadius}
                />
              </Grid>

              <Grid item lg={12} sm={12} xs={12}>
                <Field
                  name="name"
                  component={FormTextField}
                  label="Story name"
                  validate={this.validators.name}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="reference"
                  component={FormTextField}
                  label="Reference URL"
                />
              </Grid>

              {isParent && (
                <Grid item xs={12}>
                  <Field
                    name="textExpo"
                    component={FormTextField}
                    label="Preview text"
                    multiline
                    rows="3"
                    validate={this.validators.textExpo}
                  />
                </Grid>
              )}

              {isParent && (
                <Grid item xs={12}>
                  <Field
                    name="imagesExpo"
                    component={(props: any) => (
                      <FormImageField
                        submitting={isSubmitting}
                        showError={showError}
                        {...props}
                      />
                    )}
                    color="default"
                    variant="outlined"
                    className={classes.imageButton}
                  />
                </Grid>
              )}

              {isParent && (
                <Grid item xs={12}>
                  <Field
                    name="audiosExpo"
                    component={(props: any) => (
                      <FormAudioField
                        submitting={isSubmitting}
                        showError={showError}
                        {...props}
                      />
                    )}
                    color="default"
                    variant="outlined"
                    className={classes.imageButton}
                  />
                </Grid>
              )}

              {isParent && (
                <Grid item xs={12}>
                  <Field
                    name="videosExpo"
                    component={(props: any) => (
                      <FormVideoField
                        submitting={isSubmitting}
                        showError={showError}
                        {...props}
                      />
                    )}
                    color="default"
                    variant="outlined"
                    className={classes.imageButton}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Field
                  name="textMain"
                  component={FormTextField}
                  label="Full text"
                  multiline
                  rows="6"
                  validate={this.validators.textMain}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="imagesMain"
                  component={(props: any) => (
                    <FormImageField
                      submitting={isSubmitting}
                      showError={showError}
                      {...props}
                    />
                  )}
                  color="default"
                  variant="outlined"
                  className={classes.imageButton}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="audiosMain"
                  component={(props: any) => (
                    <FormAudioField
                      submitting={isSubmitting}
                      showError={showError}
                      {...props}
                    />
                  )}
                  color="default"
                  variant="outlined"
                  className={classes.imageButton}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="videosMain"
                  component={(props: any) => (
                    <FormVideoField
                      submitting={isSubmitting}
                      showError={showError}
                      {...props}
                    />
                  )}
                  color="default"
                  variant="outlined"
                  className={classes.imageButton}
                />
              </Grid>

              {isAdmin(this.context.currentUser) && (
                <Grid item xs={12}>
                  <Field
                    name="arSceneName"
                    component={FormTextField}
                    label="AR-Scene Name"
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <div>{this.deleteStoryButton()}</div>

          <div>
            <Button
              onClick={handleSubmit(this.showPreview)}
              color="primary"
              disabled={isSubmitting}
            >
              {i18n("storyForm.button.preview")}
            </Button>

            <Button
              onClick={handleSubmit(this.handleSubmit(Status.DRAFT))}
              color="primary"
              disabled={isSubmitting}
            >
              {i18n("storyForm.button.draft")}
            </Button>

            <Button
              onClick={handleSubmit(this.handleSubmit(Status.PUBLISHED))}
              color="primary"
              disabled={isSubmitting}
            >
              {i18n("storyForm.button.publish")}
            </Button>
          </div>
        </DialogActions>

        {isSubmitting && (
          <div className={classes.submittingOverlay}>
            <CircularProgress color="primary" size={45} thickness={3} />
          </div>
        )}
      </SlideDialog>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  categories: getAllCategoriesSelector(state),
  shown: state.modals.storyForm.shown && !state.modals.storyPreview.shown,
  story: state.modals.storyForm.story,
  isSubmitting: state.modals.storyForm.submitting,
  submitProgress: state.modals.storyForm.submitProgress,
  isRetrievingParentStory: state.relatedStories.isRetrievingParentStory,
  isRetrievingChildStories: state.relatedStories.isRetrievingChildStories,
  parentStory: state.relatedStories.parentStory,
  childStories: state.relatedStories.childStories,
  isNewStory: !formValueSelector("storyForm")(state, "id"),
  formValues: getFormValues("storyForm")(state),
  map: state.map,
})

const mapDispatchToProps = (dispatch: any) => ({
  submitStory: (currentUser: CurrentUser, story: StoryModel) => {
    dispatch(storyActions.createStory(currentUser, story))
  },
  updateStory: (currentUser: CurrentUser, story: StoryModel) => {
    dispatch(storyActions.updateStory(currentUser, story))
  },
  deleteStory: (currentUser: CurrentUser, story: StoryModel) => {
    dispatch(storyActions.removeStory(currentUser, story))
  },
  previewStory: (story: StoryModel) => {
    dispatch(modalPreviewActions.showStoryPreview(story))
  },
  closeDialog: () => {
    dispatch(storyFormActions.closeStoryForm())
  },
  disableAddStoryMode: () => {
    dispatch(mapActions.disableAddStoryMode())
  },
  enableAddChildStoryMode: (story: StoryModel) => {
    dispatch(mapActions.enableAddChildStoryMode(story))
  },
  enableEditStoryPositionMode: (story: StoryModel) => {
    dispatch(mapActions.enableEditStoryPositionMode(story))
  },
  editStory: (story: StoryModel) => {
    dispatch(storyActions.editStory(story))
  },
  retrieveParentStory: (id: string | undefined) => {
    dispatch(relatedStoriesActions.retrieveParentStory(id))
  },
  retrieveChildStories: (currentUser: CurrentUser, id: string) => {
    dispatch(relatedStoriesActions.retrieveChildStories(currentUser, id))
  },
  showError: (error: string) => {
    dispatch(toastsActions.showErrorToast(error))
  },
})

export default compose<ComponentClass>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  reduxForm({
    form: "storyForm",
  })
)(StoryForm)
