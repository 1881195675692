export const strFileSize = (fileSize: number): string => {
  if (fileSize > 0) {
    if (fileSize >= 1048576) {
      const inMegabytes = fileSize / 1048576
      const sizeRound = inMegabytes.toFixed(2)
      return sizeRound + " MB"
    } else {
      const inKilobytes = fileSize / 1024
      const sizeRound = inKilobytes.toFixed(2)
      return sizeRound + " KB"
    }
  } else {
    return ""
  }
}
