"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var CategoryModel =
/** @class */
function () {
  function CategoryModel(params) {
    this.id = "";
    this.names = {};
    this.storyCount = undefined;
    this.image = undefined;

    if (params) {
      this.id = params.id;
      this.names = getNames(params.names);

      if (params.storyCount) {
        this.storyCount = params.storyCount;
      }
    }
  }

  return CategoryModel;
}();

exports.CategoryModel = CategoryModel;

var getNames = function getNames(param) {
  if (typeof param === "string") {
    return JSON.parse(param);
  } else {
    return param;
  }
};