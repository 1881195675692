import { submitStatus, submitStatusProgress } from "../stories/actions"
import { StoryModel } from "../../models"
import { getStoryById } from "../../services/story"
import { updateAllStoryAudioFields } from "../../services/audio"
import * as toastsActions from "../toasts/actions"

/*
 * Upload/remove audios and update our story record
 */
export const updateStoryAudios = (
  currentUser: CurrentUser,
  story: StoryModel
) => async (dispatch: any, getState: any) => {
  const progressCallback = () => dispatch(submitStatusProgress())
  await updateAllStoryAudioFields(currentUser, story, progressCallback)

  getStoryById(story.id)
    .then(({ data }: any) => {
      dispatch(submitStatus.success())
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}
