import React from "react"
import { SignOut } from "aws-amplify-react"
import { MenuItem } from "@material-ui/core"
import IconExit from "@material-ui/icons/ExitToApp"
import i18n from "../../locales"

class CustomSignOut extends SignOut {
  constructor(props: any) {
    super(props)

    this.setState = super.setState
  }

  render() {
    const authState = this.props.authState || this.state.authState
    const signedIn = authState === "signedIn"

    if (!signedIn) {
      return null
    }

    return (
      <MenuItem onClick={this.signOut} style={{ whiteSpace: "nowrap" }}>
        <IconExit width={18} style={{ marginRight: 4 }} />
        {i18n("greetings.signOut")}
      </MenuItem>
    )
  }

  props: any
  state: any = {}
  refs: any
  context: any
  setState: any
  forceUpdate: any
}

export default CustomSignOut
