import { TextField } from "@material-ui/core"
import i18n from "../../locales"
import React from "react"

export const FormTextField = ({
  input,
  label,
  type,
  meta: { touched, invalid, error },
  ...custom
}: any) => {
  return (
    <TextField
      label={i18n(`storyForm.label.${input.name}`)}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      margin="normal"
      fullWidth
      type={type}
      {...input}
      {...custom}
    />
  )
}
