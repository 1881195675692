import React, { FunctionComponent, useState } from "react"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core"
import i18n from "../../locales"
import { ImageModel, UploadModel } from "../../models"
import { useStoryImage } from "../../utils/story-image"

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      flex: "1 0 100%",
      position: "relative",
      transition: "left 0.2s",
    },
    image: {
      display: "block",
      width: "100%",
      maxWidth: "100%",
      maxHeight: 400,
      objectFit: "contain",
    },
  })

interface Props extends WithStyles<typeof styles> {
  item: ImageModel | UploadModel
  offset: number
}

const retryDelay = 1000
const maxRetryCount = 10

const getAttribution = (item: ImageModel | UploadModel): string => {
  return item.attribution || i18n("storyPreview.label.noAttribution")
}

const StoryImageItem: FunctionComponent<Props> = (props: Props) => {
  const { classes, item, offset } = props

  const [retryHash, setRetryHash] = useState("")
  const [retryCount, setRetryCount] = useState(0)

  const image = useStoryImage(item)

  const retry = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => {
        setRetryHash("#" + Date.now())
        setRetryCount(retryCount + 1)
      }, retryDelay)
    }
  }

  const style = {
    left: offset * -100 + "%",
  }

  const attribution = getAttribution(item)

  if (image) {
    return (
      <div className={classes.wrapper} style={style}>
        <img
          src={image + retryHash}
          alt=""
          className={classes.image}
          onError={retry}
        />
        <div>
          {i18n("storyPreview.label.attribution")}: {attribution}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default withStyles(styles)(StoryImageItem)
