import Storage from "@aws-amplify/storage"
import uuid from "uuid/v4"
import { mutation } from "../graphql"
import * as Types from "../graphql/API"
import * as mutations from "../graphql/mutations"
import { StoryModel, ImageModel, ImageContainer, UploadModel } from "../models"

export const updateAllStoryImageFields = async (
  currentUser: CurrentUser,
  story: StoryModel,
  progressCallback: () => void
) => {
  const fields = ["imagesExpo", "imagesMain"] as (keyof StoryModel)[]

  for (const field of fields) {
    await updateStoryImageField(currentUser, story, progressCallback, field)
  }
}

export const updateStoryImageField = async (
  currentUser: CurrentUser,
  story: StoryModel,
  progressCallback: () => void,
  field: keyof StoryModel
) => {
  const container = story[field]

  if (container instanceof ImageContainer) {
    if (container.toUpload.length > 0) {
      container.uploadProgress = Array(container.toUpload.length).fill(0)

      await Promise.all(
        container.toUpload.map((file: UploadModel, index: number) => {
          const key = uuid()
          const identityId = currentUser.identityId
          const customerId = currentUser.customerId
          const storyId = story.id
          const storyIdField = field.replace("images", "imageStory") + "Id"

          createImage({
            identityId,
            filename: file.file.name,
            owner: customerId,
            original: key,
            [storyIdField]: storyId,
            attribution: file.attribution || null,
            size: file.file.size,
            sortOrder: file.sortOrder,
          })

          return Storage.put(key, file.file, {
            customPrefix: {
              public: "uploads/",
            },
            metadata: {
              key,
              identityId,
            },
            level: "public",
            contentType: file.file.type,
          }).then(() => {
            container.uploadProgress[index] = 1
            progressCallback()
          })
        })
      )
    }

    if (container.toRemove.length > 0) {
      await Promise.all(
        container.toRemove.map((image: ImageModel) => {
          if (currentUser.identityId === image.identityId) {
            Storage.remove(image.original, {
              level: "protected",
            })

            Storage.remove(image.thumbnail, {
              level: "protected",
            })

            Storage.remove(image.large, {
              level: "protected",
            })
          }

          return deleteImage({
            id: image.id,
          })
        })
      )
    }

    if (container.items.length > 0) {
      await Promise.all(
        container.items.map((image: ImageModel) => {
          return updateImage({
            id: image.id,
            sortOrder: image.sortOrder,
            attribution: image.attribution,
          })
        })
      )
    }
  }
}

export const createImage = (input: Types.CreateImageInput) => {
  return mutation<typeof input>(mutations.createImage, input)
}

export const deleteImage = (input: Types.DeleteImageInput) => {
  return mutation<typeof input>(mutations.deleteImage, input)
}

export const updateImage = (input: Types.UpdateImageInput) => {
  return mutation<typeof input>(mutations.updateImage, input)
}
