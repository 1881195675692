"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var audio_1 = require("./audio");

var AudioContainer =
/** @class */
function () {
  function AudioContainer(params) {
    this.items = [];
    this.toUpload = [];
    this.toRemove = [];
    this.uploadProgress = [];

    if (params && params.items) {
      this.items = params.items.map(function (item) {
        return new audio_1.AudioModel(item);
      });
      this.toUpload = (params.toUpload || []).slice();
      this.toRemove = (params.toRemove || []).slice();
      this.uploadProgress = (params.uploadProgress || []).slice();
    }
  }

  AudioContainer.prototype.upload = function (files) {
    if (files && files.length > 0) {
      this.toUpload = this.toUpload.concat(Array.from(files));
    }
  };

  AudioContainer.prototype.remove = function (toRemove) {
    if (toRemove instanceof audio_1.AudioModel) {
      var index = this.items.findIndex(function (audio) {
        return audio.id === toRemove.id;
      });

      if (index !== -1) {
        this.removeItem(index);
      }
    } else {
      var index = this.toUpload.findIndex(function (file) {
        return file === toRemove;
      });

      if (index !== -1) {
        this.removeUpload(index);
      }
    }
  };

  AudioContainer.prototype.removeItem = function (index) {
    this.toRemove = this.toRemove.concat(this.items.splice(index, 1));
  };

  AudioContainer.prototype.removeUpload = function (index) {
    this.toUpload.splice(index, 1);
  };

  return AudioContainer;
}();

exports.AudioContainer = AudioContainer;