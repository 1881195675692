import React, { FunctionComponent, useState, useEffect } from "react"
import { RouteComponentProps, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import {
  Typography,
  Button,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import SuperUserTable from "./SuperUserTable"
import SuperUserForm from "./SuperUserForm"
import { CustomerModel, SuperUserModel } from "../../models"
import i18n from "../../locales"
import { getCustomerById } from "../../services/customer"
import { RootState } from "../../state/store"
import * as superUserActions from "../../state/admin/super-users/actions"
import * as superUserFormActions from "../../state/modals/super-user-form/actions"
import { getAllSuperUsers } from "../../state/admin/super-users/selectors"

const styles = (theme: Theme) =>
  createStyles({
    newButton: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.only("xs")]: {
      newButton: {
        bottom: theme.spacing(1),
        right: theme.spacing(1),
      },
    },
  })

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  users: SuperUserModel[]
  isLoading: boolean

  retrieveUsers: (customerId: string) => void
  resetUsers: () => void
  openSuperUserForm: (superUser?: SuperUserModel) => void
}

const SuperUsers: FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    users,
    isLoading,
    retrieveUsers,
    resetUsers,
    openSuperUserForm,
  } = props
  const params = props.match.params as any

  const [customer, setCustomer] = useState<CustomerModel | null | undefined>(
    undefined
  )

  useEffect(() => {
    retrieveCustomer(params.customerId, setCustomer)
    retrieveUsers(params.customerId)

    return resetUsers
  }, [params.customerId, retrieveUsers, resetUsers])

  if (customer === null) {
    const url = props.match.url.split("/")
    url.pop()
    return <Redirect to={url.join("/")} />
  } else {
    return (
      <div>
        <Typography variant="h5">{customer ? customer.name : ""}</Typography>
        <SuperUserTable loading={isLoading} users={users} />
        <SuperUserForm />
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.newButton}
          onClick={() => {
            const superUser = new SuperUserModel()
            superUser.customerId = params.customerId
            openSuperUserForm(superUser)
          }}
        >
          {i18n("adminSuperUsers.button.new")}
        </Button>
      </div>
    )
  }
}

const retrieveCustomer = (customerId: string, onSuccess: any) => {
  getCustomerById(customerId)
    .then(({ data }: any) => {
      onSuccess(data.getCustomer)
    })
    .catch((error: any) => {
      onSuccess(null)
    })
}

const componentWithStyles = withStyles(styles)(SuperUsers)

const mapStateToProps = (state: RootState) => ({
  users: getAllSuperUsers(state),
  isLoading: state.admin.superUsers.isLoading,
})

const mapDispatchToProps = (dispatch: any) => ({
  retrieveUsers: (customerId: string) => {
    dispatch(superUserActions.retrieveSuperUsers(customerId))
  },
  resetUsers: () => {
    dispatch(superUserActions.resetSuperUsers())
  },
  openSuperUserForm: (superUser?: SuperUserModel) => {
    dispatch(superUserFormActions.openSuperUserForm(superUser))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(componentWithStyles)
