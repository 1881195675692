import { useState, useEffect } from "react"
import Storage from "@aws-amplify/storage"
import { ImageModel, UploadModel } from "../models"

type StoryImageType = undefined | string

export const useStoryImage = (
  item: ImageModel | UploadModel
): StoryImageType => {
  const [image, setImage] = useState<StoryImageType>(undefined)
  useEffect(() => {
    let mounted = true
    if (item instanceof ImageModel) {
      if (!image) {
        Storage.get(item.medium, {
          level: "protected",
          identityId: item.identityId,
        }).then((url: Object | string) => {
          if (typeof url === "string") {
            if (mounted) {
              setImage(url)
            }
          }
        })
      }
    } else if (item.file && item.file.name) {
      if (!image || image.indexOf("blob") === -1) {
        if (mounted) {
          setImage(URL.createObjectURL(item.file))
        }
      }
    }
    return () => {
      mounted = false
    }
  }, [item, image])
  return image
}
