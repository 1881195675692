import { createStandardAction } from "typesafe-actions"
import * as toastsActions from "../toasts/actions"
import { StoryModel } from "../../models"
import {
  getStoryById,
  searchChildStoriesByParentId,
} from "../../services/story"

const SET_IS_RETRIEVING_PARENT_STORY =
  "[RELATED STORIES] SET IS RETRIEVING PARENT STORY"
const SET_PARENT_STORY = "[RELATED STORIES] SET PARENT STORY"

const SET_IS_RETRIEVING_CHILD_STORIES =
  "[RELATED STORIES] SET IS RETRIEVING CHILD STORIES"
const SET_CHILD_STORIES = "[RELATED STORIES] SET CHILD STORIES"

/*
 * Parent story
 */
export const setIsRetrievingParentStory = createStandardAction(
  SET_IS_RETRIEVING_PARENT_STORY
)<boolean>()

export const setParentStory = createStandardAction(SET_PARENT_STORY)<
  StoryModel | undefined
>()

export const retrieveParentStory = (id: string | undefined) => (
  dispatch: any,
  getState: any
) => {
  dispatch(setParentStory(undefined))
  if (id) {
    dispatch(setIsRetrievingParentStory(true))
    getStoryById(id)
      .then(({ data }: any) => {
        const item = data.getStory
        const story = new StoryModel(item)

        dispatch(setParentStory(story))
        dispatch(setIsRetrievingParentStory(false))
      })
      .catch((error: any) => {
        dispatch(setIsRetrievingParentStory(false))
        dispatch(toastsActions.showErrorToast(error))
      })
  }
}

/*
 * Child stories
 */
export const setIsRetrievingChildStories = createStandardAction(
  SET_IS_RETRIEVING_CHILD_STORIES
)<boolean>()

export const setChildStories = createStandardAction(SET_CHILD_STORIES)<
  StoryModel[]
>()

export const retrieveChildStories = (currentUser: CurrentUser, id: string) => (
  dispatch: any,
  getState: any
) => {
  dispatch(setChildStories([]))
  if (id) {
    dispatch(setIsRetrievingChildStories(true))
    searchChildStoriesByParentId(currentUser, id)
      .then(({ data }: any) => {
        const items = data.searchChildStories.items || []
        const stories = items.map(
          (item: any): StoryModel => new StoryModel(item)
        )

        dispatch(setChildStories(stories))
        dispatch(setIsRetrievingChildStories(false))
      })
      .catch((error: any) => {
        dispatch(setIsRetrievingChildStories(false))
        dispatch(toastsActions.showErrorToast(error))
      })
  }
}
