import { useState, useEffect } from "react"
import Storage from "@aws-amplify/storage"
import { CategoryModel, CategoryNames } from "../models"
import i18n, { getLanguage } from "../locales"

const firstDefaultLanguage = "no"
const secondDefaultLanguage = "en"

export const categoryName = (category: CategoryModel): string => {
  const currentLanguage = getLanguage()
  const names = category.names as CategoryNames

  return (
    names[currentLanguage] ||
    names[firstDefaultLanguage] ||
    names[secondDefaultLanguage] ||
    i18n("categoryName.default")
  )
}

const defaultIcon = "/default-marker.png"

export const categoryIcon = (id: string): Promise<string> => {
  const key = `category-${id}@2x`

  return new Promise((resolve: any) => {
    Storage.get(key, {
      level: "public",
    })
      .then((url: string | Object) => {
        if (typeof url === "string") {
          resolve(url)
        } else {
          resolve(defaultIcon)
        }
      })
      .catch((error: any) => {
        resolve(defaultIcon)
      })
  })
}

export const useCategoryIcon = (categoryId: string) => {
  const [img, setImg] = useState("")
  useEffect(() => {
    let mounted = true
    categoryIcon(categoryId).then((url: string) => {
      if (mounted) {
        setImg(url)
      }
    })
    return () => {
      mounted = false
    }
  }, [categoryId])
  return img
}
