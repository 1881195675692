export type OrderDir = "asc" | "desc"

const sort = <T>(modelA: T, modelB: T, orderBy: keyof T) => {
  const a = modelA[orderBy] || 0
  const b = modelB[orderBy] || 0

  if (b < a) {
    return -1
  } else if (b > a) {
    return 1
  } else {
    return 0
  }
}

export const getSortFunction = <T>(orderBy: keyof T, orderDir: OrderDir) =>
  orderDir === "desc"
    ? (a: T, b: T) => sort(a, b, orderBy)
    : (a: T, b: T) => -sort(a, b, orderBy)
