import React, { FunctionComponent } from "react"
import {
  Dialog,
  DialogContent,
  Checkbox,
  Fab,
  FormControlLabel,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"

import i18n from "../../locales"
import { CategoryModel } from "../../models"
import { categoryName, useCategoryIcon } from "../../utils/category"

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "absolute",
      bottom: 30,
      left: "50%",
    },
    filterButton: {
      position: "fixed",
      right: "calc(50% - 70px)",
      bottom: theme.spacing(4),
      width: 140,
      padding: "0 2rem",
      background: "white",
      color: "#333",
      "&:hover": {
        color: "white",
      },
    },
    list: {
      listStyleType: "none",
    },
    categoryIcon: {
      width: 20,
      height: 20,
      marginLeft: -2,
      marginRight: 6,
    },
    [theme.breakpoints.down("sm")]: {
      filterButton: {
        display: "none",
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  isFilterVisible: boolean
  categories: CategoryModel[]
  categoriesFilter: string[]
  toggleCategoriesFilter: (categoryId: string) => void
  toggleAllCategoriesFilter: () => void
  toggleFilterModal: () => void
}

interface State {}

class CategoryFilter extends React.Component<Props, State> {
  render() {
    const { classes, isFilterVisible } = this.props
    return (
      <div>
        <Fab
          className={classes.filterButton}
          color="primary"
          size="large"
          variant="extended"
          onClick={this.toggleModalVisibility}
        >
          {i18n("map.button.categoryFilter")}
        </Fab>
        <Dialog
          open={isFilterVisible}
          onClose={this.toggleModalVisibility}
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>{this.getCategoriesList()}</DialogContent>
        </Dialog>
      </div>
    )
  }

  handleCheckBoxChange = (categoryId: string) => () => {
    this.props.toggleCategoriesFilter(categoryId)
  }

  getCategoriesList = () => {
    const { categories, categoriesFilter, classes } = this.props
    const isAllChecked = categories.length === categoriesFilter.length

    return (
      <ul className={classes.list}>
        {categories.map(category => {
          const isChecked = !!categoriesFilter.find(
            filterCategoryId => filterCategoryId === category.id
          )

          return (
            <li key={category.id}>
              <FormControlLabel
                control={
                  <>
                    <Checkbox
                      checked={isChecked}
                      onChange={this.handleCheckBoxChange(category.id)}
                      value={category.id}
                    />
                    <CategoryIcon category={category} />
                  </>
                }
                label={categoryName(category)}
              />
            </li>
          )
        })}
        <li>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllChecked}
                onChange={this.props.toggleAllCategoriesFilter}
              />
            }
            label={i18n("categoryName.all")}
          />
        </li>
      </ul>
    )
  }

  toggleModalVisibility = () => {
    this.props.toggleFilterModal()
  }
}

interface IconProps extends WithStyles<typeof styles> {
  category: CategoryModel
}

const CategoryIconRaw: FunctionComponent<IconProps> = (props: IconProps) => {
  const { classes, category } = props
  const img = useCategoryIcon(category.id)

  if (img) {
    return <img src={img} alt={category.id} className={classes.categoryIcon} />
  } else {
    return null
  }
}

const CategoryIcon = withStyles(styles)(CategoryIconRaw)

export default withStyles(styles)(CategoryFilter)
