import { createAction } from "typesafe-actions"

import { CategoryModel } from "../../models"
import { listCategories } from "../../services/category"
import * as toastsActions from "../toasts/actions"

const ON_LIST_CATEGORIES_SUCCESS = "[CATEGORIES] ON_LIST_CATEGORIES_SUCCESS"
const TOGGLE_CATEGORIES_FILTER = "[CATEGORIES] TOGGLE_CATEGORIES_FILTER"
const TOGGLE_ALL_CATEGORIES_FILTER = "[CATEGORIES] TOGGLE_ALL_CATEGORIES_FILTER"

/*
 * Retrieve categories from DB
 */
export const retrieveCategories = () => (dispatch: any, getState: any) => {
  listCategories()
    .then(({ data }: any) => {
      dispatch(onListCategoriesSuccess(data))
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}

/*
 * Process `listCategories` query response
 */
export const onListCategoriesSuccess = createAction(
  ON_LIST_CATEGORIES_SUCCESS,
  resolve => {
    return (data: any) => {
      const categories: CategoryModel[] = data.listCategories.items.map(
        (item: CategoryModel) => new CategoryModel(item)
      )
      return resolve(categories)
    }
  }
)

/*
 * Toggle filter option in Filter modal
 */
export const toggleCategoriesFilter = createAction(
  TOGGLE_CATEGORIES_FILTER,
  resolve => {
    return (categoryId: string) => {
      return resolve(categoryId)
    }
  }
)

export const toggleAllCategoriesFilter = createAction(
  TOGGLE_ALL_CATEGORIES_FILTER
)
