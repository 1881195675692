import React, { FunctionComponent, forwardRef, useEffect, useMemo } from "react"
import { compose, Dispatch } from "redux"
import { connect } from "react-redux"
import { InjectedFormProps, reduxForm, Field, initialize } from "redux-form"
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Slide,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { FormTextField } from "./FormTextField"
import { CustomerModel } from "../../models"
import i18n from "../../locales"
import { RootState } from "../../state/store"
import * as customerFormActions from "../../state/modals/customer-form/actions"
import { requiredField } from "../../validators/required"
import { maxLength, minLength } from "../../validators/lenght"

const form = "customerForm"

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
    },
    form: {
      padding: `0 ${theme.spacing(3)}px`,
    },
    actions: {
      textAlign: "right",
      padding: `${theme.spacing(3)}px 0`,
    },
    button: {
      marginLeft: theme.spacing(2),
    },
  })

interface Props
  extends WithStyles<typeof styles>,
    InjectedFormProps<CustomerModel> {
  dispatch: Dispatch

  shown: boolean
  submitting: boolean
  customer: CustomerModel

  submitCustomer: (customer: CustomerModel) => void
  closeDialog: () => void
}

const CustomerForm: FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    dispatch,
    handleSubmit,

    shown,
    submitting,
    customer,

    submitCustomer,
    closeDialog,
  } = props

  useEffect(() => {
    dispatch(initialize(form, customer))
  }, [dispatch, customer])

  const validators = useMemo(
    () => ({
      name: [requiredField, minLength(6), maxLength(200)],
    }),
    []
  )

  const onSubmit = (values: CustomerModel, dispatch: Dispatch) => {
    submitCustomer(values)
  }

  return (
    <Dialog
      open={shown}
      classes={{ paper: classes.paper }}
      onClose={closeDialog}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{i18n("adminCustomerForm.header")}</DialogTitle>
      <form className={classes.form}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Field
              name="name"
              props={{ form: "adminCustomerForm" }}
              component={FormTextField}
              validate={validators.name}
            />
          </Grid>
          <Grid item xs={12} className={classes.actions}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={closeDialog}
            >
              {i18n("adminCustomerForm.button.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit(onSubmit)}
              disabled={submitting}
            >
              {i18n("adminCustomerForm.button.submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  )
}

const Transition = forwardRef((props: TransitionProps, ref: any) => (
  <Slide direction="up" {...props} ref={ref} />
))

const mapStateToProps = (state: RootState) => ({
  shown: state.modals.customerForm.shown,
  submitting: state.modals.customerForm.submitting,
  customer: state.modals.customerForm.customer,
})

const mapDispatchToProps = (dispatch: any) => ({
  submitCustomer: (customer: CustomerModel) => {
    dispatch(customerFormActions.createCustomer(customer))
  },
  closeDialog: () => {
    dispatch(customerFormActions.closeCustomerForm())
  },
})

export default compose<FunctionComponent>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  reduxForm({ form })
)(CustomerForm)
