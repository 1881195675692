import React, { Component } from "react"
import { Theme, WithStyles, createStyles } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import SpeedDial from "@material-ui/lab/SpeedDial"
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon"
import SpeedDialAction from "@material-ui/lab/SpeedDialAction"
import FilterListIcon from "@material-ui/icons/FilterList"
import IconRoom from "@material-ui/icons/Room"
import AddIcon from "@material-ui/icons/Add"
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted"
import i18n from "../../locales"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      zIndex: 1,
    },
    exampleWrapper: {
      position: "relative",
      height: 380,
    },
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      root: {
        display: "block",
        position: "fixed",
        bottom: "-50px",
        left: "25px",
        width: "56px",
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  isPlacingMarker: boolean
  openMyStories: () => void
  openFilter: () => void
  panToMyLocation: () => void
  addNewStory: () => void
}

type State = {
  open: boolean
}

class SpeedDials extends Component<Props, State> {
  state = {
    open: false,
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  render() {
    const { classes, isPlacingMarker } = this.props
    const { open } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.exampleWrapper}>
          <SpeedDial
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon />}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            open={open}
            direction="up"
          >
            <SpeedDialAction
              key="add-story"
              icon={<AddIcon />}
              onClick={() => {
                this.props.addNewStory()
              }}
              tooltipTitle={
                <div className={classes.tooltip}>
                  {isPlacingMarker
                    ? i18n("map.tooltip.addStoryButton")
                    : i18n("map.button.addStory")}
                </div>
              }
              tooltipPlacement="right"
              tooltipOpen={true}
            />
            <SpeedDialAction
              key="my-stories"
              icon={<FormatListBulletedIcon />}
              onClick={() => {
                this.props.openMyStories()
              }}
              tooltipTitle={
                <div className={classes.tooltip}>
                  {i18n("map.button.myStories")}
                </div>
              }
              tooltipPlacement="right"
              tooltipOpen={true}
            />
            <SpeedDialAction
              key="filter"
              icon={<FilterListIcon />}
              onClick={() => {
                this.props.openFilter()
              }}
              tooltipTitle={
                <div className={classes.tooltip}>
                  {i18n("map.button.categoryFilter")}
                </div>
              }
              tooltipPlacement="right"
              tooltipOpen={true}
            />
            <SpeedDialAction
              key="mylocation"
              icon={<IconRoom />}
              onClick={() => {
                this.props.panToMyLocation()
              }}
              tooltipTitle={
                <div className={classes.tooltip}>
                  {i18n("map.button.myLocation")}
                </div>
              }
              tooltipPlacement="right"
              tooltipOpen={true}
            />
          </SpeedDial>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(SpeedDials)
