import { createSelector } from "reselect"
import { CategoryModel } from "../../models"
import { RootState } from "../store"

export const getAllCategoriesSelector = createSelector(
  [(state: RootState) => state.categories],
  (categories): CategoryModel[] => {
    return categories.allIds.map(id => categories.entities[id])
  }
)

export const getFilteredCategoriesSelector = createSelector(
  [(state: RootState) => state.categories],
  (categories): CategoryModel[] => {
    return categories.allFilteredIds.map(id => categories.entities[id])
  }
)
