import { submitStatus, submitStatusProgress } from "../stories/actions"
import { StoryModel } from "../../models"
import { getStoryById } from "../../services/story"
import { updateAllStoryVideoFields } from "../../services/video"
import * as toastsActions from "../toasts/actions"

/*
 * Upload/remove videos and update our story record
 */
export const updateStoryVideos = (
  currentUser: CurrentUser,
  story: StoryModel
) => async (dispatch: any, getState: any) => {
  const progressCallback = () => dispatch(submitStatusProgress())
  await updateAllStoryVideoFields(currentUser, story, progressCallback)

  getStoryById(story.id)
    .then(({ data }: any) => {
      dispatch(submitStatus.success())
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}
