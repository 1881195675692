import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { CategoryModel } from "../../../models"

type State = {
  shown: boolean
  category: CategoryModel
  submitting: boolean
  isNewCategory: boolean
}

const initialState = {
  shown: false,
  category: {} as CategoryModel,
  submitting: false,
  isNewCategory: false,
}

export const categoryFormReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.openCategoryForm):
      return {
        ...state,
        shown: true,
        category: new CategoryModel(action.payload),
        isNewCategory: !action.payload,
      }

    case getType(actions.closeCategoryForm):
      return {
        ...state,
        shown: false,
      }

    case getType(actions.submitStatus.request):
      return {
        ...state,
        submitting: true,
      }

    case getType(actions.submitStatus.success):
      return {
        ...state,
        shown: false,
        submitting: false,
      }

    case getType(actions.submitStatus.failure):
      return {
        ...state,
        submitting: false,
      }

    default:
      return state
  }
}
