import React from "react"
import { VerifyContact } from "aws-amplify-react"
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Button,
  SectionFooterPrimaryContent,
} from "aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React"
import i18n from "../../locales"

class CustomVerifyContact extends VerifyContact {
  constructor(props: any) {
    super(props)

    this.setState = super.setState
    this.render = super.render
  }

  showComponent(theme: any) {
    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
            {this.state.verifyAttr ? (
                i18n("verifyContact.header2")
            ) : (
                i18n("verifyContact.header1")
            )}
        </SectionHeader>
        <SectionBody theme={theme}>
          {this.state.verifyAttr ? this.submitView() : this.verifyView()}
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            {this.state.verifyAttr ? (
              <Button theme={theme} onClick={this.submit}>
                {i18n("verifyContact.form.submit")}
              </Button>
            ) : (
              <Button theme={theme} onClick={this.verify}>
                {i18n("verifyContact.form.verify")}
              </Button>
            )}
          </SectionFooterPrimaryContent>
        </SectionFooter>
      </FormSection>
    )
  }

  props: any
  state: any = {}
  refs: any
  context: any
  setState: any
  forceUpdate: any
  render: any
}

export default CustomVerifyContact
