// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
var awsmobile = {
  "aws_project_region": "eu-central-1",
  "aws_cognito_identity_pool_id": "eu-central-1:a2820da4-90e4-40e8-8ab5-c240a1f31f9b",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_uKjp67GB1",
  "aws_user_pools_web_client_id": "3jofb5k0pirkp3ttpkhmh2gd5b",
  "oauth": {},
  "aws_appsync_graphqlEndpoint": "https://jd7yhyftovfvvoh7q37qsnqsma.appsync-api.eu-central-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-central-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cloud_logic_custom": [{
    "name": "adminBackend",
    "endpoint": "https://z70gcf6gr7.execute-api.eu-central-1.amazonaws.com/master",
    "region": "eu-central-1"
  }, {
    "name": "analytics",
    "endpoint": "https://9xd898mto2.execute-api.eu-central-1.amazonaws.com/master",
    "region": "eu-central-1"
  }],
  "aws_user_files_s3_bucket": "hiddenstorage-master",
  "aws_user_files_s3_bucket_region": "eu-central-1",
  "aws_mobile_analytics_app_id": "cab096dfd72d4a1a9f55350c4af4bb1e",
  "aws_mobile_analytics_app_region": "eu-central-1"
};
export default awsmobile;