import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { SuperUserModel } from "../../../models"

type Entities = {
  [key: string]: SuperUserModel
}

type State = {
  entities: Entities
  isLoading: boolean
}

const initialState: State = {
  entities: {},
  isLoading: true,
}

export const superUsersReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.resetSuperUsers):
      return {
        ...state,
        entities: {},
        isLoading: true,
      }

    case getType(actions.setSuperUsers):
      return {
        ...state,
        entities: action.payload.reduce(
          (acc: Entities, user: SuperUserModel) => {
            acc[user.username] = user
            return acc
          },
          { ...state.entities }
        ),
        isLoading: false,
      }

    case getType(actions.setSuperUserDetails):
      if ("payload" in action) {
        const newEntities = { ...state.entities }
        action.payload.userCreated.forEach((user: any) => {
          if (newEntities[user.username]) {
            newEntities[user.username].storyCount = user.storyCount
          }
        })
        action.payload.userModified.forEach((user: any) => {
          if (newEntities[user.username]) {
            newEntities[user.username].lastAction = user.lastAction
          }
        })
        return {
          ...state,
          entities: newEntities,
        }
      } else {
        return state
      }

    case getType(actions.setLoading):
      return {
        ...state,
        isLoading: action.payload,
      }

    default:
      return state
  }
}
