import i18n from "../locales"

export const min = (condition: number) => {
  return (value: number) => {
    if (value < condition) {
      const message = i18n("validator.min")
      return message.replace("{{value}}", String(condition))
    }
  }
}

export const max = (condition: number) => {
  return (value: number) => {
    if (value > condition) {
      const message = i18n("validator.max")
      return message.replace("{{value}}", String(condition))
    }
  }
}

export const minLength = (condition: number) => {
  return (value: string) => {
    if (value && value.length < condition) {
      const message = i18n("validator.minLength")
      return message.replace("{{value}}", String(condition))
    }
  }
}

export const maxLength = (condition: number) => {
  return (value: string) => {
    if (value && value.length > condition) {
      const message = i18n("validator.maxLength")
      return message.replace("{{value}}", String(condition))
    }
  }
}
