import React, { FunctionComponent } from "react"
import { Coords } from "google-map-react"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core"

const styles = (theme: Theme) => {
  const size = 32
  const borderSize = 8
  const halfSize = Math.floor(size / 2 + borderSize)

  return createStyles({
    cluster: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      top: -halfSize,
      left: -halfSize,
      width: size,
      height: size,
      border: `${borderSize}px double #c7ceff`,
      borderRadius: 32,
      fontSize: 24,
      backgroundColor: "white",
      cursor: "pointer",
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  hash: string
  count: number
  lat: number
  lng: number
  zoomIn: (coords: Coords) => void
}

const StoryCluster: FunctionComponent<Props> = ({
  hash,
  count,
  lat,
  lng,
  zoomIn,
  classes,
}) => {
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    zoomIn({ lat, lng })
  }

  return (
    <div className={classes.cluster} onClick={onClick}>
      <span>{count}</span>
    </div>
  )
}

export default withStyles(styles)(StoryCluster)
