import { I18n } from "@aws-amplify/core"

export type I18nOptions = {
  throwOnMissingKeys: boolean
  defaultLanguage: string
}

let options: I18nOptions = {
  throwOnMissingKeys: false,
  defaultLanguage: "en",
}

let currentLanguage: string = options.defaultLanguage
export const availableLanguages: string[] = []

export const configure = (
  languages: string[],
  customOptions?: Partial<I18nOptions>
) => {
  if (customOptions) {
    options = {
      ...options,
      ...customOptions,
    }
  }

  languages.forEach((language: string) => {
    I18n.putVocabulariesForLanguage(language, require(`./${language}.json`))
    availableLanguages.push(language)
  })

  setLanguage(options.defaultLanguage)
}

const defaultLanguage = "no"
export const storageLanguageKey = "hidden-language"

export const getInitialLanguage = (): string => {
  return localStorage.getItem(storageLanguageKey) || defaultLanguage
}

export const setLanguage = (language: string) => {
  currentLanguage = language
  I18n.setLanguage(language)
  localStorage.setItem(storageLanguageKey, language)
}

export const getLanguage = (): string => {
  return currentLanguage
}

const i18n = (key: string): string => {
  const text = I18n.get(key)
  if (text && text === key) {
    if (options.throwOnMissingKeys) {
      throw new Error(`I18n key not found '${key}'`)
    } else {
      console.error(`I18n key not found '${key}'`)
    }
  }
  return text
}

export default i18n
