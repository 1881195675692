"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var AudioModel =
/** @class */
function () {
  function AudioModel(params) {
    this.id = "";
    this.identityId = "";
    this.filename = "";
    this.original = "";
    this.owner = "";
    this.attribution = "";
    this.size = 0;

    if (params) {
      this.id = params.id;
      this.identityId = params.identityId;
      this.filename = params.filename;
      this.original = params.original;
      this.owner = params.id;
      this.attribution = params.attribution;
      this.size = params.size;
    }
  }

  return AudioModel;
}();

exports.AudioModel = AudioModel;