import Storage from "@aws-amplify/storage"
import uuid from "uuid/v4"
import { mutation } from "../graphql"
import * as Types from "../graphql/API"
import * as mutations from "../graphql/mutations"
import { StoryModel, VideoModel, VideoContainer, UploadModel } from "../models"

export const updateAllStoryVideoFields = async (
  currentUser: CurrentUser,
  story: StoryModel,
  progressCallback: () => void
) => {
  const fields = ["videosExpo", "videosMain"] as (keyof StoryModel)[]

  for (const field of fields) {
    await updateStoryVideoField(currentUser, story, progressCallback, field)
  }
}

export const updateStoryVideoField = async (
  currentUser: CurrentUser,
  story: StoryModel,
  progressCallback: () => void,
  field: keyof StoryModel
) => {
  const container = story[field]

  if (container instanceof VideoContainer) {
    if (container.toUpload.length > 0) {
      container.uploadProgress = Array(container.toUpload.length).fill(0)

      await Promise.all(
        container.toUpload.map((file: UploadModel, index: number) => {
          const key = uuid()
          const originalKey = key

          const identityId = currentUser.identityId
          const customerId = currentUser.customerId
          const storyId = story.id
          const storyIdField = field.replace("videos", "videoStory") + "Id"

          return Storage.put(key, file.file, {
            level: "protected",
            contentType: file.file.type,
          }).then(() => {
            container.uploadProgress[index] = 1
            createVideo({
              identityId,
              filename: file.file.name,
              owner: customerId,
              original: originalKey,
              [storyIdField]: storyId,
              attribution: file.attribution || null,
              size: file.file.size,
            })
            progressCallback()
          })
        })
      )
    }

    if (container.toRemove.length > 0) {
      await Promise.all(
        container.toRemove.map((video: VideoModel) => {
          if (currentUser.identityId === video.identityId) {
            Storage.remove(video.original, {
              level: "protected",
            })
          }

          return deleteVideo({
            id: video.id,
          })
        })
      )
    }

    if (container.items.length > 0) {
      await Promise.all(
        container.items.map((video: VideoModel) => {
          return updateVideo({
            id: video.id,
            attribution: video.attribution,
          })
        })
      )
    }
  }
}

export const createVideo = (input: Types.CreateVideoInput) => {
  return mutation<typeof input>(mutations.createVideo, input)
}

export const deleteVideo = (input: Types.DeleteVideoInput) => {
  return mutation<typeof input>(mutations.deleteVideo, input)
}

export const updateVideo = (input: Types.UpdateVideoInput) => {
  return mutation<typeof input>(mutations.updateVideo, input)
}
