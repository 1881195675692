import React, { FunctionComponent, forwardRef, useContext } from "react"
import {
  Dialog,
  Slide,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core"
import { DialogProps } from "@material-ui/core/Dialog"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import appContext from "../../AppContext"

const Transition = forwardRef((props: TransitionProps, ref: any) => (
  <Slide direction="left" {...props} ref={ref} />
))

const SlideDialog: FunctionComponent<DialogProps> = (props: DialogProps) => {
  const context = useContext(appContext)

  return (
    <Dialog
      container={context.appRoot.current}
      TransitionComponent={Transition}
      {...props}
    />
  )
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      alignItems: "stretch",
      justifyContent: "flex-end",
    },
    paper: {
      margin: 0,
      maxHeight: "100%",
      borderRadius: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paper: {
        margin: 0,
        width: "100%",
        maxHeight: "100%",
        borderRadius: 0,
      },
    },
  })

export default withStyles(styles)(SlideDialog)
