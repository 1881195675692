import React, {
  FunctionComponent,
  useReducer,
  useState,
  useMemo,
  useContext,
} from "react"
import { Redirect } from "react-router-dom"
import {
  CircularProgress,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Grid,
  Typography,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import { CustomerModel } from "../../models"
import i18n from "../../locales"
import appContext from "../../AppContext"
import { loginAsCustomer } from "../../services/customer"
import { OrderDir, getSortFunction } from "../../utils/sort"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(11),
    },
    row: {
      cursor: "pointer",
    },
    login: {},
    noCustomers: {
      marginTop: theme.spacing(4),
    },
  })

interface Props extends WithStyles<typeof styles> {
  customers: CustomerModel[]
  root: string
}

type State = {
  linkToCustomer?: string
  loggingIn: boolean
  loggedIn: boolean
}

const initialState: State = {
  linkToCustomer: undefined,
  loggingIn: false,
  loggedIn: false,
}

type Action =
  | { type: "linkToCustomer"; payload: string }
  | { type: "loggingIn" }
  | { type: "loggedIn" }
  | { type: "notLoggedIn" }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "linkToCustomer":
      return {
        ...state,
        linkToCustomer: action.payload,
      }
    case "loggingIn":
      return {
        ...state,
        loggingIn: true,
      }
    case "loggedIn":
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
      }
    case "notLoggedIn":
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
      }
    default:
      return state
  }
}

type Column = {
  id: keyof CustomerModel
  label: string
  numeric: boolean
}

const orderCustomers = (
  customers: CustomerModel[],
  orderBy: keyof CustomerModel,
  orderDir: OrderDir
) => {
  const compare = getSortFunction<CustomerModel>(orderBy, orderDir)
  return customers.sort(compare)
}

const CustomerTable: FunctionComponent<Props> = (props: Props) => {
  const columns: Column[] = useMemo<Column[]>(
    () => [
      {
        id: "name",
        label: i18n("adminCustomers.table.name"),
        numeric: false,
      },
      {
        id: "storyCount",
        label: i18n("adminCustomers.table.storyCount"),
        numeric: true,
      },
      {
        id: "lastAction",
        label: i18n("adminCustomers.table.lastAction"),
        numeric: true,
      },
    ],
    []
  )

  const context = useContext(appContext)

  const { classes, customers, root } = props
  const { currentUser } = context

  const [orderBy, setOrderBy] = useState<keyof CustomerModel>(columns[0].id)
  const [orderDir, setOrderDir] = useState<OrderDir>("asc")

  const setOrder = (id: keyof CustomerModel) => {
    const column = columns.find((column: Column) => column.id === id)

    if (orderBy !== id) {
      setOrderBy(id)
      setOrderDir(column!.numeric ? "desc" : "asc")
    } else {
      setOrderDir(orderDir === "asc" ? "desc" : "asc")
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const loginAs = (customer: CustomerModel) => {
    dispatch({ type: "loggingIn" })
    loginAsCustomer(currentUser!.username, customer.id)
      .then(() => {
        currentUser!.customerId = customer.id
        currentUser!.customerName = customer.name
        dispatch({ type: "loggedIn" })
      })
      .catch((error: any) => {
        dispatch({ type: "notLoggedIn" })
        console.warn("ERROR logging in as customer", error)
      })
  }

  if (state.linkToCustomer) {
    return <Redirect push to={`${root}/${state.linkToCustomer}`} />
  } else if (state.loggedIn) {
    return <Redirect push to="/" />
  } else if (state.loggingIn) {
    return (
      <Typography variant="h6" className={classes.noCustomers}>
        <Grid container spacing={1} justify="center">
          <Grid item>{i18n("adminCustomers.table.loggingIn")}</Grid>
          <Grid item>
            <CircularProgress size={20} thickness={6} />
          </Grid>
        </Grid>
      </Typography>
    )
  } else if (customers.length > 0) {
    return (
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            {columns.map((column: Column) => (
              <TableCell
                key={column.id}
                align={column.numeric ? "right" : "left"}
                sortDirection={orderBy === column.id ? orderDir : false}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderDir}
                  onClick={() => setOrder(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell align="center">
              {i18n("adminCustomers.table.login")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderCustomers(customers, orderBy, orderDir).map(
            (customer: CustomerModel) => (
              <TableRow
                key={customer.id}
                className={classes.row}
                hover
                onClick={() => {
                  dispatch({
                    type: "linkToCustomer",
                    payload: customer.id,
                  })
                }}
              >
                {columns.map((column: Column) => (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? "right" : "left"}
                  >
                    {customer[column.id]}
                  </TableCell>
                ))}
                <TableCell align="center">
                  {currentUser!.customerId === customer.id ? (
                    i18n("adminCustomers.button.logged")
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.login}
                      onClick={event => {
                        event.stopPropagation()
                        loginAs(customer)
                      }}
                    >
                      {i18n("adminCustomers.button.login")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    )
  } else {
    return (
      <Typography variant="h6" align="center" className={classes.noCustomers}>
        <CircularProgress />
      </Typography>
    )
  }
}

export default withStyles(styles)(CustomerTable)
