import { createAction, createStandardAction } from "typesafe-actions"
import { Coords } from "google-map-react"
import { StoryModel } from "../../models"

const PAN_TO = "[MAP] PAN_TO"
const ZOOM_IN = "[MAP] ZOOM_IN"

const ENABLE_ADD_STORY_MODE = "[STORIES] ENABLE_ADD_STORY_MODE"
const DISABLE_ADD_STORY_MODE = "[STORIES] DISABLE_ADD_STORY_MODE"

const ENABLE_ADD_CHILD_STORY_MODE = "[STORIES] ENABLE_ADD_CHILD_STORY_MODE"
const DISABLE_ADD_CHILD_STORY_MODE = "[STORIES] DISABLE_EDIT_STORY_MODE"

const ENABLE_EDIT_STORY_POSITION_MODE =
  "[STORIES] ENABLE_EDIT_STORY_POSITION_MODE"
const DISABLE_EDIT_STORY_POSITION_MODE =
  "[STORIES] DISABLE_EDIT_STORY_POSITION_MODE"

export const panTo = createAction(
  PAN_TO,
  resolve => (coords: Coords, offset: number = 0, zoom: number = 15) =>
    resolve({ coords, offset, zoom })
)

export const panToMyLocation = () => (dispatch: any, getState: any) => {
  navigator.geolocation.getCurrentPosition(
    (position: any) => {
      const coords: Coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }

      dispatch(panTo(coords))
    },
    (error: any) => {
      console.log("An error occured while retrieving your geolocation", error)
    },
    {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 30000,
    }
  )
}

export const zoomIn = createAction(
  ZOOM_IN,
  resolve => (coords: Coords, zoom: number) =>
    resolve({ coords, offset: 0, zoom })
)

export const enableAddStoryMode = createAction(ENABLE_ADD_STORY_MODE)

export const disableAddStoryMode = createAction(DISABLE_ADD_STORY_MODE)

export const enableAddChildStoryMode = createStandardAction(
  ENABLE_ADD_CHILD_STORY_MODE
)<StoryModel>()

export const disableAddChildStoryMode = createAction(
  DISABLE_ADD_CHILD_STORY_MODE
)

export const enableEditStoryPositionMode = createStandardAction(
  ENABLE_EDIT_STORY_POSITION_MODE
)<StoryModel>()

export const disableEditStoryPositionMode = createAction(
  DISABLE_EDIT_STORY_POSITION_MODE
)
