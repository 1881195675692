import { TextField } from "@material-ui/core"
import i18n from "../../locales"
import React from "react"

export const FormTextField = (props: any) => {
  const { input, type, meta, ...custom } = props
  const { touched, invalid, error } = meta

  const label = props.label || i18n(`${custom.form}.label.${input.name}`)
  const placeholder =
    props.placeholder || i18n(`${custom.form}.placeholder.${input.name}`)

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      error={touched && invalid}
      helperText={touched && error}
      margin="normal"
      fullWidth
      type={type}
      {...input}
      {...custom}
    />
  )
}
