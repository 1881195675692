import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { SuperUserModel } from "../../../models"

type State = {
  shown: boolean
  superUser: SuperUserModel
  submitting: boolean
}

const initialState = {
  shown: false,
  superUser: {} as SuperUserModel,
  submitting: false,
}

export const superUserFormReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.openSuperUserForm):
      if ("payload" in action) {
        return {
          ...state,
          shown: true,
          superUser: new SuperUserModel(action.payload),
        }
      } else {
        return {
          ...state,
          shown: true,
          superUser: new SuperUserModel(),
        }
      }

    case getType(actions.closeSuperUserForm):
      return {
        ...state,
        shown: false,
      }

    case getType(actions.submitStatus.request):
      return {
        ...state,
        submitting: true,
      }

    case getType(actions.submitStatus.success):
      return {
        ...state,
        shown: false,
        submitting: false,
      }

    case getType(actions.submitStatus.failure):
      return {
        ...state,
        submitting: false,
      }

    default:
      return state
  }
}
