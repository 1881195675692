import React, { FunctionComponent, useState, useEffect } from "react"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core"
import Storage from "@aws-amplify/storage"
import i18n from "../../locales"
import { AudioContainer, AudioModel, UploadModel } from "../../models"

const styles = (theme: Theme) =>
  createStyles({
    item: {
      position: "relative",
      display: "block",
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    audio: {
      width: "100%",
    },
  })

interface Props extends WithStyles<typeof styles> {
  source: AudioContainer
}

const StoryAudios: FunctionComponent<Props> = (props: Props) => {
  const { classes, source: container } = props
  const audioCount = container.items.length + container.toUpload.length

  if (audioCount > 0) {
    return (
      <div>
        {container.items.map((item: AudioModel) => (
          <StoryAudio key={item.id} item={item} classes={classes} />
        ))}

        {container.toUpload.map((item: UploadModel, id: number) => (
          <StoryAudio key={id} item={item} classes={classes} />
        ))}
      </div>
    )
  } else {
    return null
  }
}

interface ItemProps extends WithStyles<typeof styles> {
  item: AudioModel | UploadModel
}

const getFilename = (item: AudioModel | UploadModel): string => {
  if (item instanceof AudioModel) {
    return item.filename
  } else if (item.file && item.file.name) {
    return item.file.name
  } else {
    return ""
  }
}

const getAttribution = (item: AudioModel | UploadModel): string => {
  return item.attribution || i18n("storyPreview.label.noAttribution")
}

const StoryAudio: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { classes, item } = props
  const [audioSource, setAudioSource] = useState("")

  useEffect(() => {
    let mounted = true

    if (item instanceof AudioModel) {
      Storage.get(item.original, {
        level: "protected",
        identityId: item.identityId,
      }).then((url: Object | string) => {
        if (typeof url === "string" && mounted) {
          setAudioSource(url)
        }
      })
    } else if (item.file && item.file.name) {
      if (!audioSource || audioSource.indexOf("blob") === -1) {
        setAudioSource(URL.createObjectURL(item.file))
      }
    }

    return () => {
      mounted = false
    }
  }, [item, audioSource])

  const filename = getFilename(item)
  const attribution = getAttribution(item)

  if (audioSource) {
    return (
      <div className={classes.item}>
        <div>{filename}</div>
        <audio controls src={audioSource} className={classes.audio}>
          Your browser does not support the <code>audio</code> element.
        </audio>
        <div>
          {i18n("storyPreview.label.attribution")}: {attribution}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default withStyles(styles)(StoryAudios)
