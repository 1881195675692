import { combineReducers, applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import { StateType } from "typesafe-actions"

import { mapReducer } from "./map/reducer"
import { categoriesReducer } from "./categories/reducer"
import { storiesReducer } from "./stories/reducer"
import { relatedStoriesReducer } from "./stories-related/reducer"
import { modalsReducer } from "./modals/reducer"
import { reducer as reduxFormReducer } from "redux-form"
import { toastsReducer } from "./toasts/reducer"
import { adminReducer } from "./admin/reducer"

const rootReducer = combineReducers({
  map: mapReducer,
  categories: categoriesReducer,
  stories: storiesReducer,
  relatedStories: relatedStoriesReducer,
  modals: modalsReducer,
  form: reduxFormReducer,
  toasts: toastsReducer,
  admin: adminReducer,
})

export type RootState = StateType<typeof rootReducer>

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)
