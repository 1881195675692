import React, { FunctionComponent, forwardRef, useEffect, useMemo } from "react"
import { compose, Dispatch } from "redux"
import { connect } from "react-redux"
import { InjectedFormProps, reduxForm, Field, initialize } from "redux-form"
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Slide,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { FormTextField } from "./FormTextField"
import { SuperUserModel } from "../../models"
import i18n from "../../locales"
import { RootState } from "../../state/store"
import * as superUserFormActions from "../../state/modals/super-user-form/actions"
import { requiredField } from "../../validators/required"
import { maxLength, minLength } from "../../validators/lenght"
import { emailFormat } from "../../validators/format"

const form = "superUserForm"

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
    },
    form: {
      padding: `0 ${theme.spacing(3)}px`,
    },
    actions: {
      textAlign: "right",
      padding: `${theme.spacing(3)}px 0`,
    },
    button: {
      marginLeft: theme.spacing(2),
    },
  })

interface Props
  extends WithStyles<typeof styles>,
    InjectedFormProps<SuperUserModel> {
  dispatch: Dispatch

  shown: boolean
  submitting: boolean
  superUser: SuperUserModel

  submitSuperUser: (superUser: SuperUserModel, password: string) => void
  closeDialog: () => void
}

const SuperUserForm: FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    dispatch,
    handleSubmit,

    shown,
    submitting,
    superUser,

    submitSuperUser,
    closeDialog,
  } = props

  useEffect(() => {
    dispatch(initialize(form, superUser))
  }, [dispatch, superUser])

  const validators = useMemo(
    () => ({
      username: [requiredField, minLength(4), maxLength(100)],
      email: [requiredField, emailFormat],
      password: [requiredField, minLength(8)],
    }),
    []
  )

  const onSubmit = (values: SuperUserModel, dispatch: Dispatch) => {
    submitSuperUser({ ...values }, (values as any).tempPassword)
  }

  return (
    <Dialog
      open={shown}
      classes={{ paper: classes.paper }}
      onClose={closeDialog}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{i18n("adminSuperUserForm.header")}</DialogTitle>
      <form className={classes.form}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Field
              name="email"
              type="email"
              props={{ form: "adminSuperUserForm" }}
              component={FormTextField}
              validate={validators.email}
            />
            <Field
              name="tempPassword"
              type="password"
              props={{ form: "adminSuperUserForm" }}
              InputProps={{ autoComplete: "new-password" }}
              component={FormTextField}
              validate={validators.password}
            />
          </Grid>
          <Grid item xs={12} className={classes.actions}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={closeDialog}
            >
              {i18n("adminSuperUserForm.button.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit(onSubmit)}
              disabled={submitting}
            >
              {i18n("adminSuperUserForm.button.submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  )
}

const Transition = forwardRef((props: TransitionProps, ref: any) => (
  <Slide direction="up" {...props} ref={ref} />
))

const mapStateToProps = (state: RootState) => ({
  shown: state.modals.superUserForm.shown,
  submitting: state.modals.superUserForm.submitting,
  superUser: state.modals.superUserForm.superUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  submitSuperUser: (superUser: SuperUserModel, password: string) => {
    dispatch(superUserFormActions.createSuperUser(superUser, password))
  },
  closeDialog: () => {
    dispatch(superUserFormActions.closeSuperUserForm())
  },
})

export default compose<FunctionComponent>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  reduxForm<SuperUserModel>({ form })
)(SuperUserForm)
