import { createAction } from "typesafe-actions"
import { StoryModel } from "../../../models"

const OPEN_STORY_FORM = "[MODALS] OPEN STORY FORM"
const CLOSE_STORY_LISTING = "[MODALS] CLOSE STORY FORM"

/*
 * Open a dialog window with an empty story form
 */
export const openStoryForm = createAction(
  OPEN_STORY_FORM,
  resolve => (story: StoryModel) => resolve(story)
)

/*
 * Hide a story dialog window
 */
export const closeStoryForm = createAction(CLOSE_STORY_LISTING)
