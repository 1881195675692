import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { StoryModel, StoryMarkerModel, StoryClusterModel } from "../../models"

type State = {
  markers: StoryMarkerModel[]
  clusters: StoryClusterModel[]
  preview?: StoryModel
  user: StoryModel[]
}

const initialState: State = {
  markers: [],
  clusters: [],
  preview: undefined,
  user: [],
}

const replace = <T extends { id: string }>(records: T[], newRecord: T): T[] => {
  const newRecords = [...records]

  const index = newRecords.findIndex((story: T) => story.id === newRecord.id)
  if (index >= 0) {
    newRecords[index] = newRecord
  } else {
    newRecords.push(newRecord)
  }

  return newRecords
}

export const storiesReducer = (
  state: State = initialState,
  action: ActionType<any>
): State => {
  switch (action.type) {
    case getType(actions.onCreateStorySuccess):
      return {
        ...state,
        markers: replace<StoryMarkerModel>(
          state.markers,
          new StoryMarkerModel(action.payload)
        ),
        user: replace<StoryModel>(state.user, new StoryModel(action.payload)),
      }

    case getType(actions.onUpdateStorySuccess):
      return {
        ...state,
        markers: replace<StoryMarkerModel>(
          state.markers,
          new StoryMarkerModel(action.payload)
        ),
        user: replace<StoryModel>(state.user, new StoryModel(action.payload)),
      }

    case getType(actions.setMarkers):
      return {
        ...state,
        markers: action.payload,
      }

    case getType(actions.setClusters):
      return {
        ...state,
        clusters: action.payload,
      }

    case getType(actions.setUser):
      return {
        ...state,
        user: action.payload,
      }

    case getType(actions.setPreview):
    case getType(actions.resetPreview):
      return {
        ...state,
        preview: action.payload,
      }

    default:
      return state
  }
}
