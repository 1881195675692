import React, { FunctionComponent, useState, useMemo } from "react"
import {
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import { SuperUserModel } from "../../models"
import i18n from "../../locales"
import { OrderDir, getSortFunction } from "../../utils/sort"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(11),
    },
    row: {
      cursor: "normal",
    },
    noUsers: {
      marginTop: theme.spacing(4),
    },
  })

interface Props extends WithStyles<typeof styles> {
  loading: boolean
  users: SuperUserModel[]
}

type Column = {
  id: keyof SuperUserModel
  label: string
  numeric: boolean
}

const orderUsers = (
  users: SuperUserModel[],
  orderBy: keyof SuperUserModel,
  orderDir: OrderDir
) => {
  const compare = getSortFunction<SuperUserModel>(orderBy, orderDir)
  return users.sort(compare)
}

const SuperUserTable: FunctionComponent<Props> = (props: Props) => {
  const columns: Column[] = useMemo<Column[]>(
    () => [
      {
        id: "username",
        label: i18n("adminSuperUsers.table.username"),
        numeric: false,
      },
      {
        id: "email",
        label: i18n("adminSuperUsers.table.email"),
        numeric: false,
      },
      {
        id: "storyCount",
        label: i18n("adminSuperUsers.table.storyCount"),
        numeric: true,
      },
      {
        id: "lastAction",
        label: i18n("adminSuperUsers.table.lastAction"),
        numeric: true,
      },
    ],
    []
  )

  const { classes, loading, users } = props

  const [orderBy, setOrderBy] = useState<keyof SuperUserModel>(columns[0].id)
  const [orderDir, setOrderDir] = useState<OrderDir>("asc")

  const setOrder = (id: keyof SuperUserModel) => {
    const column = columns.find((column: Column) => column.id === id)

    if (orderBy !== id) {
      setOrderBy(id)
      setOrderDir(column!.numeric ? "desc" : "asc")
    } else {
      setOrderDir(orderDir === "asc" ? "desc" : "asc")
    }
  }

  if (loading) {
    return (
      <Typography variant="h6" align="center" className={classes.noUsers}>
        <CircularProgress />
      </Typography>
    )
  } else if (users.length > 0) {
    return (
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            {columns.map((column: Column) => (
              <TableCell
                key={column.id}
                align={column.numeric ? "right" : "left"}
                sortDirection={orderBy === column.id ? orderDir : false}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderDir}
                  onClick={() => setOrder(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderUsers(users, orderBy, orderDir).map((user: SuperUserModel) => (
            <TableRow key={user.username} className={classes.row}>
              {columns.map((column: Column) => (
                <TableCell
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                >
                  {user[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  } else {
    return (
      <Typography variant="h6" align="center" className={classes.noUsers}>
        {i18n("adminSuperUsers.noUsers")}
      </Typography>
    )
  }
}

export default withStyles(styles)(SuperUserTable)
