import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { ActionType } from "typesafe-actions"
import { RootState } from "../../state/store"
import * as toastActions from "../../state/toasts/actions"

const styles = (theme: Theme) =>
  createStyles({
    iconButton: {
      padding: theme.spacing(0.5),
    },
    content: {
      backgroundColor: theme.palette.error.dark,
    },
  })

interface Props extends WithStyles<typeof styles> {
  isOpen: boolean
  message: string | null
  hideToast(): void
}

const Toast: React.FunctionComponent<Props> = props => {
  const { message, isOpen, classes, hideToast } = props
  const handleClose = (
    event: React.SyntheticEvent<any, Event>,
    reason: string
  ): void => {
    if (reason === "clickaway") {
      return
    }

    hideToast()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.content}
        message={message}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.iconButton}
            onClick={hideToast}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

const mapStateToProps = (state: RootState) => ({
  isOpen: state.toasts.isOpen,
  message: state.toasts.message,
})

const mapDispatchToProps = (
  dispatch: Dispatch<ActionType<typeof toastActions>>
) => ({
  hideToast: () => {
    dispatch(toastActions.hideToast())
  },
})

const componentWithStyles = withStyles(styles)(Toast)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(componentWithStyles)
