import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"

type State = {
  shown: boolean
  id?: string
}

const initialState = {
  shown: false,
}

export const storyPreviewReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.showStoryPreviewId):
      return {
        ...state,
        shown: true,
        id: action.payload,
      }

    case getType(actions.hideStoryPreview):
      return {
        ...state,
        shown: false,
        id: undefined,
      }

    default:
      return state
  }
}
