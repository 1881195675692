"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var UploadModel =
/** @class */
function () {
  function UploadModel(params) {
    this.attribution = "";
    this.sortOrder = 0;
    this.file = params.file;
    this.attribution = params.attribution;
    this.sortOrder = params.sortOrder;
  }

  return UploadModel;
}();

exports.UploadModel = UploadModel;