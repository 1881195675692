"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var BASE32 = "0123456789bcdefghjkmnpqrstuvwxyz";
var BASE32_DICT = BASE32.split("").reduce(function (acc, letter, index) {
  acc[letter] = index;
  return acc;
}, {}); // LNG_OFFSET moves 0 longitude degree seam to the left, so we can avoid
// massively different hashes of places that are next to each other in Europe.

var LNG_OFFSET = 0;
var NUMBER_OF_CHARS = 12;
var MIN_LAT = -90;
var MAX_LAT = 90;
var MIN_LNG = -180;
var MAX_LNG = 180;

exports.encodeGeohash = function (latitude, longitude) {
  longitude += LNG_OFFSET;
  var chars = [];
  var bits = 0;
  var bitsTotal = 0;
  var hash_value = 0;
  var maxLat = MAX_LAT;
  var minLat = MIN_LAT;
  var maxLng = MAX_LNG;
  var minLng = MIN_LNG;
  var mid = 0;

  while (chars.length < NUMBER_OF_CHARS) {
    if (bitsTotal % 2 === 0) {
      mid = (maxLng + minLng) / 2;

      if (longitude > mid) {
        hash_value = (hash_value << 1) + 1;
        minLng = mid;
      } else {
        hash_value = (hash_value << 1) + 0;
        maxLng = mid;
      }
    } else {
      mid = (maxLat + minLat) / 2;

      if (latitude > mid) {
        hash_value = (hash_value << 1) + 1;
        minLat = mid;
      } else {
        hash_value = (hash_value << 1) + 0;
        maxLat = mid;
      }
    }

    bits++;
    bitsTotal++;

    if (bits === 5) {
      chars.push(BASE32[hash_value]);
      bits = 0;
      hash_value = 0;
    }
  }

  return chars.join("");
};

exports.decodeBBox = function (hash) {
  var isLng = true;
  var maxLat = MAX_LAT;
  var minLat = MIN_LAT;
  var maxLng = MAX_LNG;
  var minLng = MIN_LNG;
  var mid = 0;
  var hashValue = 0;

  for (var i = 0, l = hash.length; i < l; i++) {
    var code = hash[i].toLowerCase();
    hashValue = BASE32_DICT[code];

    for (var bits = 4; bits >= 0; bits--) {
      var bit = hashValue >> bits & 1;

      if (isLng) {
        mid = (maxLng + minLng) / 2;

        if (bit === 1) {
          minLng = mid;
        } else {
          maxLng = mid;
        }
      } else {
        mid = (maxLat + minLat) / 2;

        if (bit === 1) {
          minLat = mid;
        } else {
          maxLat = mid;
        }
      }

      isLng = !isLng;
    }
  }

  return [minLat, minLng, maxLat, maxLng];
};

exports.decodeGeohash = function (hash) {
  var bbox = exports.decodeBBox(hash);
  var latitude = (bbox[0] + bbox[2]) / 2;
  var longitude = (bbox[1] + bbox[3]) / 2 - LNG_OFFSET;
  return {
    latitude: latitude,
    longitude: longitude
  };
};