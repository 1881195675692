import gql from "graphql-tag"
import API from "@aws-amplify/api"
import { appSyncClient, mutation } from "../graphql"
import * as queries from "../graphql/queries"
import * as mutations from "../graphql/mutations"
import { CustomerModel } from "../models"

export const listCustomers = () => {
  const limit = 1000

  return appSyncClient.query({
    query: gql(queries.listCustomers),
    variables: { limit },
    fetchPolicy: "no-cache",
  })
}

export const searchCustomerDetails = () => {
  return appSyncClient.query({
    query: gql(queries.searchCustomerDetails),
    fetchPolicy: "no-cache",
  })
}

export const getCustomerById = (id: string) => {
  return appSyncClient.query({
    query: gql(queries.getCustomer),
    variables: { id },
  })
}

export const createCustomerFromModel = (customer: CustomerModel) => {
  const input: Partial<CustomerModel> = {
    name: customer.name,
  }

  if (customer.id) {
    input.id = customer.id
  }

  return mutation<typeof input>(mutations.createCustomer, input)
}

export const loginAsCustomer = (
  username: string,
  customerId: string
): Promise<any> =>
  new Promise((resolve, reject) => {
    const path = "/login-as-customer"
    const body = {
      login: {
        username,
        customerId,
      },
    }

    API.post("adminBackend", path, { body })
      .then((data: any) => {
        if (data.success) {
          resolve(data)
        } else {
          reject(data.error)
        }
      })
      .catch((error: any) => {
        reject(error)
      })
  })
