import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { StoryModel } from "../../models"

type State = {
  panTarget: PanTarget | null
  zoomTarget: PanTarget | null
  parentStory?: StoryModel
  editedStory?: StoryModel
  isAddStoryModeEnabled: boolean
  isAddChildStoryModeEnabled: boolean
  isEditStoryModeEnabled: boolean
}

const initialState: State = {
  panTarget: null,
  zoomTarget: null,
  parentStory: undefined,
  editedStory: undefined,
  isAddStoryModeEnabled: false,
  isAddChildStoryModeEnabled: false,
  isEditStoryModeEnabled: false,
}

export const mapReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.panTo):
      return {
        ...state,
        panTarget: action.payload,
      }

    case getType(actions.zoomIn):
      return {
        ...state,
        zoomTarget: action.payload,
      }

    case getType(actions.enableAddStoryMode):
      return {
        ...state,
        isAddStoryModeEnabled: true,
      }

    case getType(actions.disableAddStoryMode):
      return {
        ...state,
        isAddStoryModeEnabled: false,
      }

    case getType(actions.enableAddChildStoryMode):
      return {
        ...state,
        isAddChildStoryModeEnabled: true,
        parentStory: action.payload,
      }

    case getType(actions.disableAddChildStoryMode):
      return {
        ...state,
        isAddChildStoryModeEnabled: false,
        parentStory: undefined,
      }

    case getType(actions.enableEditStoryPositionMode):
      return {
        ...state,
        isEditStoryModeEnabled: true,
        editedStory: action.payload,
      }

    case getType(actions.disableEditStoryPositionMode):
      return {
        ...state,
        isEditStoryModeEnabled: false,
        editedStory: undefined,
      }

    default:
      return state
  }
}
