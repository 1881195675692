import { createAction, createStandardAction } from "typesafe-actions"

const TOAST_SHOW = "[TOASTS] TOAST_SHOW"
const TOAST_HIDE = "[TOASTS] TOAST_HIDE"

/*
 * Show error from GraphQl
 */
export const showErrorToast = createStandardAction(TOAST_SHOW)<any>()

export const hideToast = createAction(TOAST_HIDE)
