import { API, Auth, Storage } from "aws-amplify"
import awsConfig from "@hidden/backend"
import * as I18n from "./locales"

// retrieve temporary AWS credentials and sign requests
Auth.configure(awsConfig)
// send analytics events to Amazon Pinpoint
// Analytics.configure(awsConfig)
API.configure(awsConfig)
Storage.configure(awsConfig)

I18n.configure(["no", "en"], {
  defaultLanguage: I18n.getInitialLanguage(),
})
