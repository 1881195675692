import { Bounds } from "google-map-react"
import gql from "graphql-tag"
import { appSyncClient, mutation } from "../graphql"
import * as queries from "../graphql/queries"
import * as mutations from "../graphql/mutations"
import { StoryModel } from "../models"
import { Status } from "../graphql/API"
import { createDataOrigin } from "./data-origin"

export const searchStoriesInBounds = (
  bounds: Bounds,
  precision: number,
  aggregate: boolean,
  owner: string | undefined,
  categories: string[]
) => {
  const north = bounds.nw.lat
  const south = bounds.se.lat
  const west = bounds.nw.lng
  const east = bounds.se.lng

  const limit = mapSearchLimit(aggregate)

  const filter = {
    bounds: {
      top: north,
      bottom: south,
      left: west,
      right: east,
    },
    precision,
    owner,
    categories,
  }

  return appSyncClient.query({
    query: gql(queries.searchStoriesInBounds),
    variables: { filter, limit },
    fetchPolicy: "no-cache",
  })
}

const mapSearchLimit = (aggregate: boolean): number => {
  if (aggregate) {
    return 0
  } else {
    return 200
  }
}

export const searchUserStories = (
  user: CurrentUser,
  page: number,
  perPage: number
) => {
  const filter = {
    owner: user.customerId,
  }

  const nextToken = page * perPage

  return appSyncClient.query({
    query: gql(queries.searchUserStories),
    variables: { filter, nextToken, limit: perPage },
    fetchPolicy: "no-cache",
  })
}

export const searchChildStoriesByParentId = (
  currentUser: CurrentUser,
  id: string
) => {
  const owner = currentUser.customerId

  const filter = {
    id,
    owner,
  }

  return appSyncClient.query({
    query: gql(queries.searchChildStories),
    variables: { filter },
    fetchPolicy: "no-cache",
  })
}

export const searchStoriesByQuery = (query: string, limit: number = 10) => {
  const filter = {
    query,
  }

  return appSyncClient.query({
    query: gql(queries.searchStoriesByQuery),
    variables: { filter, limit },
    fetchPolicy: "no-cache",
  })
}

export const getStoryById = (id: string) => {
  return appSyncClient.query({
    query: gql(queries.getStory),
    variables: { id },
    fetchPolicy: "no-cache",
  })
}

export const updateStoryWithModel = async (
  currentUser: CurrentUser,
  story: StoryModel
) => {
  const user = currentUser.username

  return updateStory({
    id: story.id,
    name: story.name,
    textExpo: story.textExpo,
    textMain: story.textMain,
    status: story.status,
    geoRadius: story.geoRadius,
    geoAltitude: story.geoAltitude,
    geoPoint: {
      lat: story.geoLatitude,
      lon: story.geoLongitude,
    },
    dateStory: "Nå",
    arSceneName: story.arSceneName,
    userModified: user,
    storyCategoryId: story.storyCategoryId,
    expectedVersion: story.version,
    references: createReferences(story.reference),
    storyParentId: story.storyParentId,
  })
}

export const updateStoryStatus = async (
  currentUser: CurrentUser,
  story: StoryModel,
  status: Status
) => {
  const user = currentUser.username

  return updateStory({
    id: story.id,
    status: status,
    userModified: user,
    expectedVersion: story.version,
  })
}

export const createStoryFromModel = async (
  currentUser: CurrentUser,
  story: StoryModel
) => {
  const user = currentUser.username
  const owner = currentUser.customerId
  const dataOrigin = await createDataOrigin({
    name: "test",
  })

  if (user && owner && dataOrigin && dataOrigin.data) {
    return createStory({
      name: story.name,
      textExpo: story.textExpo,
      textMain: story.textMain,
      geoRadius: story.geoRadius,
      geoAltitude: story.geoAltitude,
      geoPoint: {
        lat: story.geoLatitude,
        lon: story.geoLongitude,
      },
      dateStory: "Nå",
      arSceneName: story.arSceneName,
      userModified: user,
      userCreated: user,
      owner: owner,
      status: story.status,
      private: false,
      storyCategoryId: story.storyCategoryId,
      storyDataOriginId: dataOrigin.data.createDataOrigin.id,
      references: createReferences(story.reference),
      storyParentId: story.storyParentId,
    })
  }
}

export const createStory = (input: any) => {
  return mutation<typeof input>(mutations.createStory, clean(input))
}

export const updateStory = (input: any) => {
  return mutation<typeof input>(mutations.updateStory, clean(input))
}

const createReferences = (reference: string | undefined): string[] => {
  if (reference) {
    return [reference]
  } else {
    return []
  }
}

const nullables = ["storyParentId", "arSceneName"]

const clean = (input: any) => {
  const result = { ...input }
  for (const i in nullables) {
    result[nullables[i]] = result[nullables[i]] || null
  }
  return result
}
