import React, { FunctionComponent } from "react"
import { Button } from "@material-ui/core"
import { Theme, createStyles, withStyles } from "@material-ui/core"
import i18n from "../../locales"
import { useCategoryIcon } from "../../utils/category"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    label: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.75rem",
      lineHeight: 2.5,
    },
    button: {
      marginLeft: theme.spacing(2),
      verticalAlign: "top",
    },
  })

const handleChange = (onChange: any) => (event: any) => {
  onChange(event.target.files[0])
}

const CategoryFormImageFieldUnstyled: FunctionComponent<any> = (props: any) => {
  const { classes, input } = props

  const fieldId = "category_image"

  return (
    <label htmlFor={fieldId} className={classes.root}>
      <div className={classes.label}>
        {i18n("adminCategoryForm.label.image")}
      </div>
      <Preview {...props} />
      <input
        id={fieldId}
        accept="image/jpeg, image/png"
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={handleChange(input.onChange)}
      />
      <Button component="span" className={classes.button}>
        {i18n("adminCategoryForm.button.changeImage")}
      </Button>
    </label>
  )
}

const Preview: FunctionComponent<any> = (props: any) => {
  const width = 100
  const height = 100
  const img = useCategoryIcon(props.categoryId)

  if (props.input.value) {
    const preview = URL.createObjectURL(props.input.value)
    return <img src={preview} alt="" width={width} height={height} />
  } else {
    return <img src={img} alt="" width={width} height={height} />
  }
}

export const CategoryFormImageField = withStyles(styles)(
  CategoryFormImageFieldUnstyled
)
