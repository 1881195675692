"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var SuperUserModel =
/** @class */
function () {
  function SuperUserModel(params) {
    this.username = "";
    this.email = "";
    this.customerId = "";
    this.status = "";
    this.createdAt = "";
    this.storyCount = 0;
    this.lastAction = "";

    if (params) {
      this.username = params.username;
      this.email = params.email;
      this.customerId = params.customerId;
      this.status = params.status;
      this.createdAt = params.createdAt;

      if (params.storyCount) {
        this.storyCount = params.storyCount;
      }

      if (params.lastAction) {
        this.lastAction = params.lastAction;
      }
    }
  }

  return SuperUserModel;
}();

exports.SuperUserModel = SuperUserModel;