"use strict";
/* tslint:disable */
//  This file was automatically generated and should not be edited.

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Status;

(function (Status) {
  Status["DRAFT"] = "DRAFT";
  Status["PUBLISHED"] = "PUBLISHED";
  Status["PAUSED"] = "PAUSED";
  Status["DELETED"] = "DELETED";
})(Status = exports.Status || (exports.Status = {}));

var SearchableStorySortableFields;

(function (SearchableStorySortableFields) {
  SearchableStorySortableFields["id"] = "id";
  SearchableStorySortableFields["name"] = "name";
  SearchableStorySortableFields["textExpo"] = "textExpo";
  SearchableStorySortableFields["textMain"] = "textMain";
  SearchableStorySortableFields["geoRadius"] = "geoRadius";
  SearchableStorySortableFields["geoAltitude"] = "geoAltitude";
  SearchableStorySortableFields["reference"] = "reference";
  SearchableStorySortableFields["references"] = "references";
  SearchableStorySortableFields["stars"] = "stars";
  SearchableStorySortableFields["arSceneName"] = "arSceneName";
  SearchableStorySortableFields["private"] = "private";
  SearchableStorySortableFields["comment"] = "comment";
  SearchableStorySortableFields["dateStory"] = "dateStory";
  SearchableStorySortableFields["owner"] = "owner";
  SearchableStorySortableFields["userCreated"] = "userCreated";
  SearchableStorySortableFields["userModified"] = "userModified";
  SearchableStorySortableFields["analyticsUnlockCount"] = "analyticsUnlockCount";
  SearchableStorySortableFields["analyticsClickCount"] = "analyticsClickCount";
})(SearchableStorySortableFields = exports.SearchableStorySortableFields || (exports.SearchableStorySortableFields = {}));

var SearchableSortDirection;

(function (SearchableSortDirection) {
  SearchableSortDirection["asc"] = "asc";
  SearchableSortDirection["desc"] = "desc";
})(SearchableSortDirection = exports.SearchableSortDirection || (exports.SearchableSortDirection = {}));