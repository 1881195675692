import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { CustomerModel } from "../../../models"

type State = {
  entities: {
    [key: string]: CustomerModel
  }
  allIds: string[]
}

const initialState: State = {
  entities: {},
  allIds: [],
}

export const customersReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.setCustomers):
      if ("payload" in action) {
        return {
          ...state,
          entities: action.payload.reduce(
            (acc: any, customer: any) => {
              if (acc[customer.id]) {
                acc[customer.id].name = customer.name
              } else {
                acc[customer.id] = new CustomerModel(customer)
              }
              return acc
            },
            { ...state.entities }
          ),
          allIds: action.payload.map((customer: any) => customer.id),
        }
      } else {
        return state
      }

    case getType(actions.setCustomerDetails):
      if ("payload" in action) {
        return {
          ...state,
          entities: action.payload.reduce(
            (acc: any, customer: any) => {
              if (acc[customer.id]) {
                acc[customer.id].storyCount = customer.storyCount
                acc[customer.id].lastAction = customer.lastAction
              }
              return acc
            },
            { ...state.entities }
          ),
        }
      } else {
        return state
      }

    default:
      return state
  }
}
