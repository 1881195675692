"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var geohash_1 = require("../services/geohash");

var StoryClusterModel =
/** @class */
function () {
  function StoryClusterModel(params) {
    this.hash = "";
    this.count = 0;
    this.hash = params.key;
    this.count = params.doc_count;
    var geoPoint = geohash_1.decodeGeohash(this.hash);
    this.geoPoint = {
      lat: geoPoint.latitude,
      lon: geoPoint.longitude
    };
  }

  return StoryClusterModel;
}();

exports.StoryClusterModel = StoryClusterModel;

exports.aggregateClusters = function (aggregationBuckets, precision) {
  if (aggregationBuckets.length === 0) {
    return [];
  }

  var flattenAggregations = aggregationBuckets.reduce(function (acc, bucket) {
    return acc.concat.apply(acc, bucket.stories);
  }, []);

  if (flattenAggregations.length === 0) {
    return [];
  }

  var result = [];
  var clusters = new Map();
  var clusterData;
  flattenAggregations.forEach(function (aggregation) {
    var key = aggregation.key.substring(0, precision - 1);
    clusterData = getOrCreateClusterData(clusters, key);
    var point = geoPointFrom(geohash_1.decodeGeohash(aggregation.key));
    var count = aggregation.doc_count;
    clusterData.shifts.push({
      point: point,
      count: count
    });
  });
  clusters.forEach(function (clusterData, key) {
    var cluster = clusterData.cluster,
        shifts = clusterData.shifts;

    var center = __assign({}, cluster.geoPoint);

    var totalCount = shifts.reduce(function (acc, shift) {
      return acc + shift.count;
    }, 0);

    if (totalCount === 0) {
      return;
    }

    cluster.count = totalCount;
    shifts.forEach(function (shift) {
      var factor = shift.count / totalCount;
      var latDelta = shift.point.lat - center.lat;
      var lonDelta = shift.point.lon - center.lon;
      cluster.geoPoint.lat += latDelta * factor;
      cluster.geoPoint.lon += lonDelta * factor;
    });
    result.push(cluster);
  });
  return result;
};

var geoPointFrom = function geoPointFrom(decodedGeohash) {
  return {
    lat: decodedGeohash.latitude,
    lon: decodedGeohash.longitude
  };
};

var getOrCreateClusterData = function getOrCreateClusterData(clusters, key) {
  if (clusters.has(key)) {
    return clusters.get(key);
  } else {
    var clusterData = newClusterData(key);
    clusters.set(key, clusterData);
    return clusterData;
  }
};

var newClusterData = function newClusterData(key) {
  return {
    cluster: new StoryClusterModel({
      key: key,
      doc_count: 0
    }),
    shifts: []
  };
};