import React, { FunctionComponent, useContext } from "react"
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import UserMenu from "../Authenticator/UserMenu"
import appContext from "../../AppContext"
import { isAdmin } from "../../utils/user"
import i18n from "../../locales"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: "#ffffff",
      backgroundColor: "#02062e",
    },
    toolbar: {
      height: 64,
    },
    logo: {
      height: 48,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(2),
    },
    brand: {
      display: "block",
      opacity: 0.8,
    },
    admin: {
      paddingTop: 4,
      marginLeft: theme.spacing(3),
      color: "inherit",
      textDecoration: "none",
    },
    grow: {
      flexGrow: 1,
    },
    link: {
      color: "inherit",
      textDecoration: "none",
    },
    [theme.breakpoints.only("xs")]: {
      brand: {
        display: "none",
      },
    },
  })

interface Props extends WithStyles<typeof styles> {}

const Header: FunctionComponent<Props> = (props: Props) => {
  const context = useContext(appContext)

  const { classes } = props
  const { currentUser } = context

  if (!currentUser) {
    return null
  }

  const admin = isAdmin(currentUser)

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Link to="/" className={classes.link}>
          <img src="/logo2.svg" alt="" className={classes.logo} />
        </Link>
        <Typography variant="h6" color="inherit" className={classes.brand}>
          {i18n("greetings.brand")}
        </Typography>
        {admin && (
          <Link to="/admin/" className={classes.admin}>
            <Button color="inherit">{i18n("greetings.admin")}</Button>
          </Link>
        )}
        <div className={classes.grow} />
        <UserMenu />
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(Header)
