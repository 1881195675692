import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { StoryModel } from "../../models"

type State = {
  isRetrievingParentStory: boolean
  isRetrievingChildStories: boolean
  parentStory?: StoryModel
  childStories: StoryModel[]
}

const initialState = {
  isRetrievingParentStory: false,
  isRetrievingChildStories: false,
  parentStory: undefined,
  childStories: [],
}

export const relatedStoriesReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.setIsRetrievingParentStory):
      return {
        ...state,
        isRetrievingParentStory: action.payload,
      }

    case getType(actions.setParentStory):
      return {
        ...state,
        parentStory: action.payload,
      }

    case getType(actions.setIsRetrievingChildStories):
      return {
        ...state,
        isRetrievingChildStories: action.payload,
      }

    case getType(actions.setChildStories):
      return {
        ...state,
        childStories: action.payload,
      }

    default:
      return state
  }
}
