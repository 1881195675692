import {
  createAction,
  createStandardAction,
  createAsyncAction,
} from "typesafe-actions"
import { Bounds, Coords } from "google-map-react"

import { StoryModel, StoryMarkerModel, aggregateClusters } from "../../models"
import { Status } from "../../graphql/API"
import * as modalListingActions from "../modals/story-listing/actions"
import * as modalFormActions from "../modals/story-form/actions"
import * as toastsActions from "../toasts/actions"
import { updateStoryImages } from "../images/actions"
import { updateStoryAudios } from "../audios/actions"
import { updateStoryVideos } from "../videos/actions"
import { panTo, disableEditStoryPositionMode } from "../map/actions"
import {
  searchStoriesInBounds,
  searchUserStories,
  getStoryById,
  createStoryFromModel,
  updateStoryStatus,
  updateStoryWithModel,
} from "../../services/story"
import { zoomToGeohashPrecision } from "@hidden/backend/dist/services/map"

const config = require("../../config/map.json")

const ON_CREATE_STORY_SUCCESS = "[STORIES] ON_CREATE_STORY_SUCCESS"
const ON_UPDATE_STORY_SUCCESS = "[STORIES] ON_UPDATE_STORY_SUCCESS"

const CREATE_OR_UPDATE_REQUEST = "[STORIES] CREATE_OR_UPDATE_REQUEST"
const CREATE_OR_UPDATE_REQUEST_SUCCESS =
  "[STORIES] CREATE_OR_UPDATE_REQUEST_SUCCESS"
const CREATE_OR_UPDATE_REQUEST_FAILURE =
  "[STORIES] CREATE_OR_UPDATE_REQUEST_FAILURE"
const CREATE_OR_UPDATE_REQUEST_PROGRESS =
  "[STORIES] CREATE_OR_UPDATE_REQUEST_PROGRESS"

const LIST_USER_STORIES_REQUEST = "[STORIES] LIST_USER_STORIES_REQUEST"
const LIST_USER_STORIES_REQUEST_SUCCESS =
  "[STORIES] LIST_USER_STORIES_REQUEST_SUCCESS"
const LIST_USER_STORIES_REQUEST_FAILURE =
  "[STORIES] LIST_USER_STORIES_REQUEST_FAILURE"

const UPDATE_STATUS_REQUEST = "[STORIES] UPDATE_STATUS_REQUEST"
const UPDATE_STATUS_REQUEST_SUCCESS = "[STORIES] UPDATE_STATUS_REQUEST_SUCCESS"
const UPDATE_STATUS_REQUEST_FAILURE = "[STORIES] UPDATE_STATUS_REQUEST_FAILURE"

const SET_MARKERS = "[STORIES] SET_MARKERS"
const SET_CLUSTERS = "[STORIES] SET_CLUSTERS"
const SET_USER = "[STORIES] SET_USER"
const SET_PREVIEW = "[STORIES] SET_PREVIEW"
const RESET_PREVIEW = "[STORIES] SET_PREVIEW"

/*
 * Retrieve stories from DB
 */
export const retrieveMapStories = (
  bounds: Bounds,
  zoom: number,
  owner: string | undefined,
  categories: string[]
) => (dispatch: any) => {
  const precision = zoomToGeohashPrecision(config.geohashPrecisionLevels, zoom)
  const aggregate = zoom <= config.markersClustersThreshold

  searchStoriesInBounds(bounds, precision, aggregate, owner, categories)
    .then(({ data }: any) => {
      const items = data.searchStoriesInBounds.items || []
      const aggregations = data.searchStoriesInBounds.aggregations || []
      const gotAllItems = items.length >= data.searchStoriesInBounds.total

      if (gotAllItems) {
        dispatch(setMarkers(items))
      } else {
        dispatch(setMarkers([]))
      }

      dispatch(setClusters(aggregations, precision))
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}

/*
 * Show a dialog and retrieve user story records
 */
export const retrieveUserStories = (
  currentUser: CurrentUser,
  page: number,
  perPage: number
) => (dispatch: any, getState: any) => {
  dispatch(listUserStoriesRequest.request())
  searchUserStories(currentUser, page, perPage)
    .then(({ data }: any) => {
      const items = data.searchUserStories.items

      dispatch(listUserStoriesRequest.success())

      if (items.length) {
        dispatch(setUser(items, page))
      }
    })
    .catch((error: any) => {
      dispatch(listUserStoriesRequest.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const retrievePreviewStory = (id: string) => (
  dispatch: any,
  getState: any
) => {
  getStoryById(id)
    .then(({ data }: any) => {
      const item = data.getStory
      dispatch(setPreview(item))
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}

/*
 * Change story status
 */
export const updateStatus = (
  currentUser: CurrentUser,
  story: StoryModel,
  status: Status
) => (dispatch: any, getState: any) => {
  dispatch(updateStatusRequest.request())
  updateStoryStatus(currentUser, story, status)
    .then(({ data }: any) => {
      dispatch(updateStatusRequest.success())
      dispatch(onUpdateStorySuccess(data))
    })
    .catch((error: any) => {
      dispatch(updateStatusRequest.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}

/*
 * Open a story form for editing
 */
export const editStory = (story: StoryModel) => (
  dispatch: any,
  getState: any
) => {
  const coords: Coords = {
    lat: story.geoLatitude!,
    lng: story.geoLongitude!,
  }
  dispatch(disableEditStoryPositionMode())
  dispatch(modalListingActions.closeStoryListing())
  dispatch(modalFormActions.openStoryForm(story))
  dispatch(panTo(coords, 0.35))
}

/*
 * Process `createStory` mutation response
 */
export const onCreateStorySuccess = createAction(
  ON_CREATE_STORY_SUCCESS,
  resolve => (data: any) => resolve(data.createStory)
)

/*
 * Process `updateStory` mutation response
 */
export const onUpdateStorySuccess = createAction(
  ON_UPDATE_STORY_SUCCESS,
  resolve => (data: any) => resolve(data.updateStory)
)

export const setMarkers = createAction(SET_MARKERS, resolve => (data: any) =>
  resolve(data.map((item: any) => new StoryMarkerModel(item)))
)

export const setClusters = createAction(
  SET_CLUSTERS,
  resolve => (data: any, precision: number = 3) =>
    resolve(aggregateClusters(data, precision))
)

export const setUser = createAction(
  SET_USER,
  resolve => (data: any, page: number) =>
    resolve(data.map((item: any) => new StoryModel(item)), page)
)

export const setPreview = createAction(SET_PREVIEW, resolve => (data: any) =>
  resolve(new StoryModel(data))
)

export const resetPreview = createAction(RESET_PREVIEW, resolve => () =>
  resolve(undefined)
)

/*
 * Run necessary mutations to create a new story record
 */
export const createStory = (currentUser: CurrentUser, story: StoryModel) => (
  dispatch: any,
  getState: any
) => {
  dispatch(submitStatus.request())
  createStoryFromModel(currentUser, story)
    .then(async ({ data }: any) => {
      story.id = data.createStory.id
      await dispatch(updateStoryImages(currentUser, story))
      await dispatch(updateStoryAudios(currentUser, story))
      await dispatch(updateStoryVideos(currentUser, story))
      dispatch(submitStatus.success())
      dispatch(onCreateStorySuccess(data))
    })
    .catch((error: any) => {
      dispatch(submitStatus.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}

/*
 * Run necessary mutations to update an existing story record
 */
export const updateStory = (currentUser: CurrentUser, story: StoryModel) => (
  dispatch: any,
  getState: any
) => {
  dispatch(submitStatus.request())
  updateStoryWithModel(currentUser, story)
    .then(async ({ data }: any) => {
      await dispatch(updateStoryImages(currentUser, story))
      await dispatch(updateStoryAudios(currentUser, story))
      await dispatch(updateStoryVideos(currentUser, story))
      dispatch(submitStatus.success())
      dispatch(onUpdateStorySuccess(data))
    })
    .catch((error: any) => {
      dispatch(submitStatus.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}

/*
 * Run necessary mutations to delete an existing story record
 */
export const removeStory = (currentUser: CurrentUser, story: StoryModel) => (
  dispatch: any,
  getState: any
) => {
  dispatch(submitStatus.request())
  updateStoryStatus(currentUser, story, Status.DELETED)
    .then(({ data }: any) => {
      dispatch(submitStatus.success())
      dispatch(onUpdateStorySuccess(data))
    })
    .catch((error: any) => {
      dispatch(submitStatus.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const submitStatus = createAsyncAction(
  CREATE_OR_UPDATE_REQUEST,
  CREATE_OR_UPDATE_REQUEST_SUCCESS,
  CREATE_OR_UPDATE_REQUEST_FAILURE
)<void, void, void>()

export const updateStatusRequest = createAsyncAction(
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_REQUEST_SUCCESS,
  UPDATE_STATUS_REQUEST_FAILURE
)<void, void, void>()

export const submitStatusProgress = createStandardAction(
  CREATE_OR_UPDATE_REQUEST_PROGRESS
)<void>()

export const listUserStoriesRequest = createAsyncAction(
  LIST_USER_STORIES_REQUEST,
  LIST_USER_STORIES_REQUEST_SUCCESS,
  LIST_USER_STORIES_REQUEST_FAILURE
)<void, void, void>()
