import { createAction } from "typesafe-actions"
import * as storyActions from "../../stories/actions"
import * as storyListingActions from "../story-listing/actions"

const OPEN_STORY_LISTING = "[MODALS] OPEN STORY LISTING"
const CLOSE_STORY_LISTING = "[MODALS] CLOSE STORY LISTING"

export const pageLength = 10

export const showStoryListing = (user: CurrentUser) => (dispatch: any) => {
  dispatch(storyActions.retrieveUserStories(user, 0, pageLength))
  dispatch(storyListingActions.openStoryListing())
}

/*
 * Show a dialog window
 */
export const openStoryListing = createAction(OPEN_STORY_LISTING)

/*
 * Hide a dialog window
 */
export const closeStoryListing = createAction(CLOSE_STORY_LISTING)
