import Storage from "@aws-amplify/storage"
import gql from "graphql-tag"
import * as queries from "../graphql/queries"
import * as mutations from "../graphql/mutations"
import { appSyncClient, mutation } from "../graphql"
import { CategoryModel } from "../models"

export const listCategories = () => {
  return appSyncClient.query({
    query: gql(queries.listCategories),
    variables: { limit: 100 },
    fetchPolicy: "no-cache",
  })
}

export const searchCategoryDetails = () => {
  return appSyncClient.query({
    query: gql(queries.searchCategoryDetails),
    fetchPolicy: "no-cache",
  })
}

export const createCategoryFromModel = (category: CategoryModel) => {
  const input: Partial<CategoryModel> = {
    id: category.id,
    names: JSON.stringify(category.names),
  }

  return mutation<typeof input>(mutations.createCategory, input)
}

export const updateCategoryWithModel = (category: CategoryModel) => {
  const input: Partial<CategoryModel> = {
    id: category.id,
    names: JSON.stringify(category.names),
  }

  return mutation<typeof input>(mutations.updateCategory, input)
}

export const deleteCategoryById = (id: string) => {
  const input = {
    id,
  }

  return mutation<typeof input>(mutations.deleteCategory, input)
}

export const uploadCategoryImage = async (category: CategoryModel) => {
  const file = category.image

  if (file) {
    const key = `category-${category.id}`
    const type = "category"

    await Storage.put(key, file, {
      customPrefix: {
        public: "uploads/",
      },
      metadata: {
        key,
        type,
      },
      level: "public",
      contentType: file.type,
    })
  }
}
