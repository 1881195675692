import { combineReducers } from "redux"
import { customersReducer } from "./customers/reducer"
import { superUsersReducer } from "./super-users/reducer"
import { categoriesReducer } from "./categories/reducer"

export const adminReducer = combineReducers({
  customers: customersReducer,
  superUsers: superUsersReducer,
  categories: categoriesReducer,
})
