import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from "react"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core"
import StoryImage from "./StoryImage"
import { ImageModel, ImageContainer, UploadModel } from "../../models"

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      overflow: "hidden",
      cursor: "pointer",
    },
  })

interface Props extends WithStyles<typeof styles> {
  source: ImageContainer
}

const imageDelay = 3500

const StoryImages: FunctionComponent<Props> = (props: Props) => {
  const { classes, source: container } = props
  const imageCount = container.items.length + container.toUpload.length

  const [index, setIndex] = useState(0)

  const showNext = useCallback(() => {
    if (index + 1 >= imageCount) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }, [index, imageCount])

  useEffect(() => {
    const interval = setInterval(showNext, imageDelay)
    return () => clearInterval(interval)
  }, [index, showNext])

  if (imageCount > 0) {
    let imagesList = container.items
    imagesList.sort((a: ImageModel, b: ImageModel) => a.sortOrder - b.sortOrder)
    return (
      <div className={classes.container} onClick={showNext}>
        {imagesList.map((item: ImageModel) => (
          <StoryImage key={item.id} item={item} offset={index} />
        ))}

        {container.toUpload.map((item: UploadModel, id: number) => (
          <StoryImage key={id} item={item} offset={index} />
        ))}
      </div>
    )
  } else {
    return null
  }
}

export default withStyles(styles)(StoryImages)
