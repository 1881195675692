import React, { FunctionComponent, useState } from "react"
import { Coords } from "google-map-react"
import PlacesAutocomplete, {
  geocodeByAddress,
  Suggestion,
} from "react-places-autocomplete"
import {
  Paper,
  InputBase,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import IconSearch from "@material-ui/icons/Search"
import IconPlace from "@material-ui/icons/Place"
import { StoryModel } from "../../models"
import { searchStoriesByQuery } from "../../services/story"
import i18n from "../../locales"
import { useCategoryIcon } from "../../utils/category"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      top: 80,
      left: 12,
      maxWidth: "80vw",
      zIndex: 1,
    },
    icon: {
      position: "absolute",
      top: 12,
      left: 12,
      opacity: 0.5,
      verticalAlign: "top",
    },
    suggestionMarker: {
      width: 24,
      height: 20,
      marginLeft: -8,
      marginRight: 6,
      marginBottom: -5,
      opacity: 0.4,
      objectFit: "contain",
    },
    suggestionIcon: {
      height: 16,
      paddingTop: 3,
      marginLeft: -8,
      marginRight: 6,
      opacity: 0.25,
      verticalAlign: "top",
    },
    inputWrapper: {
      padding: 12,
      paddingLeft: 48,
      width: 400,
      verticalAlign: "top",
    },
    input: {
      paddingTop: 3,
      paddingBottom: 3,
    },
    suggestions: {
      marginTop: theme.spacing(),
    },
    loading: {
      textAlign: "center",
      borderRadius: theme.spacing(),
      color: "#888888",
    },
    suggestion: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: theme.spacing(1),
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "#fafafa",
      },
    },
    suggestionActive: {
      backgroundColor: "#fafafa",
    },
  })

interface Props extends WithStyles<typeof styles> {
  map: any
  showPreview: (id: string, coords?: Coords) => void
}

const retrieveStorySuggestions = (query: string): Promise<StoryModel[]> => {
  return searchStoriesByQuery(query.toLowerCase(), 4)
    .then(({ data }: any) => {
      const items = data.searchStoriesByQuery.items || []
      const stories = items.map((item: any): StoryModel => new StoryModel(item))
      return stories
    })
    .catch(() => {
      return []
    })
}

const suggestionKey = (name: string, index: number): string => {
  return `${name}_${index}`
}

const suggestionClassName = (classes: any, suggestion: Suggestion): string => {
  if (suggestion.active) {
    return [classes.suggestion, classes.suggestionActive].join(" ")
  } else {
    return classes.suggestion
  }
}

const Input = (classes: any, getInputProps: any, searchStories: any) => {
  const inputProps = getInputProps()
  const props = {
    ...inputProps,
    placeholder: i18n("map.search.placeholder"),
    className: classes.inputWrapper,
    onChange: (event: any) => {
      inputProps.onChange(event)
      searchStories(event.target.value)
    },
  }

  return <InputBase {...props} inputProps={{ className: classes.input }} />
}

const Suggestions = (
  props: Props,
  setValue: any,
  getSuggestionItemProps: any,
  storySuggestions: StoryModel[],
  storyLoading: boolean,
  placeSuggestions: readonly Suggestion[],
  placeLoading: boolean
): any => {
  const { classes, showPreview } = props

  if (placeSuggestions.length > 0) {
    if (storyLoading || placeLoading) {
      return (
        <div
          className={[classes.suggestion, classes.loading].join(" ")}
          key="storyLoading"
        >
          <CircularProgress size={24} thickness={6} />
        </div>
      )
    } else {
      return [
        storySuggestions.map((suggestion: StoryModel, index: number) => (
          <div
            key={suggestionKey(suggestion.name, index)}
            className={classes.suggestion}
            onMouseDown={() => {
              setValue(suggestion.name)
              showPreview(suggestion.id, {
                lat: suggestion.geoPoint!.lat,
                lng: suggestion.geoPoint!.lon,
              })
            }}
          >
            <SuggestionMarker
              className={classes.suggestionMarker}
              suggestion={suggestion}
            />
            <span>{suggestion.name}</span>
          </div>
        )),
        placeSuggestions.map((suggestion: Suggestion, index: number) => (
          <div
            {...getSuggestionItemProps(suggestion, {
              className: suggestionClassName(classes, suggestion),
            })}
          >
            <IconPlace className={classes.suggestionIcon} />
            <span>{suggestion.description}</span>
          </div>
        )),
      ]
    }
  } else {
    return null
  }
}

const SuggestionMarker: any = (props: any) => {
  const { className, suggestion } = props
  const img = useCategoryIcon(suggestion.storyCategoryId)

  return <img src={img} alt="" className={className} />
}

const MapSearch: FunctionComponent<Props> = (props: Props) => {
  const { classes, map } = props

  const [value, setValue] = useState("")
  const [panning, setPanning] = useState(false)

  const onSelect = (address: string) => {
    setValue(address)
    setPanning(true)
    geocodeByAddress(address)
      .then((results: any) => {
        const result = results[0]
        if (result && result.geometry && result.geometry.location) {
          map.panTo(result.geometry.location)
        }
        setPanning(false)
      })
      .catch((error: any) => {
        console.warn(error)
        setPanning(false)
      })
  }

  const [storyLoading, setStoryLoading] = useState<boolean>(false)
  const [storySuggestions, setStorySuggestions] = useState<StoryModel[]>([])

  const searchStories = (query: string) => {
    setStorySuggestions([])
    if (query.length >= 2) {
      setStoryLoading(true)
      retrieveStorySuggestions(query).then((stories: StoryModel[]) => {
        setStorySuggestions(stories)
        setStoryLoading(false)
      })
    }
  }

  const searchOptions = {
    componentRestrictions: {
      country: "no",
    },
  }

  return (
    <PlacesAutocomplete
      value={value}
      onChange={setValue}
      onSelect={onSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={classes.root}>
          <Paper>
            <IconSearch className={classes.icon} />
            {Input(classes, getInputProps, searchStories)}
          </Paper>
          <Paper className={classes.suggestions}>
            {panning ? (
              <div className={[classes.suggestion, classes.loading].join(" ")}>
                <CircularProgress size={24} thickness={6} />
              </div>
            ) : (
              Suggestions(
                props,
                setValue,
                getSuggestionItemProps,
                storySuggestions,
                storyLoading,
                suggestions,
                loading
              )
            )}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default withStyles(styles)(MapSearch)
