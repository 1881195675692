import { createAction, createAsyncAction } from "typesafe-actions"
import * as superUserActions from "../../admin/super-users/actions"
import * as toastsActions from "../../toasts/actions"
import { SuperUserModel } from "../../../models"
import { createSuperUserFromModel } from "../../../services/super-user"

const OPEN_SUPER_USER_FORM = "[MODALS] OPEN SUPER_USER FORM"
const CLOSE_SUPER_USER_FORM = "[MODALS] CLOSE SUPER_USER FORM"
const SUBMIT_REQUEST = "[MODALS] SUBMIT_REQUEST"
const SUBMIT_REQUEST_SUCCESS = "[MODALS] SUBMIT_REQUEST_SUCCESS"
const SUBMIT_REQUEST_FAILURE = "[MODALS] SUBMIT_REQUEST_FAILURE"

export const openSuperUserForm = createAction(
  OPEN_SUPER_USER_FORM,
  resolve => (superUser?: SuperUserModel) => resolve(superUser)
)

export const closeSuperUserForm = createAction(CLOSE_SUPER_USER_FORM)

export const submitStatus = createAsyncAction(
  SUBMIT_REQUEST,
  SUBMIT_REQUEST_SUCCESS,
  SUBMIT_REQUEST_FAILURE
)<void, void, void>()

export const createSuperUser = (
  superUser: SuperUserModel,
  password: string
) => (dispatch: any) => {
  dispatch(submitStatus.request())
  createSuperUserFromModel(superUser, password)
    .then(() => {
      dispatch(submitStatus.success())
      dispatch(superUserActions.retrieveSuperUsers(superUser.customerId))
    })
    .catch((error: any) => {
      dispatch(submitStatus.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}
