import React, { FunctionComponent, useMemo, useContext } from "react"
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom"
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core"
import Header from "./Admin/Header"
import Sidebar from "./Admin/Sidebar"
import Customers from "./Admin/Customers"
import SuperUsers from "./Admin/SuperUsers"
import Categories from "./Admin/Categories"
import Toast from "./Super/Toast"
import appContext from "../AppContext"
import { isAdmin } from "../utils/user"
import "../types/material-ui"

const sidebarWidth = 240

const theme = createMuiTheme({
  admin: {
    drawer: {
      width: sidebarWidth,
    },
  },
  typography: {
    fontFamily: "'Crimson Text', serif",
  },
})

const styles = (theme: Theme) =>
  createStyles({
    main: {
      padding: theme.spacing(2),
      height: "calc(100vh - 64px)",
      overflow: "auto",
      "-webkit-overflow-scrolling": "touch",
    },
    [theme.breakpoints.up("sm")]: {
      main: {
        marginLeft: sidebarWidth,
      },
    },
  })

interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

const Admin: FunctionComponent<Props> = (props: Props) => {
  const pages = useMemo<string[]>(() => ["customers", "categories"], [])

  const context = useContext(appContext)

  const { classes } = props
  const { currentUser } = context
  const root = props.match.path

  if (isAdmin(currentUser)) {
    return (
      <MuiThemeProvider theme={theme}>
        <Header />
        <Sidebar root={root} pages={pages} />
        <div className={classes.main}>
          <Switch>
            <Route path={`${root}/customers`} exact component={Customers} />
            <Route
              path={`${root}/customers/:customerId`}
              exact
              component={SuperUsers}
            />
            <Route path={`${root}/categories`} exact component={Categories} />
            <Redirect to={`${root}/customers`} />
          </Switch>
        </div>
        <Toast />
      </MuiThemeProvider>
    )
  } else {
    return <Redirect to="/" />
  }
}

export default withStyles(styles)(Admin)
