export const getError = (errors: any): string | null => {
  if (process.env.NODE_ENV === "development") {
    console.log(errors)
  }

  if (process.env.NODE_ENV === "test") {
    return null
  }

  if (
    errors.graphQLErrors &&
    errors.graphQLErrors[0] &&
    errors.graphQLErrors[0].errorType &&
    errors.graphQLErrors[0].errorType === "422"
  ) {
    const errorConstraints = errors.graphQLErrors[0].errorInfo[0].constraints
    return errorConstraints[Object.keys(errorConstraints)[0]]
  } else if (
    errors.graphQLErrors &&
    errors.graphQLErrors[0] &&
    errors.graphQLErrors[0].message
  ) {
    return errors.graphQLErrors[0].message
  } else if (errors.message) {
    return errors.message
  } else if (typeof errors === "string") {
    return errors
  }

  return null
}
