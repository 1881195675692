import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import * as storyActions from "../../stories/actions"

type State = {
  shown: boolean
  page: number
  isLoadingListUserStories: boolean
  isSubmitting: boolean
}

const initialState: State = {
  shown: false,
  page: 0,
  isLoadingListUserStories: false,
  isSubmitting: false,
}

export const storyListingReducer = (
  state: State = initialState,
  action: ActionType<typeof actions | typeof storyActions>
): State => {
  switch (action.type) {
    case getType(actions.openStoryListing):
      return {
        ...state,
        shown: true,
      }

    case getType(actions.closeStoryListing):
      return {
        ...state,
        shown: false,
      }

    case getType(storyActions.setUser):
      return {
        ...state,
        page: action.meta,
      }

    case getType(storyActions.listUserStoriesRequest.request):
      return {
        ...state,
        isLoadingListUserStories: true,
      }

    case getType(storyActions.listUserStoriesRequest.success):
    case getType(storyActions.listUserStoriesRequest.failure):
      return {
        ...state,
        isLoadingListUserStories: false,
      }

    case getType(storyActions.updateStatusRequest.request):
      return {
        ...state,
        isSubmitting: true,
      }

    case getType(storyActions.updateStatusRequest.success):
    case getType(storyActions.updateStatusRequest.failure):
      return {
        ...state,
        isSubmitting: false,
      }

    default:
      return state
  }
}
