import React, { FunctionComponent, useState, useEffect } from "react"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core"
import Storage from "@aws-amplify/storage"
import i18n from "../../locales"
import { VideoContainer, VideoModel, UploadModel } from "../../models"

const styles = (theme: Theme) =>
  createStyles({
    item: {
      position: "relative",
      display: "block",
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    video: {
      width: "100%",
    },
  })

interface Props extends WithStyles<typeof styles> {
  source: VideoContainer
}

const StoryVideos: FunctionComponent<Props> = (props: Props) => {
  const { classes, source: container } = props
  const videoCount = container.items.length + container.toUpload.length

  if (videoCount > 0) {
    return (
      <div>
        {container.items.map((item: VideoModel) => (
          <StoryVideo key={item.id} item={item} classes={classes} />
        ))}

        {container.toUpload.map((item: UploadModel, id: number) => (
          <StoryVideo key={id} item={item} classes={classes} />
        ))}
      </div>
    )
  } else {
    return null
  }
}

interface ItemProps extends WithStyles<typeof styles> {
  item: VideoModel | UploadModel
}

const getFilename = (item: VideoModel | UploadModel): string => {
  if (item instanceof VideoModel) {
    return item.filename
  } else if (item.file && item.file.name) {
    return item.file.name
  } else {
    return ""
  }
}

const getAttribution = (item: VideoModel | UploadModel): string => {
  return item.attribution || i18n("storyPreview.label.noAttribution")
}

const StoryVideo: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { classes, item } = props
  const [videoSource, setVideoSource] = useState("")

  useEffect(() => {
    let mounted = true

    if (item instanceof VideoModel) {
      Storage.get(item.original, {
        level: "protected",
        identityId: item.identityId,
      }).then((url: Object | string) => {
        if (typeof url === "string" && mounted) {
          setVideoSource(url)
        }
      })
    } else if (item.file && item.file.name) {
      if (!videoSource || videoSource.indexOf("blob") === -1) {
        setVideoSource(URL.createObjectURL(item.file))
      }
    }

    return () => {
      mounted = false
    }
  }, [item, videoSource])

  const filename = getFilename(item)
  const attribution = getAttribution(item)

  if (videoSource) {
    return (
      <div className={classes.item}>
        <div>{filename}</div>
        <video controls className={classes.video}>
          <source src={videoSource} />
          <p>
            Your browser does not support the <code>video</code> element.
          </p>
        </video>
        <div>
          {i18n("storyPreview.label.attribution")}: {attribution}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default withStyles(styles)(StoryVideos)
