import React, { FunctionComponent } from "react"
import { Coords } from "google-map-react"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core"
import { StoryMarkerModel } from "../../models"
import { useCategoryIcon } from "../../utils/category"

const styles = (theme: Theme) => {
  const size = 56

  return createStyles({
    marker: {
      position: "relative",
      top: `${-size}px`,
      left: `${-size / 2}px`,
      width: `${size}px`,
      cursor: "pointer",
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  marker: StoryMarkerModel
  lat: number
  lng: number
  showPreview: (id: string, coords?: Coords) => void
}

const StoryMarker: FunctionComponent<Props> = (props: Props) => {
  const { marker, lat, lng, showPreview, classes } = props
  const img = useCategoryIcon(marker.category)

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    showPreview(marker.id, { lat, lng })
  }

  return (
    <div onClick={onClick}>
      <img src={img} alt="" className={classes.marker} />
    </div>
  )
}

export default withStyles(styles)(StoryMarker)
