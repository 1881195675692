import React, { FunctionComponent, useState, useEffect, useRef } from "react"
import { ApolloProvider } from "react-apollo"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom"
import { withAuthenticator } from "aws-amplify-react"
import Auth from "@aws-amplify/auth"
import { appSyncClient } from "./graphql"
import { getCustomerById } from "./services/customer"
import { store } from "./state/store"
import { Provider as AppContextProvider } from "./AppContext"
import { isSuper, isAdmin } from "./utils/user"
import { getInitialLanguage, setLanguage } from "./locales"
import Super from "./components/Super"
import Admin from "./components/Admin"
import {
  SignIn,
  ForgotPassword,
  VerifyContact,
  RequireNewPassword,
} from "./components/Authenticator"
import "./setupAmplify"

type Props = {
  authData: any
}

export const App: FunctionComponent<Props> = (props: Props) => {
  const userInfo = props.authData.signInUserSession.idToken.payload
  const [currentUser, setCurrentUser] = useState<CurrentUser>({
    username: userInfo["cognito:username"],
    groups: userInfo["cognito:groups"],
    customerId: userInfo["custom:customerId"],
    customerName: "",
    identityId: "",
    emailVerified: userInfo["email_verified"],
  })

  const [appContextLanguage, setAppContextLanguage] = useState<string>(
    getInitialLanguage()
  )
  const setCurrentLanguage = (newLanguage: string) => {
    setAppContextLanguage(newLanguage)
    setLanguage(newLanguage)
  }

  useEffect(() => {
    let mounted = true

    const updateCurrentUser = (field: keyof CurrentUser, value: string) => {
      if (mounted) {
        if (currentUser[field] !== value) {
          setCurrentUser({
            ...currentUser,
            [field]: value,
          })
        }
      }
    }

    Auth.currentUserInfo().then((userInfo: any) => {
      if (userInfo) {
        if (
          !currentUser.emailVerified &&
          !userInfo.attributes["email_verified"]
        ) {
          Auth.signOut()
        } else {
          updateCurrentUser(
            "emailVerified",
            userInfo.attributes["email_verified"]
          )
          updateCurrentUser(
            "customerId",
            userInfo.attributes["custom:customerId"]
          )
          getCustomerById(userInfo.attributes["custom:customerId"]).then(
            ({ data }: any) => {
              updateCurrentUser("customerName", data.getCustomer.name)
            }
          )
        }
      }
    })
    Auth.currentCredentials().then((credentials: any) => {
      updateCurrentUser("identityId", credentials.identityId)
    })

    return () => {
      mounted = false
    }
  }, [currentUser])

  const appRoot = useRef(null)

  const appContext: AppContext = {
    currentUser,
    currentLanguage: appContextLanguage,
    setCurrentLanguage,
    appRoot,
  }

  if (!isSuper(currentUser) && !isAdmin(currentUser)) {
    throw new Error(
      "Invalid user account detected (group requirements aren't met)"
    )
  } else if (isSuper(currentUser) && !currentUser.customerId) {
    throw new Error(
      "Invalid user account detected (attribute customerId is missing)"
    )
  }

  if (currentUser.emailVerified) {
    return (
      <ApolloProvider client={appSyncClient}>
        <Provider store={store}>
          <AppContextProvider value={appContext}>
            <BrowserRouter>
              <Switch ref={appRoot}>
                <Route path="/" exact component={Super} />
                <Route path="/admin" component={Admin} />
                <Redirect to="/" />
              </Switch>
            </BrowserRouter>
          </AppContextProvider>
        </Provider>
      </ApolloProvider>
    )
  } else {
    return null
  }
}

const authTheme = {
  input: {
    fontFamily: "'Crimson Text', serif",
  },
  navButton: {
    color: "black",
    backgroundColor: "white",
  },
  navBar: {
    color: "white",
    backgroundColor: "black",
    borderColor: "black",
  },
}

export default withAuthenticator(
  App,
  false,
  [<SignIn />, <ForgotPassword />, <VerifyContact />, <RequireNewPassword />],
  null,
  authTheme
)
