import React, { FunctionComponent, useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import { connect } from "react-redux"
import {
  Typography,
  Button,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import CustomerForm from "./CustomerForm"
import { CustomerModel } from "../../models"
import i18n from "../../locales"
import { RootState } from "../../state/store"
import * as customerActions from "../../state/admin/customers/actions"
import * as customerFormActions from "../../state/modals/customer-form/actions"
import { getAllCustomersSelector } from "../../state/admin/customers/selectors"
import CustomerTable from "./CustomerTable"

const styles = (theme: Theme) =>
  createStyles({
    newButton: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.only("xs")]: {
      newButton: {
        bottom: theme.spacing(1),
        right: theme.spacing(1),
      },
    },
  })

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  customers: CustomerModel[]

  retrieveCustomers: () => void
  openCustomerForm: (customer?: CustomerModel) => void
}

const Customers: FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    match,
    customers,
    retrieveCustomers,
    openCustomerForm,
  } = props

  useEffect(() => {
    retrieveCustomers()
  }, [retrieveCustomers])

  return (
    <div>
      <Typography variant="h5">{i18n("adminCustomers.header")}</Typography>
      <CustomerTable customers={customers} root={match.path} />
      <CustomerForm />
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.newButton}
        onClick={() => {
          openCustomerForm()
        }}
      >
        {i18n("adminCustomers.button.new")}
      </Button>
    </div>
  )
}

const componentWithStyles = withStyles(styles)(Customers)

const mapStateToProps = (state: RootState) => ({
  customers: getAllCustomersSelector(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  retrieveCustomers: () => {
    dispatch(customerActions.retrieveCustomers())
  },
  openCustomerForm: (customer?: CustomerModel) => {
    dispatch(customerFormActions.openCustomerForm(customer))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(componentWithStyles)
