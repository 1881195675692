import gql from "graphql-tag"
import API from "@aws-amplify/api"
import { appSyncClient } from "../graphql"
import * as queries from "../graphql/queries"
import { SuperUserModel } from "../models"

export const listSuperUsersByCustomerId = (
  customerId: string,
  nextToken?: string
): Promise<any> =>
  new Promise((resolve, reject) => {
    let path = `/customers/${customerId}`
    if (nextToken) {
      path += `?nextToken=${nextToken}`
    }

    API.get("adminBackend", path, {})
      .then((data: any) => {
        resolve({
          users: data.users.map((user: any) => createSuperUserModel(user)),
          nextToken: data.nextToken,
        })
      })
      .catch((error: any) => {
        reject(error)
      })
  })

const createSuperUserModel = (user: any): SuperUserModel =>
  new SuperUserModel({
    username: user.Username,
    email: findAttribute(user, "email"),
    customerId: findAttribute(user, "custom:customerId"),
    status: user.UserStatus,
    createdAt: user.UserCreateDate,
  })

const findAttribute = (user: any, name: string) => {
  const attribute = user.Attributes.find(
    (attribute: any) => attribute.Name === name
  )

  if (attribute) {
    return attribute.Value
  } else {
    return undefined
  }
}

export const searchSuperUserDetails = (customerId: string) => {
  return appSyncClient.query({
    query: gql(queries.searchSuperUserDetails),
    variables: { customerId },
    fetchPolicy: "no-cache",
  })
}

export const createSuperUserFromModel = (
  superUser: SuperUserModel,
  password: string
): Promise<any> =>
  new Promise((resolve, reject) => {
    const path = `/customers`
    const body = {
      superUser: {
        username: superUser.email,
        email: superUser.email,
        customerId: superUser.customerId,
        password: password,
      },
    }

    API.post("adminBackend", path, { body })
      .then((data: any) => {
        if (data.success) {
          resolve(data)
        } else {
          reject(data.error)
        }
      })
      .catch((error: any) => {
        reject(error)
      })
  })
