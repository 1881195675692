// tslint:disable
// this is an auto generated file. This will be overwritten

export const replaceRegularUser = `mutation ReplaceRegularUser($input: ReplaceRegularUserInput!) {
  replaceRegularUser(input: $input) {
    id
    email
    postSplashScreenAccepted
    visitedStories
  }
}
`;
export const createStory = `mutation CreateStory($input: CreateStoryInput!) {
  createStory(input: $input) {
    id
    name
    textExpo
    textMain
    imagesExpo {
      items {
        id
        identityId
        filename
        original
        owner
        sortOrder
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    imagesMain {
      items {
        id
        identityId
        filename
        original
        owner
        sortOrder
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    audiosExpo {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    audiosMain {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    videosExpo {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    videosMain {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    category {
      id
      names
    }
    parent {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    geoRadius
    geoAltitude
    geoPoint {
      lat
      lon
    }
    dataOrigin {
      id
      name
      image
    }
    reference
    references
    submitter {
      id
      firstName
      middleName
      lastName
      email
      showName
      authorized
      adult
      wpUserId
      wpEntryId
    }
    stars
    arSceneName
    private
    status
    comment
    dateStory
    owner
    userCreated
    userModified
    analyticsUnlockCount
    analyticsClickCount
    version
  }
}
`;
export const updateStory = `mutation UpdateStory($input: UpdateStoryInput!) {
  updateStory(input: $input) {
    id
    name
    textExpo
    textMain
    imagesExpo {
      items {
        id
        identityId
        filename
        original
        owner
        sortOrder
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    imagesMain {
      items {
        id
        identityId
        filename
        original
        owner
        sortOrder
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    audiosExpo {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    audiosMain {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    videosExpo {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    videosMain {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    category {
      id
      names
    }
    parent {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    geoRadius
    geoAltitude
    geoPoint {
      lat
      lon
    }
    dataOrigin {
      id
      name
      image
    }
    reference
    references
    submitter {
      id
      firstName
      middleName
      lastName
      email
      showName
      authorized
      adult
      wpUserId
      wpEntryId
    }
    stars
    arSceneName
    private
    status
    comment
    dateStory
    owner
    userCreated
    userModified
    analyticsUnlockCount
    analyticsClickCount
    version
  }
}
`;
export const deleteStory = `mutation DeleteStory($input: DeleteStoryInput!) {
  deleteStory(input: $input) {
    id
    name
    textExpo
    textMain
    imagesExpo {
      items {
        id
        identityId
        filename
        original
        owner
        sortOrder
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    imagesMain {
      items {
        id
        identityId
        filename
        original
        owner
        sortOrder
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    audiosExpo {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    audiosMain {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    videosExpo {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    videosMain {
      items {
        id
        identityId
        filename
        original
        owner
        attribution
        size
        storyExpo {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        storyMain {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
      }
      nextToken
    }
    category {
      id
      names
    }
    parent {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    geoRadius
    geoAltitude
    geoPoint {
      lat
      lon
    }
    dataOrigin {
      id
      name
      image
    }
    reference
    references
    submitter {
      id
      firstName
      middleName
      lastName
      email
      showName
      authorized
      adult
      wpUserId
      wpEntryId
    }
    stars
    arSceneName
    private
    status
    comment
    dateStory
    owner
    userCreated
    userModified
    analyticsUnlockCount
    analyticsClickCount
    version
  }
}
`;
export const createDataOrigin = `mutation CreateDataOrigin($input: CreateDataOriginInput!) {
  createDataOrigin(input: $input) {
    id
    name
    image
  }
}
`;
export const updateDataOrigin = `mutation UpdateDataOrigin($input: UpdateDataOriginInput!) {
  updateDataOrigin(input: $input) {
    id
    name
    image
  }
}
`;
export const deleteDataOrigin = `mutation DeleteDataOrigin($input: DeleteDataOriginInput!) {
  deleteDataOrigin(input: $input) {
    id
    name
    image
  }
}
`;
export const createImage = `mutation CreateImage($input: CreateImageInput!) {
  createImage(input: $input) {
    id
    identityId
    filename
    original
    owner
    sortOrder
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const updateImage = `mutation UpdateImage($input: UpdateImageInput!) {
  updateImage(input: $input) {
    id
    identityId
    filename
    original
    owner
    sortOrder
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const deleteImage = `mutation DeleteImage($input: DeleteImageInput!) {
  deleteImage(input: $input) {
    id
    identityId
    filename
    original
    owner
    sortOrder
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const createAudio = `mutation CreateAudio($input: CreateAudioInput!) {
  createAudio(input: $input) {
    id
    identityId
    filename
    original
    owner
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const updateAudio = `mutation UpdateAudio($input: UpdateAudioInput!) {
  updateAudio(input: $input) {
    id
    identityId
    filename
    original
    owner
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const deleteAudio = `mutation DeleteAudio($input: DeleteAudioInput!) {
  deleteAudio(input: $input) {
    id
    identityId
    filename
    original
    owner
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const createVideo = `mutation CreateVideo($input: CreateVideoInput!) {
  createVideo(input: $input) {
    id
    identityId
    filename
    original
    owner
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const updateVideo = `mutation UpdateVideo($input: UpdateVideoInput!) {
  updateVideo(input: $input) {
    id
    identityId
    filename
    original
    owner
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const deleteVideo = `mutation DeleteVideo($input: DeleteVideoInput!) {
  deleteVideo(input: $input) {
    id
    identityId
    filename
    original
    owner
    attribution
    size
    storyExpo {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
    storyMain {
      id
      name
      textExpo
      textMain
      imagesExpo {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      imagesMain {
        items {
          id
          identityId
          filename
          original
          owner
          sortOrder
          attribution
          size
        }
        nextToken
      }
      audiosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      audiosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosExpo {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      videosMain {
        items {
          id
          identityId
          filename
          original
          owner
          attribution
          size
        }
        nextToken
      }
      category {
        id
        names
      }
      parent {
        id
        name
        textExpo
        textMain
        imagesExpo {
          nextToken
        }
        imagesMain {
          nextToken
        }
        audiosExpo {
          nextToken
        }
        audiosMain {
          nextToken
        }
        videosExpo {
          nextToken
        }
        videosMain {
          nextToken
        }
        category {
          id
          names
        }
        parent {
          id
          name
          textExpo
          textMain
          geoRadius
          geoAltitude
          reference
          references
          stars
          arSceneName
          private
          status
          comment
          dateStory
          owner
          userCreated
          userModified
          analyticsUnlockCount
          analyticsClickCount
          version
        }
        geoRadius
        geoAltitude
        geoPoint {
          lat
          lon
        }
        dataOrigin {
          id
          name
          image
        }
        reference
        references
        submitter {
          id
          firstName
          middleName
          lastName
          email
          showName
          authorized
          adult
          wpUserId
          wpEntryId
        }
        stars
        arSceneName
        private
        status
        comment
        dateStory
        owner
        userCreated
        userModified
        analyticsUnlockCount
        analyticsClickCount
        version
      }
      geoRadius
      geoAltitude
      geoPoint {
        lat
        lon
      }
      dataOrigin {
        id
        name
        image
      }
      reference
      references
      submitter {
        id
        firstName
        middleName
        lastName
        email
        showName
        authorized
        adult
        wpUserId
        wpEntryId
      }
      stars
      arSceneName
      private
      status
      comment
      dateStory
      owner
      userCreated
      userModified
      analyticsUnlockCount
      analyticsClickCount
      version
    }
  }
}
`;
export const createCategory = `mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    id
    names
  }
}
`;
export const updateCategory = `mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    id
    names
  }
}
`;
export const deleteCategory = `mutation DeleteCategory($input: DeleteCategoryInput!) {
  deleteCategory(input: $input) {
    id
    names
  }
}
`;
export const createSubmitter = `mutation CreateSubmitter($input: CreateSubmitterInput!) {
  createSubmitter(input: $input) {
    id
    firstName
    middleName
    lastName
    email
    showName
    authorized
    adult
    wpUserId
    wpEntryId
  }
}
`;
export const updateSubmitter = `mutation UpdateSubmitter($input: UpdateSubmitterInput!) {
  updateSubmitter(input: $input) {
    id
    firstName
    middleName
    lastName
    email
    showName
    authorized
    adult
    wpUserId
    wpEntryId
  }
}
`;
export const deleteSubmitter = `mutation DeleteSubmitter($input: DeleteSubmitterInput!) {
  deleteSubmitter(input: $input) {
    id
    firstName
    middleName
    lastName
    email
    showName
    authorized
    adult
    wpUserId
    wpEntryId
  }
}
`;
export const createCustomer = `mutation CreateCustomer($input: CreateCustomerInput!) {
  createCustomer(input: $input) {
    id
    name
  }
}
`;
export const updateCustomer = `mutation UpdateCustomer($input: UpdateCustomerInput!) {
  updateCustomer(input: $input) {
    id
    name
  }
}
`;
export const deleteCustomer = `mutation DeleteCustomer($input: DeleteCustomerInput!) {
  deleteCustomer(input: $input) {
    id
    name
  }
}
`;
export const createRegularUser = `mutation CreateRegularUser($input: CreateRegularUserInput!) {
  createRegularUser(input: $input) {
    id
    email
    postSplashScreenAccepted
    visitedStories
  }
}
`;
export const updateRegularUser = `mutation UpdateRegularUser($input: UpdateRegularUserInput!) {
  updateRegularUser(input: $input) {
    id
    email
    postSplashScreenAccepted
    visitedStories
  }
}
`;
export const deleteRegularUser = `mutation DeleteRegularUser($input: DeleteRegularUserInput!) {
  deleteRegularUser(input: $input) {
    id
    email
    postSplashScreenAccepted
    visitedStories
  }
}
`;
