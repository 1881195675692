import { createAction, createAsyncAction } from "typesafe-actions"
import * as customerActions from "../../admin/customers/actions"
import * as toastsActions from "../../toasts/actions"
import { CustomerModel } from "../../../models"
import { createCustomerFromModel } from "../../../services/customer"

const OPEN_CUSTOMER_FORM = "[MODALS] OPEN CUSTOMER FORM"
const CLOSE_CUSTOMER_FORM = "[MODALS] CLOSE CUSTOMER FORM"
const SUBMIT_REQUEST = "[MODALS] SUBMIT_REQUEST"
const SUBMIT_REQUEST_SUCCESS = "[MODALS] SUBMIT_REQUEST_SUCCESS"
const SUBMIT_REQUEST_FAILURE = "[MODALS] SUBMIT_REQUEST_FAILURE"

export const openCustomerForm = createAction(
  OPEN_CUSTOMER_FORM,
  resolve => (customer?: CustomerModel) => resolve(customer)
)

export const closeCustomerForm = createAction(CLOSE_CUSTOMER_FORM)

export const submitStatus = createAsyncAction(
  SUBMIT_REQUEST,
  SUBMIT_REQUEST_SUCCESS,
  SUBMIT_REQUEST_FAILURE
)<void, void, void>()

export const createCustomer = (customer: CustomerModel) => (dispatch: any) => {
  dispatch(submitStatus.request())
  createCustomerFromModel(customer)
    .then(() => {
      dispatch(submitStatus.success())
      dispatch(customerActions.retrieveCustomers())
    })
    .catch((error: any) => {
      dispatch(submitStatus.failure())
      dispatch(toastsActions.showErrorToast(error))
    })
}
