import { createAction, createStandardAction } from "typesafe-actions"
import {
  listCategories,
  searchCategoryDetails,
} from "../../../services/category"
import * as toastsActions from "../../toasts/actions"

const SET_CATEGORIES = "[CATEGORIES] SET_CATEGORIES"
const SET_CATEGORY_DETAILS = "[CATEGORIES] SET_CATEGORY_DETAILS"

const RESET_CATEGORIES = "[CATEGORIES] RESET_CATEGORIES"
const REMOVE_CATEGORY = "[CATEGORIES] REMOVE CATEGORY"

export const retrieveCategories = () => (dispatch: any, getState: any) => {
  dispatch(resetCategories())
  listCategories()
    .then(({ data }: any) => {
      dispatch(setCategories(data))
      dispatch(retrieveCategoryDetails())
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const retrieveCategoryDetails = () => (dispatch: any, getState: any) => {
  searchCategoryDetails()
    .then(({ data }: any) => {
      dispatch(setCategoryDetails(data))
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const setCategories = createAction(
  SET_CATEGORIES,
  resolve => ({ listCategories }: any) => resolve(listCategories.items)
)

export const setCategoryDetails = createAction(
  SET_CATEGORY_DETAILS,
  resolve => ({ searchCategoryDetails }: any) =>
    resolve(searchCategoryDetails.aggregations)
)

export const resetCategories = createStandardAction(RESET_CATEGORIES)()

export const removeCategory = createStandardAction(REMOVE_CATEGORY)<string>()
