import React, { FunctionComponent } from "react"
import {
  CircularProgress,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import { CategoryModel, CategoryNames } from "../../models"
import i18n, { availableLanguages } from "../../locales"
import { useCategoryIcon } from "../../utils/category"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(11),
    },
    noCategories: {
      marginTop: theme.spacing(4),
    },
    categoryTranslation: {
      whiteSpace: "nowrap",
    },
    categoryIcon: {
      width: 20,
      height: 20,
    },
    categoryActions: {
      whiteSpace: "nowrap",
    },
    deleteButton: {
      marginLeft: theme.spacing(1),
    },
  })

interface Props extends WithStyles<typeof styles> {
  categories: CategoryModel[]
  openCategoryForm: (category?: CategoryModel) => void
  deleteCategory: (id: string) => void
}

const sort = (categories: CategoryModel[]): CategoryModel[] =>
  categories.sort((a: CategoryModel, b: CategoryModel) => {
    if (a.id > b.id) {
      return 1
    } else if (a.id < b.id) {
      return -1
    } else {
      return 0
    }
  })

const CategoryTable: FunctionComponent<Props> = (props: Props) => {
  const { classes, categories } = props

  if (categories.length > 0) {
    return (
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell>{i18n("adminCategories.table.id")}</TableCell>
            <TableCell>{i18n("adminCategories.table.name")}</TableCell>
            <TableCell>{i18n("adminCategories.table.image")}</TableCell>
            <TableCell align="right">
              {i18n("adminCategories.table.storyCount")}
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {sort(categories).map((category: CategoryModel) => (
            <TableRow key={category.id} hover>
              <TableCell>{category.id}</TableCell>
              <TableCell>
                {availableLanguages.map((language: string) => (
                  <div key={language} className={classes.categoryTranslation}>
                    {language}: {(category.names as CategoryNames)[language]}
                  </div>
                ))}
              </TableCell>
              <TableCell>
                <CategoryIcon category={category} {...props} />
              </TableCell>
              <TableCell align="right">{category.storyCount}</TableCell>
              <TableCell align="right" className={classes.categoryActions}>
                {CategoryActions(props, category)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  } else {
    return (
      <Typography variant="h6" align="center" className={classes.noCategories}>
        <CircularProgress />
      </Typography>
    )
  }
}

interface IconProps extends Props {
  category: CategoryModel
}

const CategoryIcon: FunctionComponent<IconProps> = (props: IconProps) => {
  const { classes, category } = props
  const img = useCategoryIcon(category.id)

  return <img src={img} alt={category.id} className={classes.categoryIcon} />
}

const CategoryActions: FunctionComponent<Props> = (
  props: Props,
  category: CategoryModel
) => {
  if (category.storyCount === undefined) {
    return <CircularProgress size={32} thickness={6} />
  }

  const { classes, openCategoryForm, deleteCategory } = props
  const canDelete = category.storyCount === 0

  const promptDeletion = (category: CategoryModel) => {
    if (category.storyCount === 0) {
      if (window.confirm(i18n("adminCategories.prompt.areYouSure?"))) {
        deleteCategory(category.id)
      }
    } else {
      alert(i18n("adminCategories.prompt.cantDelete"))
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          openCategoryForm(category)
        }}
      >
        {i18n("adminCategories.button.edit")}
      </Button>
      {canDelete && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.deleteButton}
          onClick={() => {
            promptDeletion(category)
          }}
        >
          {i18n("adminCategories.button.remove")}
        </Button>
      )}
    </>
  )
}

export default withStyles(styles)(CategoryTable)
