import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { CategoryModel } from "../../models"

type State = {
  entities: {
    [key: string]: CategoryModel
  }
  allIds: string[]
  allFilteredIds: string[]
}

const initialState: State = {
  entities: {},
  allIds: [],
  allFilteredIds: [],
}

export const categoriesReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.onListCategoriesSuccess):
      return {
        ...state,
        entities: action.payload.reduce((acc, category) => {
          return { ...acc, [category.id]: category }
        }, {}),
        allIds: action.payload.map(category => category.id),
        allFilteredIds: action.payload.map(category => category.id),
      }

    case getType(actions.toggleCategoriesFilter): {
      const categoryIdFromAction = action.payload
      const isChecked = !!state.allFilteredIds.find(filterCategoryId => {
        return filterCategoryId === categoryIdFromAction
      })

      if (isChecked) {
        return {
          ...state,
          allFilteredIds: state.allFilteredIds.filter(filterCategoryId => {
            return filterCategoryId !== categoryIdFromAction
          }),
        }
      }

      return {
        ...state,
        allFilteredIds: state.allFilteredIds.concat(categoryIdFromAction),
      }
    }

    case getType(actions.toggleAllCategoriesFilter): {
      const { allIds, allFilteredIds } = state
      const isAllChecked = allIds.length === allFilteredIds.length

      return {
        ...state,
        allFilteredIds: isAllChecked ? [] : allIds,
      }
    }

    default:
      return state
  }
}
