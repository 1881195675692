import Storage from "@aws-amplify/storage"
import uuid from "uuid/v4"
import { mutation } from "../graphql"
import * as Types from "../graphql/API"
import * as mutations from "../graphql/mutations"
import { StoryModel, AudioModel, AudioContainer, UploadModel } from "../models"

export const updateAllStoryAudioFields = async (
  currentUser: CurrentUser,
  story: StoryModel,
  progressCallback: () => void
) => {
  const fields = ["audiosExpo", "audiosMain"] as (keyof StoryModel)[]

  for (const field of fields) {
    await updateStoryAudioField(currentUser, story, progressCallback, field)
  }
}

export const updateStoryAudioField = async (
  currentUser: CurrentUser,
  story: StoryModel,
  progressCallback: () => void,
  field: keyof StoryModel
) => {
  const container = story[field]

  if (container instanceof AudioContainer) {
    if (container.toUpload.length > 0) {
      container.uploadProgress = Array(container.toUpload.length).fill(0)

      await Promise.all(
        container.toUpload.map((file: UploadModel, index: number) => {
          const key = uuid()
          const originalKey = key

          const identityId = currentUser.identityId
          const customerId = currentUser.customerId
          const storyId = story.id
          const storyIdField = field.replace("audios", "audioStory") + "Id"

          return Storage.put(key, file.file, {
            level: "protected",
            contentType: file.file.type,
          }).then(() => {
            container.uploadProgress[index] = 1
            createAudio({
              identityId,
              filename: file.file.name,
              owner: customerId,
              original: originalKey,
              [storyIdField]: storyId,
              attribution: file.attribution || null,
              size: file.file.size,
            })
            progressCallback()
          })
        })
      )
    }

    if (container.toRemove.length > 0) {
      await Promise.all(
        container.toRemove.map((audio: AudioModel) => {
          if (currentUser.identityId === audio.identityId) {
            Storage.remove(audio.original, {
              level: "protected",
            })
          }

          return deleteAudio({
            id: audio.id,
          })
        })
      )
    }

    if (container.items.length > 0) {
      await Promise.all(
        container.items.map((audio: AudioModel) => {
          return updateAudio({
            id: audio.id,
            attribution: audio.attribution,
          })
        })
      )
    }
  }
}

export const createAudio = (input: Types.CreateAudioInput) => {
  return mutation<typeof input>(mutations.createAudio, input)
}

export const deleteAudio = (input: Types.DeleteAudioInput) => {
  return mutation<typeof input>(mutations.deleteAudio, input)
}

export const updateAudio = (input: Types.UpdateAudioInput) => {
  return mutation<typeof input>(mutations.updateAudio, input)
}
