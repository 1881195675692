import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { CategoryModel } from "../../../models"

type Entities = {
  [key: string]: CategoryModel
}

type State = {
  entities: Entities
  allIds: string[]
}

const initialState: State = {
  entities: {},
  allIds: [],
}

export const categoriesReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.setCategories):
      if ("payload" in action) {
        return {
          ...state,
          entities: action.payload.reduce(
            (acc: any, category: any) => {
              if (acc[category.id]) {
                acc[category.id].name = category.name
              } else {
                acc[category.id] = new CategoryModel(category)
              }
              return acc
            },
            { ...state.entities }
          ),
          allIds: action.payload.map((category: any) => category.id),
        }
      } else {
        return state
      }

    case getType(actions.setCategoryDetails):
      if ("payload" in action) {
        const entities = { ...state.entities }

        for (const category of action.payload) {
          if (entities[category.id]) {
            entities[category.id].storyCount = category.storyCount
          }
        }

        for (const i in entities) {
          const entity = entities[i]
          if (entity.storyCount === undefined) {
            entity.storyCount = 0
          }
        }

        return {
          ...state,
          entities,
        }
      } else {
        return state
      }

    case getType(actions.resetCategories):
      return {
        ...state,
        entities: {},
        allIds: [],
      }

    case getType(actions.removeCategory):
      if ("payload" in action) {
        const entities = { ...state.entities }
        delete entities[action.payload]

        return {
          ...state,
          entities,
          allIds: state.allIds.filter((id: string) => id !== action.payload),
        }
      } else {
        return state
      }

    default:
      return state
  }
}
