"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var SubmitterModel =
/** @class */
function () {
  function SubmitterModel(params) {
    this.id = "";
    this.firstName = "";
    this.middleName = "";
    this.lastName = "";
    this.email = "";
    this.showName = false;
    this.authorized = false;
    this.adult = false;
    this.wpUserId = "";
    this.wpEntryId = "";

    if (params) {
      this.id = params.id;
      this.firstName = params.firstName;
      this.middleName = params.middleName;
      this.lastName = params.lastName;
      this.email = params.email;
      this.showName = params.showName;
      this.authorized = params.authorized;
      this.adult = params.adult;
      this.wpUserId = params.wpUserId;
      this.wpEntryId = params.wpEntryId;
    }
  }

  return SubmitterModel;
}();

exports.SubmitterModel = SubmitterModel;