import { createAction, createStandardAction } from "typesafe-actions"
import {
  listSuperUsersByCustomerId,
  searchSuperUserDetails,
} from "../../../services/super-user"
import * as toastsActions from "../../toasts/actions"
import { SuperUserModel } from "../../../models"

const RESET_SUPER_USERS = "[SUPER_USERS] RESET_SUPER_USERS"
const SET_SUPER_USERS = "[SUPER_USERS] SET_SUPER_USERS"
const SET_SUPER_USER_DETAILS = "[SUPER_USERS] SUPER_USER_DETAILS"
const SET_LOADING = "[SUPER_USERS] SET_LOADING"

export const retrieveSuperUsers = (customerId: string, nextToken?: string) => (
  dispatch: any,
  getState: any
) => {
  if (!nextToken) {
    dispatch(resetSuperUsers())
  }

  listSuperUsersByCustomerId(customerId)
    .then((data: any) => {
      dispatch(setSuperUsers(data.users))
      if (data.nextToken) {
        dispatch(retrieveSuperUsers(customerId, data.nextToken))
      } else {
        dispatch(retrieveSuperUserDetails(customerId))
      }
    })
    .catch((error: any) => {
      dispatch(setLoading(false))
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const retrieveSuperUserDetails = (customerId: string) => (
  dispatch: any,
  getState: any
) => {
  searchSuperUserDetails(customerId)
    .then(({ data }: any) => {
      dispatch(setSuperUserDetails(data))
    })
    .catch((error: any) => {
      dispatch(toastsActions.showErrorToast(error))
    })
}

export const resetSuperUsers = createStandardAction(RESET_SUPER_USERS)()

export const setSuperUsers = createStandardAction(SET_SUPER_USERS)<
  SuperUserModel[]
>()

export const setSuperUserDetails = createAction(
  SET_SUPER_USER_DETAILS,
  resolve => ({ searchSuperUserDetails }: any) =>
    resolve(searchSuperUserDetails)
)

export const setLoading = createStandardAction(SET_LOADING)<boolean>()
