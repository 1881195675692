import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync"
import Auth from "@aws-amplify/auth"
import awsConfig from "@hidden/backend"
import gql from "graphql-tag"

export const appSyncClient = new AWSAppSyncClient({
  url: awsConfig.aws_appsync_graphqlEndpoint,
  region: awsConfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      return ((await Auth.currentSession()) as any).idToken.jwtToken
    },
  },
})

export const mutation = <T>(mutation: string, input: T) => {
  return appSyncClient.mutate({
    mutation: gql(mutation),
    variables: { input },
  })
}
