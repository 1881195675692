import React, { FunctionComponent } from "react"
import {
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import IconClose from "@material-ui/icons/Close"

const styles = (theme: Theme) =>
  createStyles({
    button: {
      position: "absolute",
      top: theme.spacing(),
      right: theme.spacing(),
      color: theme.palette.grey[500],
    },
  })

interface Props extends WithStyles<typeof styles> {
  onClick: () => void
}

const DialogCloseButton: FunctionComponent<Props> = (props: Props) => (
  <IconButton
    aria-label="Close"
    className={props.classes.button}
    onClick={props.onClick}
  >
    <IconClose />
  </IconButton>
)

export default withStyles(styles)(DialogCloseButton)
