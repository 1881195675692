import React, { FunctionComponent, useState } from "react"
import { Link } from "react-router-dom"
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core"
import IconMenu from "@material-ui/icons/Menu"
import IconPeople from "@material-ui/icons/People"
import IconCategory from "@material-ui/icons/Category"
import i18n from "../../locales"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      zIndex: 2000,
    },
    paper: {
      position: "absolute",
      width: theme.admin.drawer.width,
    },
    toggler: {
      position: "absolute",
      top: theme.spacing(),
      left: theme.spacing(),
      color: "white",
    },
    link: {
      color: theme.palette.text.primary,
      cursor: "pointer",
      textDecoration: "none",
    },
    [theme.breakpoints.up("sm")]: {
      paper: {
        top: 64,
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  root: string
  pages: string[]
}

const Sidebar: FunctionComponent<Props> = (props: Props) => {
  const { classes, root, pages } = props
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)
  const close = () => setOpen(false)

  const Content = (
    <List>
      {pages.map((page: string, index: number) => (
        <Link
          key={page}
          to={`${root}/${page}`}
          onClick={close}
          className={classes.link}
          title={i18n(`adminSidebar.${page}`)}
        >
          <ListItem button>
            <ListItemIcon>
              <SidebarIcon icon={page} />
            </ListItemIcon>
            <ListItemText primary={i18n(`adminSidebar.${page}`)} />
          </ListItem>
        </Link>
      ))}
    </List>
  )

  return (
    <div className={classes.root}>
      <Hidden smUp implementation="css">
        <IconButton
          className={classes.toggler}
          onClick={toggle}
          aria-label="Menu"
        >
          <IconMenu />
        </IconButton>
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={close}
          classes={{ paper: classes.paper }}
          ModalProps={{ keepMounted: true }}
        >
          {Content}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          classes={{ paper: classes.paper }}
        >
          {Content}
        </Drawer>
      </Hidden>
    </div>
  )
}

const icons: { [key: string]: any } = {
  customers: IconPeople,
  categories: IconCategory,
}

type IconProps = {
  icon: string
}

const SidebarIcon: FunctionComponent<IconProps> = (props: IconProps) => {
  const Icon = icons[props.icon] || IconPeople

  return <Icon />
}

export default withStyles(styles)(Sidebar)
