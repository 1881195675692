"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StoryMarkerModel =
/** @class */
function () {
  function StoryMarkerModel(params) {
    this.category = "";
    this.id = params.id;
    this.geoPoint = params.geoPoint;

    if (params.category) {
      this.category = params.category.id;
    } else if (params.storyCategoryId) {
      this.category = params.storyCategoryId;
    }
  }

  return StoryMarkerModel;
}();

exports.StoryMarkerModel = StoryMarkerModel;