import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { getError } from "../../services/toast"

type State = {
  isOpen: boolean
  message: string | null
}

const initialState: State = {
  isOpen: false,
  message: null,
}

export const toastsReducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case getType(actions.showErrorToast):
      return {
        ...state,
        isOpen: true,
        message: getError(action.payload),
      }

    case getType(actions.hideToast): {
      return {
        ...state,
        isOpen: false,
        message: null,
      }
    }

    default:
      return state
  }
}
